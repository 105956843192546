import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './styles.scss';
import Select from 'react-select';
import { Arrow, CrossInCircle, Confirm, Warning } from '../../assets/svg';
import { changeUser, deleteUser } from '../../api/settings/UserAPI';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { OrgUserListContext } from '../../context/OrgUserListContext';
import ErrorIndicator from '../error-indicator';
import Loader from '../loader';
import ModalWrapper from '../shared/modals/modal-wrapper';
import showNotification from '../../helpers/showNotification';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

import { fetchUsersList } from '../../helpers/fetchUserPhotos';
import Button from '../shared/button';
import DeleteContent from '../shared/modals/delete-content';

const UsersList = ({ usersState, usersDispatch }) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);

    const [sortedUsers, setSortedUsers] = useState(usersState?.data);
    const [sortDirection, setSortDirection] = useState('descending');
    const [sortParam, setSortParam] = useState('last_login');

    const [openedUser, setOpenedUser] = useState(null);

    useEffect(() => {
        sortUsers(sortParam, sortDirection);
    }, [usersState.data]);

    function renderSortingArrow(columnName) {
        return (
            <div
                className="cursor-pointer "
                onClick={() => changeSort(columnName)}
            >
                <Arrow
                    width={'10'}
                    height={'10'}
                    fill="#323236"
                    transform={
                        sortDirection === 'descending' &&
                        sortParam === columnName
                            ? 'scale(1, -1)'
                            : 'scale(1, 1)'
                    }
                />
            </div>
        );
    }

    function sortUsers(param, direction) {
        let sorted = usersState?.data;
        if (!sorted?.length) {
            setSortedUsers([]);
            return;
        }

        switch (param) {
            case 'last_login':
            case 'created_date':
                sorted.sort(
                    (firstUser, secondUser) =>
                        firstUser[param] - secondUser[param]
                );
                break;
            case 'org_hash':
                sorted.sort((firstUser, secondUser) => {
                    let a = '';
                    let b = '';

                    if (typeof firstUser[param] === 'string') {
                        a = getOrganisationName(firstUser[param]).toLowerCase();
                    } else {
                        a = 'zzzzzz';
                    }

                    if (typeof secondUser[param] === 'string') {
                        b = getOrganisationName(
                            secondUser[param]
                        ).toLowerCase();
                    } else {
                        b = 'zzzzzz';
                    }

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
                break;
            case 'last_name':
            case 'first_name':
                sorted.sort((firstUser, secondUser) => {
                    let a = '';
                    let b = '';

                    if (typeof firstUser[param] === 'string') {
                        a = firstUser[param].toLowerCase();
                    } else {
                        a = 'zzzzzz';
                    }

                    if (typeof secondUser[param] === 'string') {
                        b = secondUser[param].toLowerCase();
                    } else {
                        b = 'zzzzzz';
                    }

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
                break;
            case 'email':
            default:
                sorted.sort((firstUser, secondUser) => {
                    let a = firstUser[param]?.toLowerCase(),
                        b = secondUser[param]?.toLowerCase();

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
        }

        if (direction === 'descending') {
            sorted = sorted.reverse();
        }
        setSortedUsers(sorted);
    }

    const changeSort = (columnName) => {
        sortDirection === 'descending'
            ? setSortDirection('ascending')
            : setSortDirection('descending');
        setSortParam(columnName);
        sortUsers(
            columnName,
            sortDirection === 'descending' ? 'ascending' : 'descending'
        );
    };

    const toggleUserInfo = (userHash) => {
        if (userHash === openedUser) {
            setOpenedUser(null);
        } else {
            setOpenedUser(userHash);
        }
    };

    const getOrganisationName = (orgHash) => {
        const name = organisationsState.data.find(
            (organisation) => organisation.org_hash === orgHash
        )?.org_name;
        if (name) {
            return name;
        } else {
            return orgHash;
        }
    };

    if (usersState.loading)
        return (
            <div className="text-center">
                <Loader />
            </div>
        );

    if (usersState.error) return <ErrorIndicator error={usersState.error} />;

    return (
        <div className={`users-list col-span-12`}>
            <div className="users-list__header">
                <div className="header__email flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'email' ? 'underline' : ''
                        }`}
                    >
                        User Email
                    </p>
                    {renderSortingArrow('email')}
                </div>
                <div className="header__org flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'org_hash' ? 'underline' : ''
                        }`}
                    >
                        Main User Organisation
                    </p>
                    {renderSortingArrow('org_hash')}
                </div>
                <div className="header__superadmin flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'first_name' ? 'underline' : ''
                        }`}
                    >
                        First Name
                    </p>
                    {renderSortingArrow('first_name')}
                </div>
                <div className="header__superadmin flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'last_name' ? 'underline' : ''
                        }`}
                    >
                        Last Name
                    </p>
                    {renderSortingArrow('last_name')}
                </div>
                <div className="header__created flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'created_date' ? 'underline' : ''
                        }`}
                    >
                        {capitalizeFirstLetter(
                            t('settings_page.org_tab.created_at')
                        )}
                    </p>
                    {renderSortingArrow('created_date')}
                </div>
                <div className="header__visited flex gap-x-2 items-center">
                    <p
                        className={`value ${
                            sortParam === 'last_login' ? 'underline' : ''
                        }`}
                    >
                        Last visit
                    </p>
                    {renderSortingArrow('last_login')}
                </div>
                <div className="header__superadmin ">
                    <p className="value font-medium">Superadmin</p>
                </div>
                <div className="header__demo">
                    <p className="value font-medium">Demo user</p>
                </div>
            </div>
            <div className="users-list__body">
                {sortedUsers.map((user) => (
                    <UserListItem
                        key={user.hash}
                        open={openedUser === user.hash}
                        toggleUserInfo={toggleUserInfo}
                        getOrganisationName={getOrganisationName}
                        userData={user}
                        usersState={usersState}
                        usersDispatch={usersDispatch}
                    />
                ))}
            </div>
        </div>
    );
};

const UserListItem = ({
    userData,
    getOrganisationName,
    toggleUserInfo,
    open,
    usersDispatch,
    usersState,
}) => {
    const { t } = useTranslation();
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);
    const [isSuperadmin, setIsSuperadmin] = useState(false);
    const [isDemoUser, setIsDemoUser] = useState(false);
    const [changeSuperadminModalState, setChangeSuperadminModalState] =
        useState(false);
    const [changeDemoUserModalState, setChangeDemoUserModalState] =
        useState(false);

    useEffect(() => {
        userData?.roles?.superadmin && setIsSuperadmin(true);
        userData?.roles?.prospect_user && setIsDemoUser(true);
    }, []);

    const toggleIsSuperadmin = () => {
        setIsSuperadmin(!isSuperadmin);
    };

    const toggleIsDemoUser = () => {
        setIsDemoUser(!isDemoUser);
    };

    const handleSuperadminChange = () => {
        let updatedRoles = {
            ...userData.roles,
        };

        if (userData.roles?.superadmin) {
            delete updatedRoles.superadmin;
        } else {
            updatedRoles = {
                ...updatedRoles,
                superadmin: true,
            };
        }

        void saveUserPermission(updatedRoles);
    };

    const handleDemoUserChange = () => {
        let updatedRoles = {
            ...userData.roles,
        };

        if (userData.roles?.prospect_user) {
            delete updatedRoles.prospect_user;
        } else {
            updatedRoles = {
                ...updatedRoles,
                prospect_user: true,
            };
        }

        void saveUserPermission(updatedRoles);
    };

    const saveUserPermission = async (newPermissionsList) => {
        usersDispatch({ type: 'SET_LOADING' });
        await changeUser(userData.hash, { roles: newPermissionsList })
            .then((response) => {
                if (response.status === 200) {
                    usersDispatch({
                        type: 'SET_DATA',
                        payload: usersState.data.map((user) => {
                            if (user.hash === userData.hash) {
                                user.roles = newPermissionsList;
                            }
                            return user;
                        }),
                    });
                }

                fetchUsersList(orgUserListDispatch);

                return Promise.resolve(response);
            })
            .catch((error) => {
                usersDispatch({
                    type: 'SET_ERROR',
                    payload: error,
                });
                return Promise.reject(error);
            });
    };

    const saveUserLoginOption = async (loginOption) => {
        if (loginOption === 'password') {
            usersDispatch({ type: 'SET_LOADING' });
            await changeUser(userData.hash, {
                login_option: loginOption,
                email: userData.email,
            })
                .then((response) => {
                    if (response.status === 200) {
                        usersDispatch({
                            type: 'SET_DATA',
                            payload: usersState.data.map((user) => {
                                if (user.hash === userData.hash) {
                                    user.login_option = loginOption;
                                }
                                return user;
                            }),
                        });
                    }

                    showNotification('Login option updated', 'success');

                    return Promise.resolve(response);
                })
                .catch((error) => {
                    showNotification(
                        `${error.response?.data || error.message}`,
                        'error'
                    );
                    usersDispatch({
                        type: 'SET_ERROR',
                        payload: error,
                    });
                    return Promise.reject(error);
                });
        } else {
            await changeUser(userData.hash, { login_option: loginOption })
                .then((response) => {
                    if (response.status === 200) {
                        usersDispatch({
                            type: 'SET_DATA',
                            payload: usersState.data.map((user) => {
                                if (user.hash === userData.hash) {
                                    user.login_option = loginOption;
                                }
                                return user;
                            }),
                        });
                    }

                    showNotification('Login option updated', 'success');

                    return Promise.resolve(response);
                })
                .catch((error) => {
                    showNotification(
                        `${error.response?.data || error.message}`,
                        'error'
                    );
                    usersDispatch({
                        type: 'SET_ERROR',
                        payload: error,
                    });
                    return Promise.reject(error);
                });
        }
    };

    return (
        <>
            <div className="users-list__item">
                <div className="preview">
                    <div className="item__email">
                        <p className="value">
                            {userData.email ? userData.email : 'No Email'}
                        </p>
                    </div>
                    <div className="item__org">
                        <p className="value">
                            {userData.org_hash
                                ? getOrganisationName(userData.org_hash)
                                : 'No Organisation'}
                        </p>
                    </div>
                    <div className="item__created">
                        <p className="value">
                            {userData.first_name ? userData.first_name : '-'}
                        </p>
                    </div>
                    <div className="item__created">
                        <p className="value">
                            {userData.last_name ? userData.last_name : '-'}
                        </p>
                    </div>
                    <div className="item__created">
                        <p className="value">
                            {userData.created_date
                                ? moment(userData.created_date * 1000)
                                      .local()
                                      .format('MMM Do, YYYY')
                                : '-'}
                        </p>
                    </div>
                    <div className="item__visited">
                        <p className="value">
                            {userData.last_login
                                ? moment(userData.last_login * 1000)
                                      .local()
                                      .format('MMM Do, YYYY')
                                : '-'}
                        </p>
                    </div>
                    <div className="item__superadmin">
                        <input
                            type="checkbox"
                            id="superadmin"
                            name="isSuperadmin"
                            checked={isSuperadmin}
                            onChange={() => {
                                toggleIsSuperadmin();
                                setChangeSuperadminModalState(true);
                            }}
                        />
                    </div>
                    <div className="item__demoUser">
                        <input
                            type="checkbox"
                            id="demoUser"
                            name="isDemoUser"
                            checked={isDemoUser}
                            onChange={() => {
                                toggleIsDemoUser();
                                setChangeDemoUserModalState(true);
                            }}
                        />
                    </div>
                    <Button
                        extraClasses="toggler"
                        onClick={() => toggleUserInfo(userData.hash)}
                    >
                        <Arrow
                            width="16px"
                            height="16px"
                            fill="#484a53"
                            transform={open ? 'rotate(180)' : 'rotate(0)'}
                        />
                    </Button>
                </div>
                {open && (
                    <UserListItemDetailsContainer
                        getOrganisationName={getOrganisationName}
                        userData={userData}
                        saveUserPermission={saveUserPermission}
                        saveUserLoginOption={saveUserLoginOption}
                        usersState={usersState}
                        usersDispatch={usersDispatch}
                    />
                )}
            </div>
            <ModalWrapper
                modalState={changeSuperadminModalState}
                setModalState={setChangeSuperadminModalState}
                title="Confirmation"
            >
                <DeleteContent
                    setModalState={setChangeSuperadminModalState}
                    handleAction={handleSuperadminChange}
                    onCancel={toggleIsSuperadmin}
                    closeBtnText={'Cancel'}
                    redBtnText={'Yes'}
                >
                    <p className="text-xl text-gray-500">
                        {capitalizeFirstLetter(
                            t(
                                'settings_page.users_tab.are_you_sure_change_status'
                            )
                        )}
                    </p>
                </DeleteContent>
            </ModalWrapper>

            <ModalWrapper
                modalState={changeDemoUserModalState}
                setModalState={setChangeDemoUserModalState}
                title="Confirmation"
            >
                <DeleteContent
                    setModalState={setChangeDemoUserModalState}
                    handleAction={handleDemoUserChange}
                    onCancel={toggleIsDemoUser}
                    closeBtnText={'Cancel'}
                    redBtnText={'Yes'}
                >
                    <p className="text-xl text-gray-500">
                        {capitalizeFirstLetter(
                            t(
                                'settings_page.users_tab.are_you_sure_change_status'
                            )
                        )}
                    </p>
                </DeleteContent>
            </ModalWrapper>
        </>
    );
};

const UserListItemDetailsContainer = ({
    userData,
    getOrganisationName,
    saveUserPermission,
    saveUserLoginOption,
    usersState,
    usersDispatch,
}) => {
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);

    const [userDetailsState, setUserDetailsState] = useState({
        data: userData,
        loading: false,
        error: false,
    });

    const [modalState, setModalState] = useState(false);

    const renderContent = () => {
        if (userDetailsState.data.roles.superadmin) {
            return (
                <UserListItemDetails
                    getOrganisationName={getOrganisationName}
                    superadmin={true}
                    userData={userData}
                    saveUserLoginOption={saveUserLoginOption}
                    roles={userDetailsState?.data?.roles}
                />
            );
        }

        if (userDetailsState.data.roles.prospect_user) {
            return (
                <UserListItemDetails
                    demoUser={true}
                    userData={userData}
                    saveUserLoginOption={saveUserLoginOption}
                />
            );
        }

        return (
            <UserListItemDetails
                getOrganisationName={getOrganisationName}
                roles={userDetailsState.data.roles}
                saveUserPermission={saveUserPermission}
                saveUserLoginOption={saveUserLoginOption}
                userData={userData}
            />
        );
    };

    const deleteUserFunc = () => {
        setUserDetailsState((prevState) => {
            return { ...prevState, loading: true };
        });
        usersDispatch({ type: 'SET_LOADING' });
        deleteUser(userData.hash)
            .then((response) => {
                if (response.status === 200) {
                    usersDispatch({
                        type: 'SET_DATA',
                        payload: usersState.data.filter(
                            (user) => user.hash !== userData.hash
                        ),
                    });
                    setUserDetailsState((prevState) => {
                        return { ...prevState, loading: false };
                    });
                    fetchUsersList(orgUserListDispatch);
                }
            })
            .catch((error) => {
                usersDispatch({
                    type: 'SET_ERROR',
                    payload: error,
                });
                setUserDetailsState((prevState) => {
                    return { ...prevState, loading: false };
                });
            });
        setModalState(false);
    };

    return (
        <div className="details">
            {renderContent()}
            <div className="buttons-wrapper">
                <Button
                    extraClasses="delete-user"
                    onClick={() => setModalState(true)}
                >
                    Delete User
                </Button>
            </div>
            <Rodal
                visible={modalState}
                height={160}
                onClose={() => setModalState(false)}
            >
                <div className="modal-content">
                    <p className="modal-title">
                        Are you sure you want to delete the user?
                    </p>
                    {userDetailsState.loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    ) : null}
                    <div className="buttons-wrapper">
                        <Button
                            extraClasses="delete"
                            onClick={() => deleteUserFunc()}
                        >
                            Yes
                        </Button>
                        <Button
                            extraClasses="close"
                            onClick={() => setModalState(false)}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Rodal>
        </div>
    );
};

const UserListItemDetails = ({
    roles,
    getOrganisationName,
    superadmin = false,
    demoUser = false,
    saveUserPermission = () => {},
    saveUserLoginOption = () => {},
    userData,
}) => {
    return (
        <div className="flex justify-between gap-x-12">
            <UserPermissions
                demoUser={demoUser}
                superadmin={superadmin}
                getOrganisationName={getOrganisationName}
                roles={roles}
                saveUserPermission={saveUserPermission}
                userData={userData}
            />
            <UserLoginOption
                userData={userData}
                saveUserLoginOption={saveUserLoginOption}
            />
        </div>
    );
};

const UserLoginOption = ({ userData, saveUserLoginOption }) => {
    const [localLoginOption, setLocalLoginOption] = useState(
        userData.login_option
    );
    const [password, setPassword] = useState('');

    const options = [
        { value: 'password', label: 'Password' },
        { value: 'saml', label: 'SAML' },
        { value: 'google', label: 'Google' },
        { value: 'linkedin', label: 'LinkedIn' },
        { value: 'microsoft', label: 'Microsoft' },
        { value: 'facebook', label: 'Facebook' },
    ];

    const handleSaveLoginOption = (localLoginOption) => {
        saveUserLoginOption(localLoginOption);
    };

    return (
        <div className="min-w-1/2 flex  items-center gap-x-4">
            <p className="">Login Option:</p>
            <Select
                isSearchable={false}
                options={options}
                styles={setCustomStyle()}
                value={options.find(
                    (option) => option.value === localLoginOption
                )}
                onChange={(option) => setLocalLoginOption(option.value)}
            />
            {/* TODO: check if works correctly */}
            {userData.login_option === 'password' &&
            localLoginOption === 'password' ? (
                <div className="flex gap-x-4 items-center">
                    <p className=" text-sm text-green-500 rounded ">
                        Email has been sent
                    </p>
                    <Button
                        variant="green"
                        onClick={() => handleSaveLoginOption(localLoginOption)}
                    >
                        Send another email
                    </Button>
                </div>
            ) : (
                <Button
                    disabled={_.isEqual(
                        userData.login_option,
                        localLoginOption
                    )}
                    variant="green"
                    onClick={() => handleSaveLoginOption(localLoginOption)}
                >
                    Save Changes
                </Button>
            )}
        </div>
    );
};

const UserPermissions = ({
    demoUser,
    superadmin,
    getOrganisationName,
    roles,
    saveUserPermission,
    userData,
}) => {
    const [localPermissions, setLocalPermissions] = useState({
        permissions: roles,
        loading: false,
        error: false,
    });

    const changePermission = (orgHash, value) => {
        setLocalPermissions((prevState) => {
            return {
                ...prevState,
                permissions: {
                    ...prevState.permission,
                    [orgHash]: value,
                },
            };
        });
    };
    const removePermission = (orgHash) => {
        const newPermissionsList = {
            ...localPermissions.permissions,
            [orgHash]: null,
        };
        setLocalPermissions((prevState) => {
            return {
                ...prevState,
                permissions: newPermissionsList,
            };
        });
    };

    const savePermissions = () => {
        if (!_.isEqual(roles, localPermissions.permissions)) {
            setLocalPermissions((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                };
            });

            saveUserPermission(localPermissions.permissions)
                .then(() => {
                    setLocalPermissions((prevState) => {
                        return {
                            ...prevState,
                            loading: false,
                        };
                    });
                })
                .catch(() => {
                    setLocalPermissions((prevState) => {
                        return {
                            ...prevState,
                            error: true,
                        };
                    });
                });
        }
    };

    const renderPermissionsList = () => {
        if (superadmin) {
            const mainOrg = [userData?.org_hash, 'admin'];
            return (
                <>
                    <p className="details-content__title superadmin">
                        This user is SUPERADMIN
                    </p>
                    {localPermissions?.permissions &&
                    !Object.keys(localPermissions.permissions)?.find(
                        (org) => org === userData?.org_hash
                    ) ? (
                        <Permission
                            key={mainOrg}
                            permission={mainOrg}
                            getOrganisationName={getOrganisationName}
                            changePermission={changePermission}
                            removePermission={removePermission}
                            isMainOrg={true}
                        />
                    ) : null}

                    {localPermissions?.permissions &&
                    Object.keys(localPermissions.permissions)?.length > 0
                        ? Object.entries(localPermissions.permissions)
                              .filter(
                                  (org) =>
                                      org[1] &&
                                      org[0] !== 'superadmin' &&
                                      org[0] !== 'prospect_user'
                              )
                              .map((org) => {
                                  return (
                                      <Permission
                                          key={org}
                                          permission={org}
                                          getOrganisationName={
                                              getOrganisationName
                                          }
                                          changePermission={changePermission}
                                          removePermission={removePermission}
                                      />
                                  );
                              })
                        : null}
                </>
            );
        }

        if (demoUser)
            return (
                <p className="details-content__title demo-user">
                    This user is just DEMO USER
                </p>
            );

        if (Object.keys(localPermissions.permissions).length === 0) {
            return (
                <div className="details-content">
                    <p className="details-content__title">Permissions</p>
                    <div className="details-content__wrapper">
                        <div className="permissions-list">
                            <p className="no-permissions">No permissions</p>
                        </div>
                        <div className="status">
                            {localPermissions.loading ? (
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            ) : null}
                            {localPermissions.error ? (
                                <div title="Sorry, some error on permission update">
                                    <Warning
                                        width="36px"
                                        height="36px"
                                        fill="#ee8282"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        }

        return Object.entries(localPermissions.permissions)
            .filter((org) => org[1])
            .map((org) => {
                return (
                    <Permission
                        key={org}
                        permission={org}
                        getOrganisationName={getOrganisationName}
                        changePermission={changePermission}
                        removePermission={removePermission}
                    />
                );
            });
    };

    return (
        <div className="details-content">
            {!superadmin && !demoUser && (
                <p className="details-content__title">
                    Organisations and permissions
                </p>
            )}

            <div className="details-content__wrapper">
                <div className="permissions-list">
                    {renderPermissionsList()}
                </div>
                {!superadmin && !demoUser && (
                    <>
                        <Button
                            disabled={_.isEqual(
                                roles,
                                localPermissions.permissions
                            )}
                            variant="green"
                            onClick={savePermissions}
                        >
                            Save Changes
                        </Button>
                        <div className="status">
                            {localPermissions.loading ? (
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            ) : null}
                            {localPermissions.error ? (
                                <div title="Sorry, some error on permission update">
                                    <Warning
                                        width="36px"
                                        height="36px"
                                        fill="#ee8282"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
            {!superadmin && !demoUser && (
                <AddOrgisation
                    setLocalPermissions={setLocalPermissions}
                    localPermissions={localPermissions}
                    userData={userData}
                />
            )}
        </div>
    );
};

const AddOrgisation = ({ setLocalPermissions, userData }) => {
    const { organisationsState } = useContext(OrganisationsContext);

    const [addPermissionState, setAddPermissionState] = useState({
        open: false,
        org: null,
        permission: null,
    });

    const options = [
        { value: 'admin', label: 'admin' },
        { value: 'write', label: 'write' },
        { value: 'read', label: 'read' },
    ];

    const orgList = () => {
        return organisationsState.data
            .filter((item) => item.org_hash !== userData.org_hash)
            .map((item) => {
                return { value: item.org_hash, label: item.org_name };
            });
    };

    const selectActiveOrg = (selectedOrg) => {
        setAddPermissionState((prevState) => {
            return {
                ...prevState,
                org: selectedOrg.value,
            };
        });
    };

    const addPermission = () => {
        setLocalPermissions((prevState) => {
            const newList = { ...prevState.permissions };
            newList[addPermissionState.org] = newList[addPermissionState.org] =
                addPermissionState.permission;

            return {
                ...prevState,
                permissions: newList,
            };
        });
        setAddPermissionState({ open: false, org: null, permission: null });
    };

    const changePermission = (value) => {
        setAddPermissionState((prevState) => {
            return {
                ...prevState,
                permission: value,
            };
        });
    };

    return (
        <div className="add-permission-wrapper">
            <Button
                variant="green"
                extraClasses={'min-w-[160px] mx-0 mt-2'}
                onClick={() => {
                    setAddPermissionState((prevState) => {
                        return {
                            ...prevState,
                            open: true,
                        };
                    });
                }}
            >
                Add Organisation
            </Button>
            {addPermissionState.open && (
                <div className="organisation-selector-wrapper">
                    <Select
                        isSearchable={false}
                        options={orgList()}
                        styles={setCustomOrgStyle()}
                        value={orgList().find(
                            (org) => org.value === addPermissionState.org
                        )}
                        onChange={(selectedOrg) => selectActiveOrg(selectedOrg)}
                    />
                </div>
            )}
            {addPermissionState.org && (
                <div className="permission-selector-wrapper">
                    <Select
                        isSearchable={false}
                        options={options}
                        styles={setCustomStyle()}
                        value={options.find(
                            (option) =>
                                option.value === addPermissionState.permission
                        )}
                        onChange={(option) => changePermission(option.value)}
                    />
                </div>
            )}
            {addPermissionState.permission && (
                <Button variant="green" onClick={addPermission}>
                    <Confirm width="30px" height="30px" fill={'#8ec358'} />
                </Button>
            )}
        </div>
    );
};

const Permission = ({
    permission,
    getOrganisationName,
    changePermission,
    removePermission = () => {},
    isMainOrg,
}) => {
    const options = [
        { value: 'admin', label: 'admin' },
        { value: 'write', label: 'write' },
        { value: 'read', label: 'read' },
    ];

    // const mainOrgOptions = [{ value: 'admin', label: 'admin' }];

    return (
        <div className="permissions-list__item">
            <p className="item__orgName">
                {permission[0] ? getOrganisationName(permission[0]) : null}
            </p>
            <div className="item__permission">
                <Select
                    isSearchable={false}
                    isDisabled={isMainOrg}
                    options={options}
                    styles={setCustomStyle()}
                    value={options.find(
                        (option) => option.value === permission[1]
                    )}
                    onChange={(option) =>
                        changePermission(permission[0], option.value)
                    }
                />
            </div>
            {isMainOrg ? null : (
                <Button
                    className="remove-permission"
                    onClick={() => removePermission(permission[0])}
                >
                    <CrossInCircle fill="#ee8282" width="24px" height="24px" />
                </Button>
            )}
        </div>
    );
};

const setCustomStyle = () => ({
    control: (provided, state) => ({
        ...provided,
        padding: '2px 0',
        width: '125px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
        borderColor: state.isDisabled ? '#c8c9cb' : '#484a53',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#484a53',
        color: state.isDisabled ? '#c8c9cb' : '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#c8c9cb' : '#484a53',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#c8c9cb' : '#484a53',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

const setCustomOrgStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        width: '250px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
        // borderColor: state.isSelected ? "red" : "rgba(0,0,0,.5)",
        borderColor: '#484a53',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#484a53',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default UsersList;
