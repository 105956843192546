import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import PanelTitle from '../../../panel-title';
import '../styles.scss';
import TableHeader from '../table-header';
import { TimePeriodContext } from '../../../../context/TimePeriodContext';
import Loader from '../../../loader';
import TableRow from '../table-row';
import ErrorIndicator from '../../../error-indicator';

function TableWrapper({
    title,
    paginationType = 'pages', // paginationType: 'none', 'showMore', 'pages'
    columns,
    gridColsAmount,
    rowsPerPage = 10,
    rowsQuantity, // if paginationType = 'showMore', rows not needed???
    onGetTableRows, // return value format: {rows: [], error: null}
    defaultSortingColumnId,
    defaultSortingDirection, // 'start' or 'end'
    noDataFound, // sets different messegase for stock in TableBody
    noDataFoundTrueText, // custom text to display when there is no table rows & noDataFound=true
    noDataFoundFalseText, // custom text to display when there is no table rows & noDataFound=false
    rerenderTrigger,
}) {
    const [timePeriodState] = useContext(TimePeriodContext);
    const [rowsData, setRowsData] = useState([]);
    const [rowList, setRowList] = useState({
        start: '0',
        end: '9',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [direction, setDirection] = useState(
        defaultSortingDirection ? defaultSortingDirection : 'start'
    );
    const [sort, setSort] = useState(
        lowerColumn(
            defaultSortingColumnId || !columns?.length
                ? defaultSortingColumnId
                : columns[0]['columnId']
        )
    ); // sorting column ID
    const [minHeight, setMinHeight] = useState(getMinHeight());

    const pageQuantity = Math.ceil(rowsQuantity / rowsPerPage);

    useEffect(() => {
        getTableData();
    }, []);

    function lowerColumn(column) {
        return column?.toLowerCase().replace(' ', '_');
    }

    function getMinHeight(newRowsQuantity) {
        let minHeight;

        if (newRowsQuantity) {
            minHeight = `${49 * newRowsQuantity}px`;
        } else if (rowsQuantity) {
            minHeight = `${49 * rowsQuantity}px`;
        } else {
            minHeight = rowsQuantity < 10 ? `${49 * rowsQuantity}px` : '490px';
        }
        return minHeight;
    }

    function handleSort(sortColumnId, sortDirection) {
        const loweredSort = lowerColumn(sortColumnId);
        const columnsSort = columns.map((col) => lowerColumn(col.columnId));
        if (columnsSort.includes(loweredSort)) {
            setSort(loweredSort);
        }

        setDirection(sortDirection ? 'start_to_end' : 'end_to_start');
        getTableData(rowList?.start, rowList?.end, loweredSort, sortDirection);
    }

    function pageChange(page) {
        let newStart, newEnd, remainingRows;
        newStart = `${page.selected}0`;
        remainingRows = rowsQuantity - +newStart;
        if (remainingRows <= 10) {
            newEnd = (rowsQuantity - 1).toString();

            const newRowsList = {
                start: newStart,
                end: newEnd,
            };

            setRowList(newRowsList);
            setMinHeight(getMinHeight(remainingRows));
            getTableData(newStart, newEnd, sort, direction);
        } else {
            let newEnd = `${page.selected}9`;
            const newRowsList = {
                start: newStart,
                end: newEnd,
            };
            setRowList(newRowsList);
            setMinHeight(480);
            getTableData(newStart, newEnd, sort, direction);
        }
    }

    async function getTableData(start, end, newSort, newDirection) {
        setLoading(true);

        let requestParams = {
            sort: newSort ? newSort : sort,
            direction: newDirection ? newDirection : direction,
            start: start ? start : rowList?.start,
            end: end ? end : rowList?.end,
            date_start: timePeriodState?.date_start,
            date_end: timePeriodState?.date_end,
        };

        if (rowsQuantity !== 0) {
            try {
                const response = await onGetTableRows(requestParams);
                if (response?.error) {
                    setError(response?.error);
                } else {
                    setRowsData(response.rows);
                }
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        } else {
            setRowsData([]);
            setLoading(false);
        }
    }

    return (
        <div className="generic-table-wrapper">
            {title ? (
                <PanelTitle
                    title={title}
                    underlineColorType="tertiary"
                    isLink={true}
                />
            ) : null}
            {error ? (
                <ErrorIndicator error={error} />
            ) : (
                <div className="generic-table">
                    <TableHeader
                        handleSort={handleSort}
                        columns={columns}
                        gridColsAmount={gridColsAmount}
                        sortColumnId={sort}
                        sortDirection={direction}
                        lowerColumn={lowerColumn}
                    />
                    <div
                        className="generic-table__body divide-y divide-solid divide-gray-300"
                        style={loading ? { minHeight: minHeight } : null}
                    >
                        {loading ? (
                            <div className="m-auto">
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        ) : (
                            <TableBody
                                rowsData={rowsData}
                                gridColsAmount={gridColsAmount}
                                noDataFound={noDataFound}
                                noDataFoundTrueText={noDataFoundTrueText}
                                noDataFoundFalseText={noDataFoundFalseText}
                            />
                        )}
                    </div>
                    {paginationType === 'pages' ? (
                        <div className="flex justify-end mt-2">
                            {pageQuantity > 1 ? (
                                <ReactPaginate
                                    previousLabel={'<'}
                                    previousLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    nextLabel={'>'}
                                    nextLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    breakLabel={'...'}
                                    breakClassName={'text-gray-400'}
                                    pageLinkClassName={'block w-full p-1'}
                                    pageClassName={'text-center text-gray-400'}
                                    pageCount={pageQuantity}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={3}
                                    containerClassName={
                                        'flex gap-x-1.5 items-center'
                                    }
                                    subContainerClassName={''}
                                    activeClassName={'bg-gray-200 rounded'}
                                    onPageChange={(page) => pageChange(page)}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}

function TableBody({
    rowsData,
    gridColsAmount,
    noDataFound,
    noDataFoundTrueText,
    noDataFoundFalseText,
}) {
    if (rowsData?.length) {
        return rowsData.map((row, i) => {
            return (
                <TableRow
                    rowData={row}
                    gridColsAmount={gridColsAmount}
                    key={row?.value}
                />
            );
        });
    } else {
        const text = noDataFound ? noDataFoundTrueText : noDataFoundFalseText;
        return (
            <div className="no-data text-center">
                <p>{text}</p>
            </div>
        );
    }
}

export default TableWrapper;
