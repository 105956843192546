import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import Loader from '../../components/loader';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import CreateOrganisationUserForm from '../../components/create-organisation-user-form';
import ModalWrapper from '../shared/modals/modal-wrapper';

const ModalCreateOrganisation = ({ modalState, setModalState, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userState } = useContext(UserContext);
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);
    const [showCreateSuccessScreen, setShowCreateSuccessScreen] =
        useState(false);
    const [initialValues, setInitialValues] = useState({
        contactName: '',
        contactLastName: '',
        contactEmail: '',
        contactJobTitle: '',
        contactGoal: '',
        companyName: '',
        monitoringSystem: '',
        gitSystem: '',
        pmSystem: '',
        communicationSystem: '',
    });

    useEffect(() => {
        setInitialValues({
            ...initialValues,
            contactName: userState?.data?.first_name?.length
                ? userState?.data?.first_name
                : '',
            contactLastName: userState?.data?.last_name?.length
                ? userState?.data?.last_name
                : '',
            contactEmail: userState?.data?.email?.length
                ? userState?.data?.email
                : '',
        });
    }, [userState?.data]);

    function handleChangeModalState() {
        setModalState(false);
        navigate('/overview');
    }

    return (
        <>
            {showCreateSuccessScreen ? (
                <div className="bg-white absolute top-0 left-0 w-screen h-screen px-auto pb pt-32 text-center z-20">
                    <p className="text-2xl text-gray-500 font-display mb-8">
                        Your organisation has been created. Start using Agile
                        Analytics in a moment...{' '}
                    </p>
                    <Loader />
                </div>
            ) : (
                <ModalWrapper
                    modalState={modalState}
                    setModalState={setModalState}
                    height={600}
                    additionalOnCloseFunc={onClose}
                    title="Get started with your own organisation"
                >
                    <CreateOrganisationUserForm
                        isInTrial={false}
                        setShowCreateSuccessScreen={setShowCreateSuccessScreen}
                        userState={userState}
                        organisationsState={organisationsState}
                        organisationsDispatch={organisationsDispatch}
                        modalState={modalState}
                        initialValues={initialValues}
                        handleChangeModalState={handleChangeModalState}
                    />
                </ModalWrapper>
            )}
        </>
    );
};

export default ModalCreateOrganisation;
