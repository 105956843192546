import { useContext, useState } from 'react';
import { restoreOrganisation } from '../api/organisation/OrganisationAPI';
import { OrganisationsContext } from '../context/OrganisationsContext';
import Loader from './loader';
import { getAdminPermission } from '../helpers/getPermission';
import Button from './shared/button';

function RestoreOrgScreen() {
    const { organisationsState } = useContext(OrganisationsContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const selectedOrgHash = organisationsState.data
        ? organisationsState?.data.find((org) => org.active).org_hash
        : null;

    const isAdmin = getAdminPermission(organisationsState?.data);

    async function handleRestoringOrg(orgHash) {
        if (!orgHash) {
            setError('Invalid organisation');
            return;
        }

        setError(null);
        setLoading(true);

        try {
            await restoreOrganisation(orgHash);
            window.location.reload();
        } catch (err) {
            setError(`${err.response?.data || err.message} || ''`);
        }
        setLoading(false);
    }

    return (
        <div className="relative page-content-wrapper bg-gray-bg text-center">
            <div className="absolute w-full h-full z-10 top-0 p-24">
                <div className="mx-auto bg-white rounded-lg w-1/2 p-8  shadow-lg text-center flex flex-col ">
                    <h2 className=" font-semibold font-display text-gray-500 text-2xl leading-6 mb-4">
                        This organisation has been removed
                    </h2>
                    {isAdmin ? (
                        <p className="font-display text-gray-500 text-lg mb-4">
                            It could be restored within 30 days.
                        </p>
                    ) : (
                        <p className="font-display text-gray-500 text-lg mb-4">
                            It could be restored by organisation's administrator
                            within 30 days.
                        </p>
                    )}

                    {isAdmin ? (
                        error ? (
                            <p className="h-8 font-light py-1 leading-5 text-sm text-red-text w-full">
                                {error}
                            </p>
                        ) : (
                            <p className="h-8"></p>
                        )
                    ) : null}

                    {isAdmin ? (
                        <Button
                            extraClasses="px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium"
                            onClick={() => handleRestoringOrg(selectedOrgHash)}
                        >
                            {loading ? (
                                <Loader
                                    color={'#fff'}
                                    size={18}
                                    speedMultiplier={0.8}
                                />
                            ) : (
                                'Restore'
                            )}
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default RestoreOrgScreen;
