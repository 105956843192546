import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { getCronjobStatus, getServices } from '../../api/settings-slo/slo';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { withUserAndOragnisations } from '../../helpers/withUser';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import CronjobStatus from './components/sre-cronjob-status';
import UpgradeWindow from '../../components/upgrade-window';
import { getSREBackends } from '../../api/settings/sreAPI';
import SettingsMessage from '../../components/settings-message';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';
import ModalWrapper from '../../components/shared/modals/modal-wrapper';
import ModalAddingServiceContent from './components/sre-modal-add-service-content';
import SREService from './components/sre-service';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { getDeploymentFrequencyData } from '../../api/dora/DoraAPI';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import Button from '../../components/shared/button';
import { FaArrowUp } from 'react-icons/fa';

const SREPage = ({ scrollRef, scrollTop }) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [services, servicesDispatch] = useFetchDataAndSetState(getServices, [
        selectedOrgHash,
    ]);
    const jobsRef = useRef(null);
    const [filter, setFilter] = useState('');
    const [filteredServices, setFilteredServices] = useState(null);
    const [currentOrgPermission, setCurrentOrgPermission] = useState(null);

    const [jobsFetchingCounter, setJobsFetchingCounter] = useState(0);

    function createJobStatusSub() {
        return Promise.resolve({
            status: 200,
            data: {
                job_name: 'slo-of2170-27413870',
                last_run: 'Mon, 14 Feb 2022 09:50:00 GMT',
                status: 'Succeeded',
            },
        });
    }

    const cronjobRequest = useCallback(() => {
        if (selectedOrgHash === 'O27A10') {
            return createJobStatusSub();
        } else {
            return getCronjobStatus();
        }
    }, [jobsFetchingCounter, selectedOrgHash]);

    const [cronjobState] = useFetchDataAndSetState(
        cronjobRequest,
        [selectedOrgHash, jobsFetchingCounter],
        null
    );

    const backendsRequest = useCallback(() => getSREBackends(), []);
    const [backendsState] = useFetchDataAndSetState(backendsRequest, [
        selectedOrgHash,
    ]);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;

    const deploymentFreqRequest = useCallback(
        () =>
            getDeploymentFrequencyData({
                params: { date_start: dateTimeStart, date_end: dateTimeEnd },
            }),
        [dateTimeStart, dateTimeEnd]
    );
    const [deploymentFreqState] = useFetchDataAndSetState(
        deploymentFreqRequest,
        [selectedOrgHash, dateTimeStart, dateTimeEnd]
    );

    useEffect(() => {
        setCurrentOrgPermission(
            organisationsState.data.find(
                (org) => org.org_hash === selectedOrgHash
            ).subscription_level
        );
    }, [selectedOrgHash]);

    useEffect(() => {
        if (filter?.length) {
            const filtered = services?.data
                ?.filter(
                    (service) =>
                        service?.service
                            ?.toLowerCase()
                            .includes(filter?.toLowerCase()) ||
                        service?.name
                            ?.toLowerCase()
                            .includes(filter?.toLowerCase()) ||
                        service?.url
                            ?.toLowerCase()
                            .includes(filter?.toLowerCase())
                )
                .map((ser) => ser?.service);
            setFilteredServices(filtered);
        } else {
            setFilteredServices(services?.data?.map((ser) => ser?.service));
        }
    }, [services?.data, filter]);

    function handleScroll() {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function renderJobsScrollBtn(jobsRef) {
        function executeJobsScroll() {
            jobsRef.current.scrollIntoView();
        }

        return (
            <div className="flex">
                <Button
                    onClick={() => executeJobsScroll()}
                    type="button"
                    variant={'yellowText'}
                >
                    {t('sre.last_job_status_go_to')}
                </Button>
            </div>
        );
    }

    return (
        <div className={`container mx-auto relative`}>
            {scrollTop > 50 ? (
                <Button
                    extraClasses={
                        'w-12 h-12 rounded-full bg-gray-bg fixed bottom-8 right-12 z-30  shadow-lg flex items-center justify-center'
                    }
                    onClick={handleScroll}
                >
                    <FaArrowUp className="w-6 h-6 text-gray-400" />
                </Button>
            ) : null}
            {currentOrgPermission === 'free' && (
                <UpgradeWindow
                    accessTo={capitalizeEveryWordFirstLetter(
                        t('settings_page.sre_tab.title')
                    )}
                />
            )}
            <div
                className={`${
                    currentOrgPermission === 'free' ? 'filter blur' : ''
                } flex flex-col gap-4`}
            >
                {!backendsState?.data?.length &&
                !backendsState.loading &&
                !services.loading ? (
                    <SettingsMessage
                        messageText={capitalizeFirstLetter(
                            t('settingsMessages.no_backend')
                        )}
                        link={NAVIGATION_PATHS.settingsErrorBudgets}
                        linkText={capitalizeFirstLetter(
                            t('settingsMessages.go_to_sre_settings')
                        )}
                    />
                ) : null}
                {backendsState?.data?.length &&
                !services?.data?.length &&
                !backendsState.loading &&
                !services.loading ? (
                    <SettingsMessage
                        messageText={`Add your first service and set up a feature to start measuring ${capitalizeEveryWordFirstLetter(
                            t('settings_page.sre_tab.title')
                        )}.`}
                    />
                ) : null}

                {isEditing ? null : (
                    <div className="flex gap-x-4 justify-between ">
                        <input
                            className="p-2 placeholder-gray-300 text-gray-700 relative bg-transparent
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-72 disabled:bg-gray-300"
                            id={filter}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            placeholder="Filter services..."
                        />
                        <Button
                            type="button"
                            onClick={() => setIsAddModalOpen(true)}
                            variant="whiteHoverBlue"
                        >
                            Configure new service
                        </Button>
                    </div>
                )}

                {services?.data?.length &&
                !cronjobState?.loading &&
                cronjobState?.data &&
                cronjobState?.data?.status === 'Failed' ? (
                    <div className="mt-4">
                        <SettingsMessage
                            messageText={t('sre.last_job_status_message')}
                            type="yellow"
                            renderContentFunc={() =>
                                renderJobsScrollBtn(jobsRef)
                            }
                        />
                    </div>
                ) : null}

                {services?.error ? (
                    <ErrorIndicator error={services.error} />
                ) : null}

                {services?.loading ? (
                    <div
                        className="flex justify-center items-center"
                        style={{ width: '100%', height: '394px' }}
                    >
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                            css={{ margin: '30px auto', display: 'block' }}
                        />
                    </div>
                ) : null}

                {services?.data?.length && !services?.loading ? (
                    <>
                        <div className="flex gap-x-4 items-center">
                            <p className="text-gray-400 px-4 py-2">Go to:</p>
                            <ul className="flex gap-x-4 flex-wrap">
                                {services?.data
                                    ?.sort((a, b) =>
                                        a.service
                                            ?.toLowerCase()
                                            .localeCompare(
                                                b.service?.toLowerCase()
                                            )
                                    )
                                    ?.map((service) => (
                                        <li key={service.service}>
                                            <a
                                                className="block px-4 py-2 text-gray-400 hover:text-gray-500 text-center w-full border-solid border-b  border-gray-300 hover:border-gray-500 "
                                                href={'#' + service.service}
                                            >
                                                {capitalizeFirstLetter(
                                                    service.service
                                                )}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        {services?.data
                            ?.sort((a, b) =>
                                a.service
                                    ?.toLowerCase()
                                    .localeCompare(b.service?.toLowerCase())
                            )
                            ?.map((service) => {
                                return (
                                    <div
                                        className="w-full "
                                        key={service.service}
                                        id={service.service}
                                    >
                                        <SREService
                                            service={service}
                                            services={services}
                                            key={service.service}
                                            servicesDispatch={servicesDispatch}
                                            setIsEditing={setIsEditing}
                                            sortedDeploymentFreqData={deploymentFreqState?.data?.sort(
                                                (a, b) =>
                                                    a.timestamp - b.timestamp
                                            )}
                                            isVisible={filteredServices?.find(
                                                (filtered) =>
                                                    service?.service ===
                                                    filtered
                                            )}
                                        />
                                    </div>
                                );
                            })}
                    </>
                ) : null}

                <div className="flex gap-x-4 mb-10" ref={jobsRef}>
                    <CronjobStatus cronjobState={cronjobState} />
                </div>

                <ModalWrapper
                    modalState={isAddModalOpen}
                    setModalState={(value) => setIsAddModalOpen(value)}
                    title={t('sre.modal_add_service')}
                >
                    <ModalAddingServiceContent
                        services={services}
                        onClose={() => setIsAddModalOpen(false)}
                        servicesDispatch={servicesDispatch}
                    />
                </ModalWrapper>
            </div>
        </div>
    );
};

export default withUserAndOragnisations(SREPage);
