import React, { useState, useEffect } from 'react';
import { tryFilters } from '../../../api/settings-slo/slo';
import ErrorIndicator from '../../../components/error-indicator';
import Loader from '../../../components/loader';
import SreFormFilterTryRow from './sre-form-filters-try-row';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/shared/button';

const mockData = {
    error: {
        title: 'Potential scale error',
        message: 'One or more values seems to be outside of 0...100 range',
    },
    logs: [
        { date_start: 1700816739, date_end: 1700818739, value: -2 },
        { date_start: 1690816739, date_end: 1700816738, value: 98.123 },
        { date_start: 1689816738, date_end: 1690816738, value: 99.123 },
        { date_start: 1689616737, date_end: 1689816737, value: 50.12 },
    ],
};

function SreFormFilterTry({ serviceName, sloSettings, isAws }) {
    const { t } = useTranslation();
    const [wasTriggered, setWasTriggered] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loadingItemIndex, setLoadingItemIndex] = useState(0);

    useEffect(() => {
        if (loading) {
            if (
                t('common.loading_texts', { returnObjects: true })?.length - 2 >
                loadingItemIndex
            ) {
                setTimeout(() => {
                    setLoadingItemIndex((prev) => prev + 1);
                }, 2000);
            } else {
                setTimeout(() => {
                    setLoadingItemIndex(0);
                }, 2000);
            }
        }
    }, [loadingItemIndex, loading]);

    async function handleTryFilters() {
        if (!wasTriggered) {
            setWasTriggered(true);
        }
        setError(null);
        setLoading(true);

        let tryFiltersParams = {
            backend: sloSettings?.backend,
            method: sloSettings?.method,
        };

        if (isAws) {
            tryFiltersParams = {
                ...tryFiltersParams,
                threshold_value: sloSettings?.threshold_value,
                filter_good: sloSettings?.distribution_options_aws?.length
                    ? sloSettings?.distribution_options_aws[0]?.filter_good
                    : null,
                filter_bad: sloSettings?.distribution_options_aws?.length
                    ? sloSettings?.distribution_options_aws[0]?.filter_bad
                    : null,
                filter_valid: sloSettings?.distribution_options_aws?.length
                    ? sloSettings?.distribution_options_aws[0]?.filter_valid
                    : null,
            };
        } else {
            if (tryFiltersParams.method === 'good_bad_ratio') {
                tryFiltersParams = {
                    ...tryFiltersParams,
                    threshold_bucket: sloSettings?.threshold_bucket,
                    filter_good: sloSettings?.ratio_options?.length
                        ? sloSettings?.ratio_options[0]?.filter_good
                        : null,
                    filter_bad: sloSettings?.ratio_options?.length
                        ? sloSettings?.ratio_options[0]?.filter_bad
                        : null,
                    filter_valid: sloSettings?.ratio_options?.length
                        ? sloSettings?.ratio_options[0]?.filter_valid
                        : null,
                };
            } else {
                tryFiltersParams = {
                    ...tryFiltersParams,
                    threshold_bucket: sloSettings?.threshold_bucket,
                    filter_good: sloSettings?.distribution_options?.length
                        ? sloSettings?.distribution_options[0]?.filter_good
                        : null,
                    filter_bad: sloSettings?.distribution_options?.length
                        ? sloSettings?.distribution_options[0]?.filter_bad
                        : null,
                    filter_valid: sloSettings?.distribution_options?.length
                        ? sloSettings?.distribution_options[0]?.filter_valid
                        : null,
                    good_below_threshold:
                        sloSettings?.good_below_threshold === 'true',
                };
            }
        }

        try {
            const res = await tryFilters(serviceName, tryFiltersParams);
            // const res = { data: mockData };
            if (res?.data) {
                setData(res.data);
            } else {
                setData(null);
            }
        } catch (err) {
            setError(err);
        }
        setLoading(false);
        return;
    }

    return (
        <div className={`mb-4`}>
            <div className={`flex justify-center`}>
                <Button
                    variant="whiteHoverBlue"
                    type="button"
                    onClick={() => handleTryFilters()}
                    extraClasses={'min-w-[110px]'}
                >
                    Try it out
                </Button>
            </div>
            <div className={`mt-2`}>
                {error ? <ErrorIndicator error={error} /> : null}
            </div>
            {loading ? (
                <div className="flex mt-2 items-center justify-center">
                    <div className="flex flex-col items-center justify-center gap-4">
                        <Loader />
                        <p className="text-gray-400 text-sm">
                            {t(`common.loading_texts.${loadingItemIndex}`)}...
                        </p>
                    </div>
                </div>
            ) : wasTriggered ? (
                <div className="mt-2">
                    {!error && data ? (
                        <div>
                            {data?.error ? (
                                <div className="error-indicator mx-auto  p-3 bg-red-100 rounded border border-solid border-red-200 text-red-500">
                                    <p
                                        className="text-center mb-2 font-medium"
                                        style={{ margin: 0 }}
                                    >
                                        {data.error?.title}
                                    </p>
                                    <p
                                        className="text-center error-data"
                                        style={{ margin: 0 }}
                                    >
                                        {data.error?.message}
                                    </p>
                                </div>
                            ) : null}
                            {!error && data?.length ? (
                                <div className="mt-4 rounded border border-solid border-gray-300 divide-y divide-solid divide-gray-200 ">
                                    <div className="grid grid-cols-12 gap-2 p-4 bg-gray-300">
                                        <p className="col-span-4">
                                            Time period
                                        </p>
                                        <p className="col-span-2">
                                            Good events
                                        </p>
                                        <p className="col-span-2">Bad events</p>
                                        <p className="col-span-2">
                                            Total events
                                        </p>
                                        <p className="col-span-2">SLI</p>
                                    </div>

                                    {data?.map((item, i) => (
                                        <SreFormFilterTryRow
                                            row={item}
                                            key={i}
                                            i={i}
                                        />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    ) : error ? null : (
                        <p className="text-center text-gray-500">No data</p>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default SreFormFilterTry;
