import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import './styles.scss';
import Button from '../button';

const SaveButton = ({
    saveFunc = () => {},
    disabled = false,
    text,
    withoutPermisson = false,
    variant = null,
    style,
    type = 'button',
    extraClasses,
}) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const userPermission = organisationsState.data
        ? organisationsState.data.find((org) => org.active).permissions
        : null;

    const updatedDisabled =
        withoutPermisson || disabled || userPermission === 'read'
            ? true
            : false;

    if (style) {
        return (
            <button
                style={style}
                className="save-button"
                disabled={updatedDisabled}
                onClick={() => saveFunc()}
            >
                {text ? text : capitalizeFirstLetter(t('button.save'))}
            </button>
        );
    }

    return (
        <Button
            type={type}
            variant={variant ? variant : 'blue'}
            onClick={() => saveFunc()}
            disabled={updatedDisabled}
            extraClasses={extraClasses}
        >
            {text ? text : capitalizeFirstLetter(t('button.save'))}
        </Button>
    );
};

export default SaveButton;
