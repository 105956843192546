import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import { TimePeriodContext } from '../context/TimePeriodContext';
import { OrganisationsContext } from '../context/OrganisationsContext';
import LeaksChart from '../components/leaks-chart';
import Loader from '../components/loader';
import { withUserAndOragnisations } from '../helpers/withUser';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import ErrorIndicator from '../components/error-indicator';
import { getLeaks } from '../api/leak/LeakAPI';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import useParamsQuery from '../hooks/useParamsQuery';
import UpgradeWindow from '../components/upgrade-window';
import { getGitProviders } from '../api/settings/GitProviderAPI';
import { getSelectedRepositories } from '../api/swarm/SwarmAPI';
import SettingsMessage from '../components/settings-message';
import { NAVIGATION_PATHS } from '../constants/navigationPaths';
import { RepoGroupsContext } from '../context/RepoGroupsContext';
import { fetchRepoGroups } from '../helpers/fetchRepoGroups';
import { groupReposWithGroupsList } from '../helpers/groupRepos';
import RepositoryLeaksTable from '../components/leaks-repo-table';

const LeaksPage = () => {
    const { setIsOpen } = useTour();
    const query = useParamsQuery();
    const [updateChart, setUpdateChart] = useState(0);
    const [showTableLoader, setShowTableLoader] = useState(true);

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const [timePeriodState] = useContext(TimePeriodContext);

    const { date_end, date_start } = timePeriodState;

    const request = useCallback(
        () =>
            getLeaks({
                params: { date_start, date_end },
            }),
        [date_start, date_end]
    );

    const { repoGroupsState, repoGroupsDispatch } =
        useContext(RepoGroupsContext);

    const [LeaksState] = useFetchDataAndSetState(
        request,
        [selectedOrgHash, date_start, date_end, updateChart],
        { repo_list: [], statistics: [] }
    );

    const [groupedReposData, setGroupedReposData] = useState(null);

    const providersRequest = useCallback(() => getGitProviders(), []);
    const [gitProvidersState] = useFetchDataAndSetState(providersRequest, [
        selectedOrgHash,
    ]);

    const [selectedRepositoriesState] = useFetchDataAndSetState(
        getSelectedRepositories,
        [selectedOrgHash]
    );

    const { t } = useTranslation();

    useEffect(() => {
        setShowTableLoader(true);
    }, [selectedOrgHash, date_start, date_end]);

    useEffect(() => {
        setGroupedReposData(null);
        void fetchRepoGroups(repoGroupsDispatch);
    }, [selectedOrgHash]);

    useEffect(() => {
        if (selectedOrgHash !== demoOrgHash) return;
        if (!LeaksState.data) return;
        if (query.get('tour') !== 'true') return;
        setTimeout(() => {
            setIsOpen(true);
        }, 1000);
        return () => setIsOpen(false);
    }, [LeaksState?.data]);

    useEffect(() => {
        if (
            repoGroupsState?.data?.length &&
            !repoGroupsState.loading &&
            !LeaksState?.loading &&
            LeaksState?.data?.repo_list.length
        ) {
            const grouped = groupReposWithGroupsList(
                repoGroupsState.data,
                LeaksState?.data?.repo_list
            );

            setGroupedReposData(grouped);
        }
    }, [LeaksState?.data, repoGroupsState?.data]);

    if (LeaksState.error) {
        return (
            <>
                {LeaksState.error.status === 403 && (
                    <UpgradeWindow accessTo={'Leaks'} />
                )}
                <div
                    className={`container px-4 mx-auto my-8 ${
                        LeaksState.error.status === 403 ? 'filter blur' : ''
                    }`}
                >
                    <div className="">
                        <ErrorIndicator error={LeaksState.error} />
                    </div>
                </div>
            </>
        );
    }

    function onUpdateChart() {
        setShowTableLoader(false);
        setUpdateChart(updateChart + 1);
    }

    return (
        <div className="container mx-auto ">
            <div data-tour="r-step-page-8">
                {!gitProvidersState?.data?.length && !LeaksState?.loading ? (
                    <SettingsMessage
                        messageText="Seems like you haven't added your Git provider yet. Add your Git provider to start tracking Leaks, Stock & measuring DORA metrics."
                        link={NAVIGATION_PATHS.settingsGitProviders}
                        linkText="Go to Git Providers settings"
                    />
                ) : null}
                {gitProvidersState?.data?.length &&
                !selectedRepositoriesState?.data?.length &&
                !selectedRepositoriesState?.loading &&
                !LeaksState?.loading ? (
                    <SettingsMessage
                        messageText="Seems like you haven't selected repositories to track yet. Go to Repositories and click 'Add Repository' button to select some."
                        link={NAVIGATION_PATHS.dora}
                        linkText="Go to Repositories"
                    />
                ) : null}
                <WhitePanelContentWrapper className="mb-8 mt-8">
                    {LeaksState.loading || repoGroupsState.loading ? (
                        <div className="flex justify-center">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    ) : (
                        <LeaksChart LeaksState={LeaksState} />
                    )}
                </WhitePanelContentWrapper>
            </div>
            {/* SEPARATE TABLE FOR EACH REPO */}
            {((LeaksState.loading && !showTableLoader) ||
                !LeaksState.loading ||
                !repoGroupsState.loading) &&
            LeaksState?.data?.repo_list?.length ? (
                <>
                    {groupedReposData?.length &&
                    groupedReposData.filter(
                        (group) => group.repositories.length > 0
                    )?.length
                        ? groupedReposData
                              .filter((group) => group.repositories.length > 0)
                              .map((group) => {
                                  return (
                                      <div key={group.id}>
                                          <p className="my-4 font-display text-gray-700 font-bold text-lg	leading-5">
                                              {group.name}
                                          </p>
                                          {group.repositories.map(
                                              (repository) => {
                                                  return (
                                                      <RepositoryLeaksTable
                                                          repository={
                                                              repository
                                                          }
                                                          key={
                                                              repository[
                                                                  'repo_name'
                                                              ]
                                                          }
                                                          onUpdate={
                                                              onUpdateChart
                                                          }
                                                      />
                                                  );
                                              }
                                          )}
                                      </div>
                                  );
                              })
                        : LeaksState.data.repo_list.map((repository) => {
                              return (
                                  <RepositoryLeaksTable
                                      repository={repository}
                                      key={repository['repo_name']}
                                      onUpdate={onUpdateChart}
                                  />
                              );
                          })}
                </>
            ) : null}

            {!LeaksState.loading && !LeaksState?.data?.repo_list.length ? (
                <div className="container px-4 mx-auto my-8">
                    <div className="flex justify-center">
                        {t('common.no_data')}
                    </div>
                </div>
            ) : null}
            {((LeaksState.loading && showTableLoader) ||
                repoGroupsState?.loading) && (
                <div className="container px-4 mx-auto my-8">
                    <div className="flex justify-center">
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default withUserAndOragnisations(LeaksPage);
