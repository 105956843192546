import { useTranslation } from 'react-i18next';
import { capitalizeEveryWordFirstLetter } from '../../helpers/textFormatters';
import ErrorBoundary from '../error-boundary';
import TableWrapper from '../shared/table/table-wrapper';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import moment from 'moment';
import { useState, useContext } from 'react';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { getLeaksTable, setLeakStatus } from '../../api/leak/LeakAPI';
import showNotification from '../../helpers/showNotification';

function RepositoryLeaksTable({ repository, onUpdate }) {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_end, date_start } = timePeriodState;
    const [loading, setLoading] = useState(true);

    const defaultSortingColumnId = 'committer';
    const defaultSortingDirection = 'start';
    const columns = [
        {
            columnId: 'commit_id',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.commit_id')
            ),
            columnSize: 2,
            canSort: false,
        },
        {
            columnId: 'committer',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.committer')
            ),
            columnSize: 2,
            canSort: true,
        },
        {
            columnId: 'file',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.file')
            ),
            columnSize: 2,
            canSort: false,
        },
        {
            columnId: 'date',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.date')
            ),
            columnSize: 1,
            canSort: true,
        },
        {
            columnId: 'marked_date',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.marked_date')
            ),
            columnSize: 1,
            canSort: true,
        },
        {
            columnId: 'leak_type',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.leak_type')
            ),
            columnSize: 2,
            canSort: true,
        },
        {
            columnId: 'action',
            columnName: capitalizeEveryWordFirstLetter(
                t('leaks_page.table.action')
            ),
            columnSize: 1,
            canSort: true,
        },
    ];
    const gridColsAmount = columns
        .map((obj) => obj.columnSize)
        .reduce((acc, size) => acc + size, 0);

    async function fetchTableData(start, end, newSort, newDirection) {
        const params = {
            params: {
                repository_name: repository.repo_name,
                start: start ? start : '0',
                end: end ? end : repository.table_rows - 1,
                sort: newSort ? newSort : defaultSortingColumnId,
                direction:
                    newDirection === 'end' ? 'end_to_start' : 'start_to_end',
                date_start: date_start,
                date_end: date_end,
            },
        };

        !loading && setLoading(true);

        try {
            const res = await getLeaksTable(params);

            if (res?.status === 204) {
                setLoading(false);
                return [];
            } else if (res?.status === 200) {
                setLoading(false);
                return res?.data;
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return null;
        }
    }

    async function handleActionSelectChange(value, leak) {
        let markedDate = moment().unix();

        let newLeak = {
            commit: leak?.commit_id?.value,
            commit_author: leak?.committer?.value,
            file: leak?.file?.value,
            date: leak?.date?.value,
            tag: leak?.leak_type?.value,
            key: value.value,
            marked_date: markedDate,
            repo: repository['repo_name'],
        };

        try {
            await setLeakStatus(newLeak);
            onUpdate();
        } catch (error) {
            showNotification(error.message);
        }
    }

    async function getTableRows(params) {
        const rows = await fetchTableData(
            params?.start,
            params?.end,
            params?.sort,
            params?.direction
        );
        const rowsDataWithTypesAndExtraClasses = rows?.map((row) => {
            return {
                commit_id: {
                    value: row?.commit,
                    type: 'string',
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'commit_id'
                    )?.columnSize,
                },
                committer: {
                    value: row?.commit_author,
                    type: 'string',
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'committer'
                    )?.columnSize,
                },
                file: {
                    value: row?.file,
                    type: 'string',
                    colsSpan: columns.find((col) => col?.columnId === 'file')
                        ?.columnSize,
                },
                date: {
                    value: moment(row?.date).format('YYYY MMM D'),
                    type: 'string',
                    colsSpan: columns.find((col) => col?.columnId === 'date')
                        ?.columnSize,
                },
                marked_date: {
                    value: moment(row?.marked_date).format('YYYY MMM D'),
                    type: 'string',
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'marked_date'
                    )?.columnSize,
                },
                leak_type: {
                    value: row?.tag,
                    type: 'string',
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'leak_type'
                    )?.columnSize,
                },
                action: {
                    value:
                        row?.key === "didn't solve"
                            ? { value: "didn't solve", label: 'Not solved' }
                            : { label: 'Solved', value: 'solve' },
                    type: 'select',
                    colsSpan: columns.find((col) => col?.columnId === 'action')
                        ?.columnSize,
                    onSelectChange: handleActionSelectChange,
                    selectOptions: [
                        { value: 'solve', label: 'Solved' },
                        { value: "didn't solve", label: 'Not solved' },
                    ],
                },
            };
        });
        const data = { rows: rowsDataWithTypesAndExtraClasses, error: null };
        return data;
    }

    return (
        <WhitePanelContentWrapper
            className="mb-8"
            key={repository['repo_name']}
        >
            <ErrorBoundary>
                <TableWrapper
                    title={repository['repo_name']}
                    paginationType={'pages'}
                    columns={columns}
                    gridColsAmount={gridColsAmount}
                    onGetTableRows={getTableRows}
                    defaultSortingColumnId={defaultSortingColumnId}
                    defaultSortingDirection={defaultSortingDirection}
                    rowsQuantity={repository['leaks_quantity']}
                    noDataFound={repository['no_leaks_data']}
                    noDataFoundTrueText={t('leaks_page.table.no_data')}
                    noDataFoundFalseText={t('leaks_page.table.no_leak')}
                />
            </ErrorBoundary>
        </WhitePanelContentWrapper>
    );
}

export default RepositoryLeaksTable;
