import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { Github, Gitlab, Bitbucket, Arrow } from '../../assets/svg.js';
import { getAdminPermission } from '../../helpers/getPermission';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import Button from '../shared/button/index.js';

const AddProviderItem = ({ addProvider }) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const [isProvidersListOpen, setIsProvidersListOpen] = useState(false);

    const toggleIsProvidersListOpen = () =>
        setIsProvidersListOpen(!isProvidersListOpen);

    const handleAddNewProvider = (provider) => {
        addProvider(provider);
        toggleIsProvidersListOpen();
    };

    return (
        <li className="w-480 h-450 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
            {getAdminPermission(organisationsState?.data) ? (
                <>
                    <Button
                        onClick={() => toggleIsProvidersListOpen()}
                        variant="white"
                        extraClasses={'bg-transparent'}
                    >
                        <span className="mr-2">
                            {capitalizeFirstLetter(
                                t('settings_page.git_tab.add')
                            )}
                        </span>
                        <Arrow
                            width={'12'}
                            height={'8'}
                            fill="#7F8992"
                            transform={
                                isProvidersListOpen
                                    ? 'scale(1, 1)'
                                    : 'scale(1, -1)'
                            }
                        />
                    </Button>
                    <div className="relative w-130">
                        {isProvidersListOpen && (
                            <ul className="w-130 absolute top-0 left-0 bg-white rounded shadow-default text-gray-500">
                                <li className="p-2 flex items-center filter grayscale">
                                    <Gitlab width={16} height={16} />
                                    <span
                                        className="ml-1 cursor-pointer"
                                        onClick={() =>
                                            handleAddNewProvider('gitlab')
                                        }
                                    >
                                        GitLab
                                    </span>
                                </li>
                                <li className="p-2 flex items-center filter grayscale">
                                    <Github width={16} height={16} />
                                    <span
                                        className="ml-1 cursor-pointer"
                                        onClick={() =>
                                            handleAddNewProvider('github')
                                        }
                                    >
                                        GitHub
                                    </span>
                                </li>
                                <li className="p-2 flex items-center filter grayscale">
                                    <Bitbucket width={16} height={16} />
                                    <span
                                        className="ml-1 cursor-pointer"
                                        onClick={() =>
                                            handleAddNewProvider('bitbucket')
                                        }
                                    >
                                        Bitbucket
                                    </span>
                                </li>
                            </ul>
                        )}
                    </div>
                </>
            ) : (
                <p>
                    {capitalizeFirstLetter(
                        t('settings_page.git_tab.no_permission')
                    )}
                </p>
            )}
        </li>
    );
};

export default AddProviderItem;
