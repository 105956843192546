import moment from 'moment';
import React, {
    forwardRef,
    useRef,
    useEffect,
    useMemo,
    useContext,
    useState,
    memo,
} from 'react';
import { useTable, useRowSelect } from 'react-table';
import { ArrowRight, Arrow } from '../../../assets/svg';
import Loader from '../../../components/loader';
import ErrorIndicator from '../../../components/error-indicator';
import { getConfidentClass, getUniqueListByParent } from './helpers';
import { TYPE_ICON_MAP, TYPE_TICKET_MAP } from './consts';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import { MediaContext } from '../../../context/MediaContext';
import userAvatar from '../../../assets/user-avatar.png';
import Button from '../../../components/shared/button';

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, title, isParent, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div>
                <input
                    type="checkbox"
                    ref={resolvedRef}
                    title="Select ticket"
                    disabled={isParent}
                    {...rest}
                />
            </div>
        );
    }
);

const subTasksToggle = (evt) => {
    const parentTask = evt.target.closest('.task-row');
    const taskKey = parentTask.dataset.task;
    const subTasks = parentTask.parentElement.querySelectorAll(
        `.task-row-subtask[data-task=${taskKey}]`
    );
    let btnToggle = evt.target.closest('.task-row-toggle');

    btnToggle.classList.toggle('rotate');

    subTasks?.forEach((subtask) => {
        subtask.classList.toggle('hidden');
        subtask.classList.toggle('show');
    });
};

function Table({
    columns,
    data,
    onRowSelect,
    isTableSettings,
    setIsTableSettings,
    initialHiddenCols,
    type,
    sprintsArray,
}) {
    const { t } = useTranslation();
    let sprintsStartAndEnd = null;
    let currentSprint = null;
    useEffect(() => {
        sprintsStartAndEnd = null;
        currentSprint = null;
    }, [data]);

    if (type === 'team' && sprintsArray?.length) {
        sprintsStartAndEnd = sprintsArray
            .map((sprint, i) => {
                return [
                    moment(sprint[0]).unix(),
                    moment(sprint[sprint.length - 1]).unix(),
                ];
            })
            .reverse();
        currentSprint = sprintsStartAndEnd[0];
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: { selectedRowIds, hiddenColumns },
        allColumns,
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            {type === 'team' ? (
                                <></>
                            ) : (
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            )}
                        </div>
                    ),
                    className: `${
                        type === 'team' ? 'w-6 pt-3' : 'w-10 py-2'
                    } flex-none col-span-1 font-medium leading-6  text-center`,
                    Cell: ({ row }) => {
                        if (row.original.isParent) {
                            return (
                                <div className="flex align-center">
                                    {type === 'team' ? (
                                        <></>
                                    ) : (
                                        <IndeterminateCheckbox
                                            {...row.getToggleRowSelectedProps()}
                                        />
                                    )}
                                    {row.original.isParent && (
                                        <Button
                                            type="button"
                                            onClick={subTasksToggle}
                                            extraClasses={
                                                'ml-3 task-row-toggle'
                                            }
                                        >
                                            <Arrow
                                                fill={'#7F8992'}
                                                width={12}
                                                height={12}
                                                transform={'rotate(-90)'}
                                            />
                                        </Button>
                                    )}
                                </div>
                            );
                        }

                        return null;
                    },
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        onRowSelect(selectedFlatRows);
    }, [onRowSelect, selectedFlatRows]);

    React.useEffect(() => {
        setHiddenColumns(initialHiddenCols);
    }, [initialHiddenCols]);

    const tableHeader = (
        <thead className=" border-solid border-b-2 border-gray-300 ">
            {headerGroups.map((headerGroup) => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className={`flex flex-nowrap gap-x-1.5 2xl:gap-x-2 justify-start`}
                >
                    {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps([
                                { className: column.className },
                            ])}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );

    if (!data.length) {
        return (
            <table
                {...getTableProps}
                className="mx-auto tasks-table fit-content  w-full border-collapse"
            >
                {tableHeader}
                <tbody className="tasks-table-body text-gray-600 text-base">
                    <tr>
                        <td className="text-center py-3">
                            {t('common.no_data')}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <>
            {isTableSettings ? (
                <div
                    id="columns-filter-popup"
                    className="absolute bg-white w-48 rounded-lg right-16 -top-6 p-2 shadow-md flex flex-col gap-1"
                >
                    <label>
                        <input
                            type="checkbox"
                            checked={!hiddenColumns?.length}
                            onChange={() => {
                                setHiddenColumns(
                                    hiddenColumns?.length
                                        ? []
                                        : allColumns
                                              .map((column) => column.id)
                                              .filter(
                                                  (id) =>
                                                      id !== 'selection' &&
                                                      id !== 'ticket key' &&
                                                      id !== 'label'
                                              )
                                );
                            }}
                            className="w-4 h-4 rounded"
                        />{' '}
                        {hiddenColumns?.length ? 'Show all' : 'Hide all'}
                    </label>

                    {allColumns.map((column) => {
                        const isFixed =
                            column.id === 'selection' ||
                            column.id === 'ticket key' ||
                            column.id === 'label';

                        if (column.id === 'selection') {
                            return;
                        }

                        if (isFixed) {
                            return (
                                <div key={column.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            disabled
                                            checked
                                            className="w-4 h-4 rounded"
                                        />{' '}
                                        {column.render('Header')}
                                    </label>
                                </div>
                            );
                        }
                        return (
                            <div key={column.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="w-4 h-4 rounded"
                                        {...column.getToggleHiddenProps()}
                                    />{' '}
                                    {column.render('Header')}
                                </label>
                            </div>
                        );
                    })}
                </div>
            ) : null}

            <table
                {...getTableProps}
                className="mx-auto tasks-table fit-content border-collapse flex flex-col "
            >
                {tableHeader}

                <tbody
                    {...getTableBodyProps}
                    className="tasks-table-body text-gray-500"
                >
                    {rows.map((row, i) => {
                        prepareRow(row);
                        const subTaskClass = row.original.isParent
                            ? ''
                            : 'task-row-subtask hidden';

                        if (type === 'team' && sprintsStartAndEnd?.length) {
                            if (
                                currentSprint?.length &&
                                data[i]?.timestamp <= currentSprint[1] + 86400
                            ) {
                                const oldSprint = [...currentSprint];
                                currentSprint = sprintsStartAndEnd?.reduce(
                                    (acc, sprint, i) => {
                                        if (sprint[0] === oldSprint[0]) {
                                            return sprintsStartAndEnd[i + 1];
                                        }
                                        return acc;
                                    },
                                    null
                                );

                                return (
                                    <>
                                        <tr
                                            className={`flex flex-nowrap gap-x-1.5 2xl:gap-x-2 justify-start border-solid border-b border-gray-300 align-middle task-row py-2 px-3 ${subTaskClass} `}
                                            data-task={oldSprint[0]}
                                        >
                                            <td
                                                className={`leading-6 align-middle `}
                                            >
                                                {moment
                                                    .unix(oldSprint[0])
                                                    .format('D MMMM YYYY')}{' '}
                                                -{' '}
                                                {moment
                                                    .unix(oldSprint[1])
                                                    .format('D MMMM YYYY')}
                                            </td>
                                        </tr>
                                        <tr
                                            {...row.getRowProps()}
                                            className={`flex flex-nowrap gap-x-1.5 2xl:gap-x-2 justify-start border-solid border-b border-gray-300 align-middle task-row ${subTaskClass}`}
                                            data-task={row.original.parent.key}
                                        >
                                            {row.cells.map((cell) => {
                                                const alignText =
                                                    cell.column.extraClass ??
                                                    'text-center';
                                                const columnClasses =
                                                    cell.column.className;
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={` break-words ${columnClasses.replace(
                                                            'font-medium',
                                                            ''
                                                        )}} leading-6 align-middle ${alignText}`}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            }
                        }

                        return (
                            <tr
                                {...row.getRowProps()}
                                className={`flex flex-nowrap gap-x-1.5 2xl:gap-x-2 justify-start border-solid border-b border-gray-300 align-middle task-row ${subTaskClass}`}
                                data-task={row.original.parent.key}
                            >
                                {row.cells.map((cell) => {
                                    const alignText =
                                        cell.column.extraClass ?? 'text-center';
                                    const columnClasses = cell.column.className;
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className={` ${columnClasses.replace(
                                                'font-medium',
                                                ''
                                            )}} leading-6 align-middle ${alignText}`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

const TasksTable = ({
    setSelectedTasks,
    data,
    error,
    loading,
    isTableSettings,
    setIsTableSettings,
    type,
    sprintsArray,
    selectedOrganisationHash,
}) => {
    const { t } = useTranslation();
    const mediaWidth = useContext(MediaContext);
    const [initialHiddenCols, setInitialHiddenCols] = useState([]);

    useEffect(() => {
        if (type === 'team') {
            setInitialHiddenCols(['sprint']);
            return;
        }
        switch (mediaWidth) {
            case 'xs':
            case 'sm':
            case 'md':
                setInitialHiddenCols([
                    'sprint',
                    'confidence',
                    'description',
                    'assignee',
                    'hours',
                    'type',
                ]);
                break;
            case 'lg':
                setInitialHiddenCols(['sprint', 'assignee', 'confidence']);
                break;
            case 'xl':
                setInitialHiddenCols(['sprint']);
                break;
            case '2xl':
            default:
                setInitialHiddenCols([]);
        }
    }, [mediaWidth]);

    const columns = useMemo(
        () => [
            {
                Header: () => capitalizeFirstLetter(t('si_page.date_event')),
                accessor: 'date event',
                className:
                    'w-24 2xl:w-36 flex-none font-medium leading-6 py-2  text-center',
                Cell: ({ row }) =>
                    moment(row.original.timestamp * 1000).format(
                        'DD-MM-YYYY HH:mm'
                    ),
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.transition')),
                accessor: 'transition',
                className:
                    'w-28 lg:w-36 xl:w-48 flex-grow lg:flex-none font-medium leading-6 py-2  text-center',
                Cell: ({ row }) => {
                    const transitionFrom = row.original.transition_from;
                    const transitionTo = row.original.transition_to;

                    const transitionFromClass = transitionFrom
                        .replace(/\s+/g, '-')
                        .toLowerCase();
                    const transitionToClass = transitionTo
                        .replace(/\s+/g, '-')
                        .toLowerCase();

                    const transitionFromFullClass = `ticket ${
                        TYPE_TICKET_MAP[transitionFromClass] ??
                        TYPE_TICKET_MAP['default']
                    }`;
                    const transitionToFullClass = `ticket ${
                        TYPE_TICKET_MAP[transitionToClass] ??
                        TYPE_TICKET_MAP['default']
                    }`;

                    return (
                        <div className="flex content-center justify-around">
                            <span
                                className={transitionFromFullClass}
                                title={transitionFrom}
                            >
                                {transitionFrom}
                            </span>
                            <span>
                                <ArrowRight
                                    fill={'#7F8992'}
                                    className="h-100 w-4"
                                />
                            </span>
                            <span
                                className={transitionToFullClass}
                                title={transitionTo}
                            >
                                {transitionTo}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.sprint')),
                accessor: 'sprint',
                className:
                    'w-36 flex-none font-medium leading-6 py-2  text-center',
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.ticket_key')),
                id: 'ticket key',
                accessor: (originalRow) =>
                    originalRow.isParent
                        ? originalRow.parent.key
                        : originalRow.key,
                className:
                    'w-20 md:w-24 2xl:w-28 flex-none font-medium leading-6 py-2  text-center',
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.type')),
                accessor: 'type',
                className:
                    'w-8 2xl:w-10 flex-none font-medium leading-6 py-2  text-center',
                Cell: ({ row }) => {
                    const taskType = row.original.isParent
                        ? row.original.parent.type
                        : row.original.type;
                    return (
                        <div className="flex content-center justify-center">
                            {typeof TYPE_ICON_MAP[taskType] === 'function'
                                ? TYPE_ICON_MAP[taskType]()
                                : TYPE_ICON_MAP['Story']()}
                        </div>
                    );
                },
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.discription')),
                id: 'description',
                accessor: (originalRow) => {
                    return originalRow.isParent
                        ? originalRow.parent.summary
                        : originalRow.summary;
                },
                className:
                    'w-16 flex-grow font-medium leading-6 py-2 break-words text-left ',
                extraClass: 'text-left ',
            },
            {
                Header: () => capitalizeFirstLetter('assignee'),
                id: 'assignee',
                accessor: (originalRow) => renderUserPhoto(originalRow),
                className: 'w-32 font-medium leading-6 py-2',
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.hours')),
                accessor: 'hours',
                className:
                    'w-12 flex-none font-medium leading-6 py-2 text-center ',
                extraClass: 'text-left',
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.label')),
                accessor: 'label',
                className:
                    'w-24 flex-none font-medium leading-6 py-2  text-center ',
                Cell: ({ row }) => {
                    const label = row.original.parent.label.toLowerCase();
                    const classLabel =
                        label === 'feature'
                            ? 'label-feature'
                            : 'label-not-feature';
                    return row.original.isParent ? (
                        <span className={`label ${classLabel}`}>{label}</span>
                    ) : null;
                },
            },
            {
                Header: () => capitalizeFirstLetter(t('si_page.confidence')),
                accessor: 'confidence',
                className:
                    'w-24 flex-none font-medium leading-6 py-2  text-center',
                Cell: ({ row }) => {
                    const value = row.original.parent.predictions[0].value;
                    const confidentClass = getConfidentClass(value);
                    return row.original.isParent ? (
                        <div className={`confidence-level ${confidentClass}`}>
                            <span className="confidence-division"></span>
                            <span className="confidence-division"></span>
                            <span className="confidence-division"></span>
                            <span className="confidence-division"></span>
                            <span className="confidence-division"></span>
                        </div>
                    ) : null;
                },
            },
        ],
        []
    );

    if (loading) {
        return (
            <div className="w-full flex justify-center items-center">
                <Loader
                    color={'#C2C7D7'}
                    size={35}
                    speedMultiplier={0.8}
                    css={{ margin: '30px auto', display: 'block' }}
                />
            </div>
        );
    }

    if (error || !data) {
        return <ErrorIndicator error={error} />;
    }

    const latestTasksWithUniqueParent = getUniqueListByParent(data).sort(
        (a, b) => b.timestamp - a.timestamp
    );

    const parentTaskWithSubTasks = [];

    latestTasksWithUniqueParent?.forEach((uniqueTask) => {
        // if parent task wasn`t updated in the time period, display latest subtask hours & timestam as tasks group`s parent
        let parentTaskWithLatestTimestamp = { ...uniqueTask, isParent: true };

        // check if parent task of the uniqueTask was updated in the time period
        const parentTasksUpdates = data
            .filter((task) => task.key === uniqueTask.parent.key)
            .sort((a, b) => b.timestamp - a.timestamp);

        if (parentTasksUpdates.length) {
            //pick latest parent task update
            const parentTask = parentTasksUpdates[0];

            // if parent task was modifified, display it as tasks group`s parent, but with latest subtask timestamp
            parentTaskWithLatestTimestamp = {
                ...parentTask,
                timestamp: uniqueTask.timestamp,
                isParent: true,
            };
        }

        const allSubtasks = data.filter(
            (task) => task.parent.key === uniqueTask.parent.key
        );

        parentTaskWithSubTasks.push(parentTaskWithLatestTimestamp);
        parentTaskWithSubTasks.push(...allSubtasks);
    });

    function renderUserPhoto(originalRow) {
        return (
            <div className="flex w-32 gap-2 items-center py-2">
                <div className="flex-none w-8 h-8 rounded-full overflow-hidden flex justify-center items-center ">
                    <img
                        className="w-full h-full"
                        alt={
                            originalRow?.assignee
                                ? originalRow?.assignee
                                : 'Default'
                        }
                        src={
                            originalRow?.assignee_photo
                                ? originalRow?.assignee_photo
                                : userAvatar
                        }
                    />
                </div>
                <p className="text-left">
                    {originalRow?.assignee ? originalRow?.assignee : 'Unknown'}
                </p>
            </div>
        );
    }

    console.log('columns', columns);

    return (
        <Table
            columns={columns}
            initialHiddenCols={initialHiddenCols}
            data={parentTaskWithSubTasks}
            isTableSettings={isTableSettings}
            setIsTableSettings={setIsTableSettings}
            onRowSelect={(rows) =>
                setSelectedTasks((prevState) => {
                    const ticketKeys = rows.map((row) => {
                        const ticketKey = row.original?.isParent
                            ? row.original?.parent.key
                            : row.original?.key;

                        return ticketKey;
                    });
                    const ticketKey = rows[0]?.original?.isParent
                        ? rows[0]?.original?.parent.key
                        : rows[0]?.original?.key;

                    return {
                        ...prevState,
                        issueKeys: ticketKeys ? ticketKeys : [],
                    };
                })
            }
            type={type}
            sprintsArray={sprintsArray}
        />
    );
};

export default memo(TasksTable);
