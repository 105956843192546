import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { BiInfoCircle } from 'react-icons/bi';
// Options Example
// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ];

// styleType options: lightBlue. darkBlue

const allOption = {
    label: 'Select all',
    value: '*',
};

export default function CustomSelect({
    options = [],
    isMulti = false,
    isSearchable = false,
    isClearable = false,
    placeholder = 'Choose an option',
    noOptionsMessage = ({ inputValue }) =>
        !inputValue ? 'No options found' : inputValue,
    wrapperClassName = '',
    onChange = (value) => console.log(value),
    defaultOption = null,
    styleType = 'white',
    width = '300px',
    height = '42px',
    selectAllLabel = 'All is selected',
    selectedValues = null,
}) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (selectedValues) {
            selectedValues?.length === options.length
                ? setSelectedOptions([allOption, ...selectedValues])
                : setSelectedOptions(selectedValues);
        } else {
            defaultOption?.length === options.length
                ? setSelectedOptions([allOption, ...defaultOption])
                : setSelectedOptions(defaultOption);
        }
    }, []);

    useEffect(() => {
        selectedValues?.length === options.length
            ? setSelectedOptions([allOption, ...selectedValues])
            : setSelectedOptions(selectedValues);
    }, [selectedValues]);

    let setCustomStyle = () => {};

    function handleChange(selected) {
        selectedValues === null && setSelectedOptions(selected);
        onChange(selected);
    }

    switch (styleType) {
        case 'lightBlue':
            setCustomStyle = () => ({
                control: (provided) => ({
                    display: 'flex',
                    padding: '2px 8px',
                    width: width,
                    outline: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#DCE8FF',
                    borderRadius: '5px',
                    opacity: '1',
                    height: height,
                    cursor: 'pointer',
                    flexWrap: 'nowrap',
                    color: '#4C72BD',
                    justifyContent: 'space-between',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                }),
                option: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                    cursor: 'pointer',
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                }),
                input: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                    opacity: '1',
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#4C72BD',
                    },
                }),
                clearIndicator: (provided) => ({
                    ...provided,
                    color: '#4C72BD',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#4C72BD',
                    },
                }),
                indicatorSeparator: (provided) => ({
                    opacity: '0',
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    height: '38px',
                    display: 'flex',
                    overflowY: 'auto',
                }),
                multiValue: (provided, state) => ({
                    display: 'flex',
                    alignItems: 'center',
                }),
                multiValueRemove: (provided) => ({
                    display: 'none',
                }),
                multiValueLabel: (provided, state) => ({
                    color: state.isSelected ? '#fff' : '#4C72BD',
                    paddingRight: '2px',
                }),
            });
            break;
        case 'smallBlue':
            setCustomStyle = () => ({
                control: (provided) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0px 0px',
                    width: width,
                    outline: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#1FB8D3',
                    borderRadius: '4px',
                    opacity: '1',
                    height: height,
                    cursor: 'pointer',
                    flexWrap: 'nowrap',
                    color: '#fff',
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '2px 3px',
                    display: 'flex',
                    position: 'rellative',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: '#fff',
                    fontSize: '14px',
                    fontWeight: '500',
                    position: 'absolute',
                }),
                option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? '#fff' : '#1FB8D3',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    padding: '4px 2px',
                    cursor: 'pointer',
                    backgroundColor: state.isSelected
                        ? '#1FB8D3'
                        : 'transparent',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#1FB8D3',
                    },
                    '&:focus': {
                        color: '#fff',
                        backgroundColor: '#1FB8D3',
                    },
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: '#fff',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '16px',
                }),
                input: (provided) => ({
                    ...provided,
                    color: '#fff',
                }),
                indicatorsContainer: (provided) => ({
                    color: '#4C72BD',
                    opacity: '1',
                    padding: '0px',
                }),
                dropdownIndicator: (provided) => ({
                    padding: '2px',

                    color: '#fff',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#fff',
                    },
                }),
                clearIndicator: (provided) => ({
                    ...provided,
                    color: '#fff',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#fff',
                    },
                }),
                indicatorSeparator: (provided) => ({
                    opacity: '0',
                }),
                menu: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                }),

                multiValue: (provided, state) => ({
                    display: 'flex',
                    alignItems: 'center',
                }),
                multiValueRemove: (provided) => ({
                    display: 'none',
                }),
                multiValueLabel: (provided, state) => ({
                    color: state.isSelected ? '#fff' : '#4C72BD',
                    paddingRight: '2px',
                }),
            });
            break;
        case 'white':
        default:
            setCustomStyle = () => ({
                control: (provided) => ({
                    ...provided,
                    padding: '2px 0',
                    width: '310px',
                    outline: 'none',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    opacity: '1',
                    height: '42px',
                    borderColor: '#dbeafe',
                    '&:hover': {
                        borderColor: '#dbeafe',
                        backgroundColor: '#f3f4f6',
                    },
                    cursor: 'pointer',
                    flexWrap: 'nowrap',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: '#c2c7d7',
                    '&:hover': {
                        color: '#86899f',
                    },
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    color: '#484a53',
                    opacity: '1',
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#c2c7d7',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#86899f',
                    },
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    backgroundColor: '#c2c7d7',
                    opacity: '1',
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    height: '38px',
                    display: 'flex',
                    overflowY: 'auto',
                    // flexWrap: 'nowrap',
                }),
            });
    }

    return (
        <div className={`${wrapperClassName}`}>
            {isMulti ? (
                <MultiSelect
                    options={options}
                    value={selectedOptions}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionsMessage}
                    onChange={handleChange}
                    styles={setCustomStyle()}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                        ValueContainer,
                        MultiValue,
                    }}
                    allowSelectAll={true}
                    selectAllLabel={selectAllLabel}
                />
            ) : (
                <Select
                    options={options}
                    defaultOption={defaultOption}
                    value={selectedOptions}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionsMessage}
                    onChange={handleChange}
                    styles={setCustomStyle()}
                />
            )}
        </div>
    );
}

function Option(props) {
    const [hoverStatus, setHoverStatus] = useState(false);
    if (props.isDisabled) {
        return (
            <div>
                <components.Option {...props}>
                    <div
                        className="flex gap-x-1 relative"
                        onMouseEnter={() => setHoverStatus(true)}
                        onMouseLeave={() => setHoverStatus(false)}
                    >
                        <p className={`ml-6 text-gray-400`}>{props.label}</p>
                        <div>
                            <BiInfoCircle size={16} color={'#C2C7D7'} />
                        </div>
                        {hoverStatus && (
                            <div className="absolute top-1  text-xs  w-full  py-1 px-3  z-50 shadow-md bg-gray-100 text-gray-600">
                                Connect user's Jira profile first{' '}
                            </div>
                        )}
                    </div>
                </components.Option>
            </div>
        );
    }
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    className="border border-solid border-custom-blue"
                />{' '}
                <label
                    className={`ml-2 ${props.isSelected ? 'text-white' : ''}`}
                >
                    {props.label}
                </label>
            </components.Option>
        </div>
    );
}

function ValueContainer({ children, ...props }) {
    const currentValues = props.getValue();

    let toBeRendered = children;
    if (currentValues.some((val) => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    let additionalClasses = '';

    if (toBeRendered[0]?.key !== 'placeholder' && toBeRendered[0]?.length > 2) {
        additionalClasses =
            'items-center w-9/12 overflow-hidden flex-grow text-xs';

        toBeRendered = [
            [
                ...toBeRendered[0].slice(0, 2),
                `+${toBeRendered[0].length - 2} more`,
            ],
            children[1],
        ];
    }

    return (
        <div
            className={`flex flex-nowrap w-9/12 pl-2 overflow-hidden items-center ${additionalClasses}`}
        >
            {' '}
            {toBeRendered}
        </div>
    );
}

const MultiValue = (props) => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = props.selectProps.selectAllLabel;
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

function MultiSelect(props) {
    if (props.allowSelectAll) {
        return (
            <Select
                {...props}
                options={
                    props.options.length
                        ? [props.allOption, ...props.options]
                        : [...props.options]
                }
                onChange={(selected, event) => {
                    if (selected !== null && selected.length > 0) {
                        if (
                            selected[selected.length - 1].value ===
                            props.allOption.value
                        ) {
                            return props.onChange([
                                props.allOption,
                                ...props.options,
                            ]);
                        }
                        let result = [];

                        if (selected.length === props.options.length) {
                            if (selected.includes(props.allOption)) {
                                result = selected.filter(
                                    (option) =>
                                        option.value !== props.allOption.value
                                );
                            } else if (
                                event.action === 'deselect-option' &&
                                event.option.value === props.allOption.value
                            ) {
                                result = [];
                            } else if (event.action === 'deselect-option') {
                                result = selected.filter(
                                    (option) =>
                                        option.value !== event.option.value &&
                                        option.value !== props.allOption.value
                                );
                            } else if (event.action === 'select-option') {
                                result = [props.allOption, ...props.options];
                            }
                            return props.onChange(result);
                        }
                    }

                    return props.onChange(selected);
                }}
            />
        );
    }

    return <Select {...props} />;
}

MultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};
