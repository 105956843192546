import { useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import ModifiedLinesChart from '../components/modified-lines-chart';
import ErrorBoundry from '../components/error-boundary';
import Loader from '../components/loader';
import { OrganisationsContext } from '../context/OrganisationsContext';
import { TimePeriodContext } from '../context/TimePeriodContext';
import moment from 'moment';
import { colors, groupedColors } from '../assets/colors';
import ErrorIndicator from '../components/error-indicator';
import { withUserAndOragnisations } from '../helpers/withUser';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import './styles.scss';
import { getRepositoriesChanges } from '../api/overview/OverviewAPI';
import useParamsQuery from '../hooks/useParamsQuery';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import { getStockTable, getRepoTableSize } from '../api/stock/StockAPI';
import UpgradeWindow from '../components/upgrade-window';
import { getGitProviders } from '../api/settings/GitProviderAPI';
import { getSelectedRepositories } from '../api/swarm/SwarmAPI';
import SettingsMessage from '../components/settings-message';
import { NAVIGATION_PATHS } from '../constants/navigationPaths';
import PanelTitle from '../components/panel-title';
import SprintInsightChart from './sprint-insights-page/si-chart';
import { capitalizeFirstLetter } from '../helpers/textFormatters';
import { RepoGroupsContext } from '../context/RepoGroupsContext';
import { fetchRepoGroups } from '../helpers/fetchRepoGroups';
import { groupReposWithGroupsList } from '../helpers/groupRepos';
import RepositoryStockTable from '../components/stock-repo-table';

const StockPage = () => {
    const { t } = useTranslation();
    const query = useParamsQuery();
    const { setIsOpen } = useTour();
    const { organisationsState } = useContext(OrganisationsContext);
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [timePeriodState] = useContext(TimePeriodContext);

    const { date_end, date_start } = timePeriodState;

    const { repoGroupsState, repoGroupsDispatch } =
        useContext(RepoGroupsContext);

    const request = useCallback(
        () =>
            getRepositoriesChanges({
                params: { date_start, date_end },
            }),
        [date_start, date_end]
    );

    const [repositoryStatisticsState] = useFetchDataAndSetState(request, [
        selectedOrganisationHash,
        timePeriodState.date_start,
        timePeriodState.date_end,
    ]);

    const [groupedRepositoriesData, setGroupedRepositoriesData] =
        useState(null);

    const tableRequest = useCallback(
        () =>
            getRepoTableSize({
                params: { date_start, date_end },
            }),
        [date_start, date_end]
    );

    const [repositoriesTableState] = useFetchDataAndSetState(
        tableRequest,
        [selectedOrganisationHash, date_start, date_end],
        { repo_list: [] }
    );

    const riskChartRequest = useCallback(async () => {
        let response = { data: null, status: 200 };

        if (repositoriesTableState?.data?.length) {
            const reposData = await Promise.allSettled(
                repositoriesTableState.data.map((repo) => {
                    const params = {
                        params: {
                            repository: repo.repo_name,
                            start: 0,
                            end: repo.table_rows - 1,
                            sort: 'branch',
                            direction: 'start',
                            date_start: date_start,
                            date_end: date_end,
                        },
                    };

                    return getStockTable(params);
                })
            );

            if (reposData?.length) {
                if (!reposData.find((value) => value.status === 'fulfilled')) {
                    response = {
                        ...response,
                        status: 404,
                        error: reposData[0].reason,
                    };
                } else {
                    let status = 200;

                    if (
                        !reposData.find(
                            (value) =>
                                value.status === 'fulfilled' &&
                                value.value.status !== 204
                        )
                    ) {
                        status = 204;
                    }

                    response = {
                        ...response,
                        data: reposData
                            .filter((repo) => repo.status === 'fulfilled')
                            .reduce(
                                (acc, repo) => {
                                    if (repo?.value?.data?.length) {
                                        const repoRiskData =
                                            repo?.value?.data.reduce(
                                                (acc, row) => {
                                                    switch (row.risk) {
                                                        case 'highest':
                                                        case 'high':
                                                            return {
                                                                ...acc,
                                                                high:
                                                                    acc.high +
                                                                    1,
                                                            };
                                                        case 'medium':
                                                            return {
                                                                ...acc,
                                                                medium:
                                                                    acc.medium +
                                                                    1,
                                                            };
                                                        case 'low':
                                                        case 'lowest':
                                                            return {
                                                                ...acc,
                                                                low:
                                                                    acc.low + 1,
                                                            };
                                                        default:
                                                            return acc;
                                                    }
                                                },
                                                {
                                                    low: 0,
                                                    medium: 0,
                                                    high: 0,
                                                }
                                            );

                                        return {
                                            [t('stock_page.table.low_risk')]:
                                                acc[
                                                    t(
                                                        'stock_page.table.low_risk'
                                                    )
                                                ] + repoRiskData.low,
                                            [t('stock_page.table.medium_risk')]:
                                                acc[
                                                    t(
                                                        'stock_page.table.medium_risk'
                                                    )
                                                ] + repoRiskData.medium,
                                            [t('stock_page.table.high_risk')]:
                                                acc[
                                                    t(
                                                        'stock_page.table.high_risk'
                                                    )
                                                ] + repoRiskData.high,
                                        };
                                    } else {
                                        return acc;
                                    }
                                },
                                {
                                    [t('stock_page.table.low_risk')]: 0,
                                    [t('stock_page.table.medium_risk')]: 0,
                                    [t('stock_page.table.high_risk')]: 0,
                                }
                            ),
                        status: 200,
                    };
                }
            }
        } else {
            response = { data: null, status: 204 };
        }

        return response;
    }, [repositoriesTableState?.data]);

    const [riskChartState] = useFetchDataAndSetState(
        riskChartRequest,
        [repositoriesTableState?.data],
        {}
    );

    const providersRequest = useCallback(() => getGitProviders(), []);
    const [gitProvidersState] = useFetchDataAndSetState(providersRequest, [
        selectedOrganisationHash,
    ]);

    const [selectedRepositoriesState] = useFetchDataAndSetState(
        getSelectedRepositories,
        [selectedOrganisationHash]
    );

    useEffect(() => {
        query.get('tour') === 'true' &&
            repositoryStatisticsState?.data &&
            setTimeout(() => {
                setIsOpen(true);
            }, 1000);
        return () => setIsOpen(false);
    }, [repositoryStatisticsState.data]);

    useEffect(() => {
        void fetchRepoGroups(repoGroupsDispatch);
    }, [selectedOrganisationHash]);

    useEffect(() => {
        if (
            repoGroupsState?.data?.length &&
            !repoGroupsState.loading &&
            !repositoriesTableState?.loading &&
            repositoriesTableState?.data?.length
        ) {
            const grouped = groupReposWithGroupsList(
                repoGroupsState?.data,
                repositoriesTableState?.data
            );

            setGroupedRepositoriesData(grouped);
        }
    }, [repositoriesTableState?.data, repoGroupsState?.data]);

    if (repositoryStatisticsState.error) {
        return (
            <>
                {repositoryStatisticsState.error.status === 403 && (
                    <UpgradeWindow accessTo={'Stock'} />
                )}
                <div
                    className={`py-4 px-16 ${
                        repositoryStatisticsState.error.status === 403
                            ? 'filter blur'
                            : ''
                    }`}
                >
                    <div
                        className="w-full bg-white py-4 px-4 rounded-lg shadow-md "
                        data-tour="r-step-page-10"
                    >
                        <h1 className="w-1/4 mb-2 font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-tertiary">
                            {t('stock_page.title')}
                        </h1>
                        <div className="">
                            <ErrorIndicator
                                error={repositoryStatisticsState.error}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function isModifiedLines(data) {
        return Boolean(
            Object.values(data)
                .map((repo) => {
                    const isLines = Boolean(
                        Array.isArray(repo) &&
                            repo.find(
                                (branch) =>
                                    branch.avg_branch_total_modified_lines !== 0
                            )
                    );
                    return isLines;
                })
                .find((item) => item === true)
        );
    }

    return (
        <div className="">
            {!gitProvidersState?.data?.length &&
            !repositoryStatisticsState.loading &&
            !repositoriesTableState.loading ? (
                <SettingsMessage
                    messageText="Seems like you haven't added your Git provider yet. Add your Git provider to start tracking Leaks, Stock & measuring DORA metrics."
                    link={NAVIGATION_PATHS.settingsGitProviders}
                    linkText="Go to Git Providers settings"
                />
            ) : null}
            {gitProvidersState?.data?.length &&
            !selectedRepositoriesState?.data?.length &&
            !selectedRepositoriesState?.loading &&
            !repositoryStatisticsState.loading &&
            !repositoriesTableState.loading ? (
                <SettingsMessage
                    messageText="Seems like you haven't selected repositories to track yet. Go to Repositories and click 'Add Repository' button to select some."
                    link={NAVIGATION_PATHS.dora}
                    linkText="Go to Repositories"
                />
            ) : null}
            <div className="flex gap-2  mt-4 mb-8">
                <div
                    className="w-full bg-white rounded-lg shadow-md py-2 px-4"
                    data-tour="r-step-page-10"
                >
                    <h1 className="w-1/4 mb-2 font-display inline-block  text-28 text-gray-700 font-light border-b-2 border-solid border-theme-tertiary">
                        {t('stock_page.title')}
                    </h1>
                    {repositoryStatisticsState.loading ||
                    repositoriesTableState.loading ||
                    repoGroupsState.loading ? (
                        <div className="my-8">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            <ErrorBoundry>
                                {transformRepositoriesChangesData(
                                    repositoryStatisticsState.data,
                                    repoGroupsState?.data
                                )?.length === 0 ||
                                !repositoryStatisticsState?.data ? (
                                    <p>{t('common.no_data')}</p>
                                ) : (
                                    <ModifiedLinesChart
                                        data={transformRepositoriesChangesData(
                                            repositoryStatisticsState.data,
                                            repoGroupsState?.data
                                        )}
                                        isModifiedLines={isModifiedLines(
                                            repositoryStatisticsState.data
                                        )}
                                    />
                                )}
                            </ErrorBoundry>
                        </div>
                    )}
                </div>

                <WhitePanelContentWrapper className="relative w-96">
                    <PanelTitle
                        title={capitalizeFirstLetter(
                            t('stock_page.risk_chart')
                        )}
                    />
                    <SprintInsightChart
                        data={riskChartState.data}
                        error={riskChartState.error}
                        loading={
                            repositoriesTableState.loading ||
                            riskChartState.loading
                        }
                        customSlices={[
                            {
                                color: '#92CE52',
                            },
                            {
                                color: '#F8C238',
                            },
                            {
                                color: '#E11E1E',
                            },
                        ]}
                        legendPosition="top"
                        additionalOptions={{
                            height: 280,
                            chartArea: {
                                left: 0,
                                top: 40,

                                width: '100%',
                                height: '80%',
                            },
                        }}
                    />
                </WhitePanelContentWrapper>
            </div>
            {repositoriesTableState?.loading || repoGroupsState?.loading ? (
                <div className="my-8">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    </div>
                </div>
            ) : null}
            {!repositoriesTableState?.loading &&
            !repositoriesTableState?.data?.length ? (
                <div className="my-8">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {t('common.no_data')}
                    </div>
                </div>
            ) : null}
            {groupedRepositoriesData?.length > 0 &&
            repositoriesTableState?.data?.length > 0 &&
            !repositoriesTableState.loading &&
            !repoGroupsState.loading &&
            groupedRepositoriesData.filter(
                (group) => group.repositories?.length > 0
            )?.length
                ? groupedRepositoriesData
                      .filter((group) => group.repositories?.length > 0)
                      .map((group) => {
                          return (
                              <div key={group.id}>
                                  <p className="my-4 font-display text-gray-700 font-bold text-lg	leading-5">
                                      {group.name}
                                  </p>
                                  {group.repositories.map((repository) => {
                                      return (
                                          <RepositoryStockTable
                                              repository={repository}
                                              key={repository['repo_name']}
                                          />
                                      );
                                  })}
                              </div>
                          );
                      })
                : repositoriesTableState?.data?.length &&
                  repositoriesTableState?.data?.map((repository) => {
                      return (
                          <RepositoryStockTable
                              repository={repository}
                              key={repository['repo_name']}
                          />
                      );
                  })}
        </div>
    );
};

const transformRepositoriesChangesData = (response, groupsData) => {
    const getLabels = (buckets) => {
        const labels = [];

        const timestamps = Object.keys(buckets);
        timestamps?.forEach((item, index, array) => {
            let start_date = moment(item).utc();
            let end_date = moment(array[index + 1])
                .subtract(1, 'seconds')
                .utc();
            let now_date = moment().utc();
            if (index === array.length - 1) {
                let diff = moment(item)
                    .subtract(1, 'seconds')
                    .diff(moment(array[index - 1]), 'days');
                if (diff) {
                    labels.push(
                        `${start_date.format('MMM Do')} - ${now_date.format(
                            'MMM Do'
                        )}`
                    );
                } else {
                    labels.push(`${start_date.format('MMM Do')}`);
                }
            } else {
                let diff = moment(array[index + 1])
                    .subtract(1, 'seconds')
                    .diff(moment(item), 'days');
                if (diff) {
                    labels.push(
                        `${start_date.format('MMM Do')} - ${end_date.format(
                            'MMM Do'
                        )}`
                    );
                } else {
                    labels.push(`${start_date.format('MMM Do')}`);
                }
            }
        });
        return labels;
    };

    // in case there are no groups or group endpoin returned error
    const getColor = (the_appointment, i, dataLength) => {
        let y = i - 1;

        if (dataLength * 2 <= Object.keys(colors).length) {
            y = y * 2;
        }

        const checkIfFitColorsRange = (number) => {
            if (number > Object.keys(colors).length - 1) {
                y = number - Object.keys(colors).length;
                checkIfFitColorsRange(y);
            } else {
                return;
            }
        };

        if (the_appointment === 'main') {
            checkIfFitColorsRange(y);
            return colors[y]?.main;
        }
        checkIfFitColorsRange(y);
        return colors[y]?.hover;
    };

    const getGroupedColor = (the_appointment, repoIndex, groupIndex) => {
        let groupedColorsGroupIndex = groupIndex;
        let groupedColorsRepoIndex = repoIndex;

        while (groupedColorsGroupIndex > groupedColors.length - 1) {
            groupedColorsGroupIndex -= groupedColors.length;
        }

        while (
            groupedColorsRepoIndex >
            groupedColors[groupedColorsGroupIndex]?.length - 1
        ) {
            groupedColorsRepoIndex -=
                groupedColors[groupedColorsGroupIndex].length;
        }

        if (the_appointment === 'main') {
            return groupedColors[groupedColorsGroupIndex][
                groupedColorsRepoIndex
            ]?.main;
        }
        return groupedColors[groupedColorsGroupIndex][groupedColorsRepoIndex]
            ?.hover;
    };

    const getDatasets = (data, groups) => {
        const datasets = [];
        const date_array = [];

        const groupedData = groups
            ?.map((group) => {
                return {
                    ...group,
                    repositories: Object.entries(data).filter((repoArray) =>
                        group.repositories.find(
                            (groupRepo) => repoArray[0] === groupRepo?.name
                        )
                    ),
                };
            })
            ?.filter((updatedGroup) => updatedGroup?.repositories?.length);

        for (let date in data.buckets) {
            date_array.push(date);
        }

        const _getModifiedLines = (dataset_data_array) => {
            const modifiedLines = [];
            date_array?.forEach((item) => {
                const have_date = dataset_data_array.find((el) => {
                    return el.bucket === item;
                });
                if (have_date) {
                    modifiedLines.push(
                        have_date.avg_branch_total_modified_lines
                    );
                } else {
                    modifiedLines.push(0);
                }
            });
            return modifiedLines;
        };
        const _getBranches = (dataset_data_array) => {
            const unic_branches = [];
            date_array?.forEach((item) => {
                const have_date = dataset_data_array.find((el) => {
                    return el.bucket === item;
                });
                if (have_date) {
                    unic_branches.push(have_date.unique_branches);
                } else {
                    unic_branches.push('no data');
                }
            });
            return unic_branches;
        };

        if (groupedData?.length) {
            groupedData.map((groupWithRepos, groupIndex) => {
                groupWithRepos?.repositories.map((repo, repoIndex) => {
                    if (repo !== 'buckets') {
                        let dataset = {
                            type: 'bar',
                            label: repo[0],
                            backgroundColor: getGroupedColor(
                                'main',
                                repoIndex,
                                groupIndex
                            ),
                            borderWidth: 1,
                            data: _getModifiedLines(repo[1]),
                            branches: _getBranches(repo[1]),
                        };
                        datasets.push(dataset);
                    }
                });
            });
        } else {
            for (let repo in data) {
                if (repo !== 'buckets') {
                    let dataset = {
                        type: 'bar',
                        label: repo,
                        backgroundColor: getColor(
                            'main',
                            Object.keys(data).indexOf(repo),
                            Object.keys(data)?.length - 1
                        ),
                        borderWidth: 1,
                        data: _getModifiedLines(data[repo]),
                        branches: _getBranches(data[repo]),
                    };
                    datasets.push(dataset);
                }
            }
        }

        return datasets;
    };

    let data = {
        labels: getLabels(response.buckets),
        datasets: getDatasets(response, groupsData),
    };

    return data;
};

export default withUserAndOragnisations(StockPage);
