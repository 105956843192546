import React, { useEffect, useRef, useState } from 'react';
import { IoPauseOutline, IoPlayOutline } from 'react-icons/io5';
import Button from '../shared/button';

function AudioPlayButton({ audioFilePath }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(audioFilePath));

    useEffect(() => {
        const isAutoplay = localStorage.getItem('autoplay');
        if (audioRef.current.src !== audioFilePath && audioFilePath) {
            audioRef.current.src = audioFilePath;
            if (isAutoplay) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }
        if (!audioFilePath) {
            audioRef.current.pause();
            audioRef.current.src = '';
        }

        return () => {
            audioRef.current.pause();
        };
    }, [audioFilePath]);

    const toggleAudio = () => {
        const audio = audioRef.current;
        const isAutoplay = localStorage.getItem('autoplay');
        if (isPlaying) {
            if (isAutoplay) {
                localStorage.removeItem('autoplay');
            }
            audio.pause();
        } else {
            if (!isAutoplay) {
                localStorage.setItem('autoplay', true);
            }
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };
    return (
        <Button
            onClick={toggleAudio}
            extraClasses="absolute right-0 bottom-0 w-8 h-8 rounded-full bg-theme-tertiary flex justify-center items-center text-white"
        >
            {isPlaying ? (
                <IoPauseOutline data-testid={'IoPauseOutline'} />
            ) : (
                <IoPlayOutline data-testid={'IoPlayOutline'} />
            )}
        </Button>
    );
}

export default AudioPlayButton;
