import React from 'react';

function HoursPageTimesheetOverviewHeader({ weekRange }) {
    return (
        <div className="flex justify-between border-b-2 border-solid border-gray-300 py-3">
            <div className="w-64 font-bold px-4">Project</div>
            <div className="grid grid-cols-8 justify-between w-[512px] relative gap-y-1">
                {weekRange.map((day) => (
                    <div key={day.format('YYYY-MM-DD')} className="px-4 w-18">
                        <div className="font-bold text-center">
                            {day.format('dd')}
                        </div>
                        <div>
                            <small className="text-xs text-center">
                                ({day.format('D-M')})
                            </small>
                        </div>
                    </div>
                ))}
                <div className="font-bold px-4 text-center">Tot.</div>
            </div>
            <div className="w-48" />
        </div>
    );
}

export default HoursPageTimesheetOverviewHeader;
