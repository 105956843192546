import React from 'react';
import Loader from '../../loader';
import { twMerge } from 'tailwind-merge';

function Button({
    children,
    extraClasses,
    isLoading,
    onClick,
    variant,
    type = 'button',
    disabled,
    title,
    testId,
    onBlur = () => {},
}) {
    function getVariantStyle() {
        switch (variant) {
            // case 'red':
            //     return 'bg-red-700 hover:bg-red-600 text-white hover:text-white';
            case 'white':
                return 'p-2 bg-white border-tailwind border-gray-500 border-solid border-[1px] rounded text-gray-500 flex justify-between items-center cursor-pointer';

            case 'green':
                return 'save-button';
            case 'blue':
                return 'inline-flex justify-center py-[6px] px-3 m-0 border border-solid border-theme-tertiary rounded font-display text-white font-semibold bg-theme-tertiary hover:text-white hover:bg-theme-tertiary-dark hover:border-theme-tertiary-dark disabled:pointer-events-none leading-[17px]';
            case 'darkBlue':
                return 'inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border hover:bg-theme-tertiary border-transparent rounded-md  disabled:cursor-not-allowed min-w-[110px]  font-sans ';
            case 'gray':
                return 'm-0 inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border  border-solid border-gray-300 hover:border-gray-200 rounded-md hover:bg-gray-200';
            case 'red':
                return 'inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed ';
            case 'whiteHoverBlue':
                return 'btn-white-hover-blue leading-[17px]';
            case 'grayHoverBlue':
                return 'add-repository-toggler text-xs  border border-solid border-gray-400 text-gray-400 font-display px-1 py-0.5 rounded hover:bg-theme-tertiary hover:text-white hover:border-theme-tertiary';
            case 'blueText':
                return 'inline-flex justify-center px-6 py-2   text-theme-tertiary hover:underline  rounded-md disabled:cursor-not-allowed text-lg';
            case 'redText':
                return 'font-display text-sm underline text-red-text disabled:hidden';
            case 'yellowText':
                return 'text-yellow-700 py-1 underline';
            case 'transpatentHoverGray':
                return 'btn border-blue-100 text-gray-400 hover:text-gray-500 border border-solid  max-h-14 hover:bg-gray-100';
            case 'text':
                return 'text-gray-400 underline';
            case 'authGray':
                return 'btn bg-gray-300 hover:bg-gray-400 text-gray-700 max-h-14 flex justify-center items-center';
            case 'authTransparentGray':
                return 'text-left hover:underline px-2 py-2 text-gray-500 text-lg flex gap-x-2 mb-4 hover:bg-gray-100 rounded';
            case 'auth':
                return 'btn bg-blue-700 hover:bg-blue-600 text-white hover:text-white max-h-14 flex justify-center items-center';
            case 'default':
            default:
                return '';
        }
    }

    return (
        <button
            className={twMerge(
                `${getVariantStyle()}   disabled:opacity-50 disabled:cursor-not-allowed `,
                extraClasses
            )}
            onClick={onClick}
            onBlur={onBlur}
            type={type}
            disabled={disabled}
            title={title}
            data-testid={testId ? testId : type}
        >
            {isLoading ? null : children}
            {isLoading ? <Loader color={'white'} size={24} /> : null}
        </button>
    );
}

export default Button;
