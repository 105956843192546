import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../../helpers/textFormatters';
import FormField from './sre-form-field';
import showNotification from '../../../helpers/showNotification';
import { deleteSerive, updateService } from '../../../api/settings-slo/slo';
import FeaturesTabs from './sre-tabs-features';
import ModalWrapper from '../../../components/shared/modals/modal-wrapper';
import { useCallback } from 'react';
import { replaceSpacesToUnderscores } from '../../../helpers/replaceSpacesToUnderscores';
import { MdEdit } from 'react-icons/md';
import Button from '../../../components/shared/button';
import DeleteContent from '../../../components/shared/modals/delete-content';

function ServiceEditForm({
    service,
    serviceState,
    serviceDispatch,
    services,
    servicesDispatch,
    onGoBack,
}) {
    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState({
        service: service?.service,
        description: service?.description,
        url: service?.url,
    });

    const [modalState, setModalState] = useState(false);
    const [isServiceDataEditing, setIsServiceDataEditing] = useState(false);

    useEffect(() => {
        setInitialValues({
            service: service?.service,
            description: service?.description,
            url: service?.url,
        });
    }, [service]);

    const addFeatureText =
        capitalizeFirstLetter(t('button.add')) + ' ' + 'SLO' + ' +';

    const sendDeleteServiceRequest = useCallback(() => {
        deleteSerive(service?.service)
            .then((response) => {
                if (response.status === 200) {
                    const servicesData = services?.data || [];
                    const updatedServices = servicesData.filter(
                        (item) => item.service !== service?.service
                    );

                    servicesDispatch({
                        type: 'SET_DATA',
                        payload: [...updatedServices],
                    });

                    onGoBack();

                    showNotification(
                        `Service "${service?.service}" has been deleted`,
                        'success'
                    );
                }
            })
            .catch((error) => {
                servicesDispatch({ type: 'SET_ERROR', payload: error });
                showNotification(
                    error.response?.data ||
                        `Service "${service?.service}" has not been deleted`
                );
            });
    }, []);

    function checkIsFieldsEmpty(serviceName) {
        const isEmpty = serviceName === '' ? true : false;

        if (isEmpty) {
            showNotification('Service name is required', 'warning');
        }

        return isEmpty;
    }

    async function handleServiceDataUpdate(values) {
        const isEmpty = checkIsFieldsEmpty(values?.service);

        if (isEmpty) {
            return;
        }

        try {
            const response = await updateService(service?.service, {
                ...values,
                service_name: replaceSpacesToUnderscores(values?.service),
            });
            if (response.status === 200) {
                const changedServices = services?.data?.map((item) => {
                    if (item.service === service?.service) {
                        return values;
                    }
                    return item;
                });
                servicesDispatch({
                    type: 'SET_DATA',
                    payload: [...changedServices],
                });

                showNotification(
                    `Service "${service.service}" has been changed`,
                    'success'
                );
            }
        } catch (error) {
            console.log(error);
            servicesDispatch({ type: 'SET_ERROR', payload: error });
            showNotification(
                `Service "${service.service}" has not been changed`
            );
        }
    }

    return (
        <div className="flex flex-col h-full w-full gap-4">
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    service: Yup.string().required(t('common.form.required')),
                    description: Yup.string(),
                    url: Yup.string(),
                })}
                onSubmit={async (values) => {
                    handleServiceDataUpdate(values);
                }}
            >
                {({ errors, touched, values }) => {
                    return (
                        <Form>
                            {isServiceDataEditing ? (
                                <div className=" mb-3 flex flex-col gap-2">
                                    <div>
                                        <div className="border-b-2 border-solid border-theme-primary mb-4">
                                            <FormField
                                                attributeName={'service'}
                                                fieldType={'service'}
                                                placeholderText={
                                                    'Enter service name'
                                                }
                                                error={errors.service}
                                                touched={touched.service}
                                                className="font-display text-28 text-gray-700 font-light "
                                            />
                                        </div>

                                        <FormField
                                            attributeName={'description'}
                                            fieldType={'description'}
                                            placeholderText={
                                                'Enter service description'
                                            }
                                            labelClassName="font-light leading-5 pb-3"
                                            error={errors.description}
                                            touched={touched.description}
                                        />
                                        <FormField
                                            attributeName={'url'}
                                            fieldType={'url'}
                                            placeholderText={
                                                'Enter service URL'
                                            }
                                            labelClassName="font-light leading-5 pb-3"
                                            error={errors.url}
                                            touched={touched.url}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="flex flex-col items-start">
                                        <div className="flex gap-x-2">
                                            <h1 className="font-display text-28 text-gray-700 font-light border-b-2 border-solid border-theme-primary">
                                                {capitalizeEveryWordFirstLetter(
                                                    service?.service
                                                        ? service?.service
                                                        : 'No name'
                                                )}
                                            </h1>
                                            <Button
                                                extraClasses={'w-6 h-6'}
                                                type="button"
                                                onClick={() =>
                                                    setIsServiceDataEditing(
                                                        true
                                                    )
                                                }
                                            >
                                                <MdEdit className="fill-current grey-icon" />
                                            </Button>
                                        </div>
                                        {service?.description ? (
                                            <p className="mt-4 text-gray-500 ">
                                                {service?.description}
                                            </p>
                                        ) : null}
                                        {service?.url ? (
                                            <div className="flex mt-2 gap-1 items-center">
                                                <p className="text-xs text-gray-500 font-light">
                                                    Operational dashboard URL:{' '}
                                                </p>
                                                <a
                                                    className="block text-xs text-theme-tertiary underline "
                                                    target="_blank"
                                                    href={service?.url}
                                                >
                                                    {service?.url}
                                                </a>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}

                            {isServiceDataEditing ? (
                                <div className="flex justify-between items-center">
                                    <div className="flex justify-end">
                                        <Button
                                            type="button"
                                            extraClasses={'min-w-[110px]'}
                                            onClick={() =>
                                                setIsServiceDataEditing(false)
                                            }
                                            variant="blueText"
                                        >
                                            {capitalizeFirstLetter('Go back')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            extraClasses={
                                                'min-w-[110px] font-sans  text-lg font-normal'
                                            }
                                            variant="blue"
                                            disabled={
                                                !Object.entries(values).find(
                                                    (value) => {
                                                        return (
                                                            (initialValues[
                                                                value[0]
                                                            ] &&
                                                                value[1] !==
                                                                    initialValues[
                                                                        value[0]
                                                                    ]) ||
                                                            (!initialValues[
                                                                value[0]
                                                            ] &&
                                                                value[1]
                                                                    ?.length)
                                                        );
                                                    }
                                                )
                                            }
                                        >
                                            {capitalizeFirstLetter(
                                                t('button.update')
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </Form>
                    );
                }}
            </Formik>
            <div>
                <p className="text-2xl font-display font-light my-4">
                    Manage SLOs:
                </p>
                <FeaturesTabs
                    selectedService={service}
                    featuresState={serviceState}
                    featuresDispatch={serviceDispatch}
                    addFeatureText={addFeatureText}
                    t={t}
                />
            </div>
            <div className="flex justify-between items-center">
                <Button
                    type="button"
                    variant="redText"
                    extraClasses={'min-w-[110px] text-xs font-sans font-medium'}
                    onClick={() => setModalState(true)}
                >
                    Delete service
                </Button>
                <Button
                    data-btn-name="back"
                    type="button"
                    variant="blueText"
                    extraClasses={'min-w-[110px] text-lg'}
                    onClick={onGoBack}
                >
                    Go back
                </Button>
            </div>
            <ModalWrapper
                modalState={modalState}
                setModalState={setModalState}
                title={t('sre.modal_delete')}
            >
                <DeleteContent
                    handleAction={sendDeleteServiceRequest}
                    setModalState={() => setModalState(!modalState)}
                >
                    <p className="text-xl text-gray-500">
                        {t('sre.modal_delete_text')}
                        <span className="font-medium">
                            {t('sre.modal_delete_feature', {
                                name: service?.service,
                            })}
                        </span>
                        {'?'}
                    </p>
                </DeleteContent>
            </ModalWrapper>
        </div>
    );
}

export default ServiceEditForm;
