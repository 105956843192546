import React, { useContext, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { nl, enGB } from 'date-fns/locale';
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Button from '../../../../components/shared/button';
import { UserContext } from '../../../../context/UserContext';

const HoursPageTimesheetToolbarDatePicker = ({
    filterDate,
    setFilterDate,
    updateDates,
}) => {
    const { userState } = useContext(UserContext);
    const [showDayPicker, setShowDayPicker] = useState(false);

    const handleDayChange = (date) => {
        setFilterDate(moment(date));
        setShowDayPicker(false);
        updateDates();
    };
    return (
        <div className="relative">
            <Button
                onClick={() => setShowDayPicker(!showDayPicker)}
                variant={'transpatentHoverGray'}
                extraClasses={'h-8'}
            >
                <FaRegCalendarAlt />
            </Button>
            {showDayPicker && (
                <div className="absolute w-[312px] left-0 top-12 bg-white shadow-lg rounded z-10">
                    <DayPicker
                        selectedDays={filterDate.toDate()}
                        showWeekNumbers={true}
                        locale={userState?.data?.lang === 'nl' ? nl : enGB}
                        firstDayOfWeek={1}
                        onDayClick={handleDayChange}
                    />
                </div>
            )}
        </div>
    );
};

export default HoursPageTimesheetToolbarDatePicker;
