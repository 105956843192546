import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Loader from '../loader';
import {
    GoogleCloudPlatformIcon,
    AWSCloudWatchIcon,
} from '../../assets/svg.js';
import showNotification from '../../helpers/showNotification.js';
import {
    replaceSpacesToUnderscores,
    replaceUnderscoresToSpaces,
} from '../../helpers/replaceSpacesToUnderscores';
import {
    createSREBackend,
    deleteSREBackend,
    updateSREBackend,
    testSREBackend,
} from '../../api/settings/sreAPI';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import ModalWrapper from '../shared/modals/modal-wrapper';
import ModalBackendUpdateContent from '../modal-update-backend-content';
import Button from '../shared/button';
import DeleteContent from '../shared/modals/delete-content/index.js';
import SaveButton from '../shared/save-button/index.js';

const SREBackendItem = ({
    sreBackendData,
    backendsList,
    permissionForWrite,
    onDeleteEmptyItem,
    onUpdateBackend,
}) => {
    const { t } = useTranslation();
    const { type } = sreBackendData;
    const [name, setName] = useState('');
    const [isTestPassed, setIsTestPassed] = useState(null);
    const [projectId, setProjectId] = useState('');
    const [googleCredentials, setGoogleCredentials] = useState('');

    const [awsAccessKey, setAwsAccessKey] = useState('');
    const [awsAccessSecret, setAwsAccessSecret] = useState('');
    const [isSecretShown, setIsSecretShown] = useState(false);
    const [awsRegion, setAwsRegion] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

    useEffect(() => {
        !name &&
            sreBackendData?.name &&
            setName(replaceUnderscoresToSpaces(sreBackendData.name));

        setIsTestPassed(sreBackendData.enabled ?? null);

        if (type === 'cloud_monitoring') {
            sreBackendData.project_id &&
                setProjectId(sreBackendData.project_id);
            sreBackendData.credentials &&
                setGoogleCredentials(
                    Buffer.from(sreBackendData.credentials, 'base64').toString()
                );
        }
        if (type === 'cloud_watch') {
            sreBackendData.region && setAwsRegion(sreBackendData.region);
            sreBackendData.access_key &&
                setAwsAccessKey(sreBackendData.access_key);
            sreBackendData.access_secret &&
                setAwsAccessSecret(sreBackendData.access_secret);
        }
    }, [sreBackendData]);

    useEffect(() => {
        // new backends with unfilled fields
        const condition1 =
            sreBackendData.id &&
            (!name || !projectId || !googleCredentials) &&
            type === 'cloud_monitoring';
        const condition2 =
            sreBackendData.id &&
            (!name || !awsAccessKey || !awsAccessSecret || !awsRegion) &&
            type === 'cloud_watch';

        // existing backends with no changes
        const condition3 =
            type === 'cloud_monitoring' &&
            replaceSpacesToUnderscores(name, true) === sreBackendData.name &&
            projectId === sreBackendData.project_id &&
            googleCredentials ===
                Buffer.from(sreBackendData.credentials, 'base64').toString();

        const condition4 =
            type === 'cloud_watch' &&
            replaceSpacesToUnderscores(name, true) === sreBackendData.name &&
            awsAccessKey === sreBackendData.access_key &&
            awsAccessSecret === sreBackendData.access_secret &&
            awsRegion === sreBackendData.region;

        if (condition1 || condition2 || condition3 || condition4) {
            setIsUpdateDisabled(true);
        } else {
            setIsUpdateDisabled(false);
        }
    }, [
        name,
        projectId,
        googleCredentials,
        awsAccessKey,
        awsAccessSecret,
        awsRegion,
    ]);

    const renderLogoBlock = () => {
        if (type) {
            if (type === 'cloud_monitoring') {
                return (
                    <div className="flex items-center gap-x-7">
                        <GoogleCloudPlatformIcon />
                        <h2 className="font-display font-light text-xl leading-6 text-black">
                            Google Cloud Monitoring
                        </h2>
                    </div>
                );
            } else if (type === 'cloud_watch') {
                return (
                    <div className="flex items-center gap-x-7">
                        <AWSCloudWatchIcon />
                        <h2 className="font-display font-light text-xl leading-6 text-black">
                            AWS Cloud Watch
                        </h2>
                    </div>
                );
            }
        }
    };

    const testBackend = async () => {
        let data = {};

        //Validation
        if (type === 'cloud_watch') {
            if (!name) {
                showNotification(
                    `${capitalizeFirstLetter(
                        t('settings_page.sre_tab.name')
                    )} is required`
                );
                return;
            }
            if (!awsAccessKey) {
                showNotification(`Access Key ID is required`);
                return;
            }

            if (!awsAccessSecret) {
                showNotification(`Access Key Secret is required`);
                return;
            }

            if (!awsRegion) {
                showNotification(`Region is required`);
                return;
            }
        }

        if (type === 'cloud_monitoring') {
            if (!name) {
                showNotification(
                    `${capitalizeFirstLetter(
                        t('settings_page.sre_tab.name')
                    )} is required`
                );
                return;
            }
            if (!projectId) {
                showNotification(`Project ID is required`);
                return;
            }

            if (!googleCredentials) {
                showNotification(
                    `${capitalizeFirstLetter(
                        t('settings_page.sre_tab.json')
                    )} is required`
                );
                return;
            }
        }

        setIsLoading(true);

        if (type === 'cloud_watch') {
            data = {
                type: 'cloud_watch',
                name: replaceSpacesToUnderscores(name, true),
                access_key: awsAccessKey,
                access_secret: awsAccessSecret,
                region: awsRegion,
            };
        }

        if (type === 'cloud_monitoring') {
            const base64Creds =
                Buffer.from(googleCredentials).toString('base64');
            data = {
                type: 'cloud_monitoring',
                name: replaceSpacesToUnderscores(name, true),
                project_id: projectId,
                credentials: base64Creds,
            };
        }

        try {
            const response = await testSREBackend(data);
            setIsLoading(false);
            response.status === 200 &&
                showNotification('Backend is valid', 'success');
            setIsTestPassed(true);
        } catch (error) {
            if (error.status === 403 || error.response.status === 403) {
                setIsTestPassed(false);
            }
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message || error.data.message}`
            );
        }
    };

    const checkIsNameUnique = () => {
        if (!backendsList.length) {
            return true;
        } else {
            const isNameInList = backendsList.find(
                (backend) =>
                    backend.name === replaceSpacesToUnderscores(name, true)
            );
            const isNameUnique = Boolean(!isNameInList);

            return isNameUnique;
        }
    };

    const handleFile = async (file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => setGoogleCredentials(e.target.result);
    };

    const handleCreateBackend = async () => {
        if (!checkIsNameUnique()) {
            showNotification('Name must be unique');
            return;
        }
        let data = {};
        setIsLoading(true);

        if (type === 'cloud_watch') {
            data = {
                type: 'cloud_watch',
                name: replaceSpacesToUnderscores(name, true),
                access_key: awsAccessKey,
                access_secret: awsAccessSecret,
                region: awsRegion,
            };
        }

        if (type === 'cloud_monitoring') {
            const base64Creds =
                Buffer.from(googleCredentials).toString('base64');
            data = {
                type: 'cloud_monitoring',
                name: replaceSpacesToUnderscores(name, true),
                project_id: projectId,
                credentials: base64Creds,
            };
        }

        try {
            const response = await createSREBackend(data);
            setIsLoading(false);
            response.status === 200 &&
                showNotification('Backend successfully created', 'success');
            response.status === 200 && onUpdateBackend();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message || error.data.message}`
            );
        }
    };

    const handleUpdateBackend = async () => {
        if (
            replaceSpacesToUnderscores(name, true) !== sreBackendData.name &&
            !checkIsNameUnique()
        ) {
            showNotification('Name must be unique');
            return;
        }
        setIsUpdateModalOpen(true);
    };

    const handleUpdateBackendConfirmation = async () => {
        let data = {};
        setIsLoading(true);

        if (type === 'cloud_watch') {
            data = {
                type: 'cloud_watch',
                name: replaceSpacesToUnderscores(name, true),
                access_key: awsAccessKey,
                access_secret: awsAccessSecret,
                region: awsRegion,
            };
        }

        if (type === 'cloud_monitoring') {
            const base64Creds =
                Buffer.from(googleCredentials).toString('base64');
            data = {
                type: 'cloud_monitoring',
                name: replaceSpacesToUnderscores(name, true),
                project_id: projectId,
                credentials: base64Creds,
            };
        }

        try {
            const response = await updateSREBackend(sreBackendData.name, data);
            setIsLoading(false);
            response.status === 200 &&
                showNotification('Backend successfully updated', 'success');
            response.status === 200 && onUpdateBackend();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message || error.data.message}`
            );
        }
    };

    const handleDeleteClick = () => {
        sreBackendData.id
            ? onDeleteEmptyItem(sreBackendData.id)
            : setIsDeleteModalOpen(true);
    };

    const handleToggleSecret = () => {
        setIsSecretShown(!isSecretShown);
    };

    const handleDeleteBackend = async () => {
        setIsLoading(true);

        try {
            const response = await deleteSREBackend(sreBackendData.name);
            setIsLoading(false);
            response.status === 200 &&
                showNotification('Backend successfully deleted', 'success');
            response.status === 200 && onUpdateBackend();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message || error.data.message}`
            );
        }
    };

    return (
        <li className="w-480 h-450 flex flex-col bg-white rounded-lg shadow-workspace-item p-4 overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
            <div className="flex justify-between items-center mb-4">
                {renderLogoBlock()}
                <p
                    className={`font-light  leading-5 text-sm ${
                        (isTestPassed === null || isTestPassed === undefined) &&
                        'text-gray-500'
                    } capitalize ${
                        isTestPassed === true && 'text-success-green-300'
                    } ${isTestPassed === false && 'text-red-text'}`}
                >
                    {(isTestPassed === null || isTestPassed === undefined) &&
                        'Untested'}
                    {isTestPassed === false && 'Invalid (failed test)'}
                    {isTestPassed && 'Valid'}
                </p>
            </div>

            <div className="flex-grow ">
                <div className="mb-2">
                    <label
                        className="block mb-1 font-light text-gray-500 leading-5"
                        htmlFor="name"
                    >
                        {capitalizeFirstLetter(t('settings_page.sre_tab.name'))}
                    </label>
                    <input
                        className="w-60	p-1.5 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded capitalize focus:outline-gray"
                        id="name"
                        type="text"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={!permissionForWrite}
                    />
                </div>

                {type === 'cloud_monitoring' && (
                    <>
                        <div className="mb-2">
                            <label
                                className="block mb-1 font-light text-gray-500 leading-5"
                                htmlFor="projectId"
                            >
                                Default Project ID
                            </label>

                            <div className="flex gap-x-4 items-stretch">
                                <input
                                    className="w-360 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                    id="projectId"
                                    type="text"
                                    placeholder="Enter default project ID"
                                    value={projectId}
                                    onChange={(e) =>
                                        setProjectId(e.target.value)
                                    }
                                    disabled={!permissionForWrite}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label
                                className="block mb-1 font-light text-gray-500 leading-5"
                                htmlFor="credentials"
                            >
                                {capitalizeFirstLetter(
                                    t('settings_page.sre_tab.json')
                                )}
                            </label>

                            <input
                                type="file"
                                id="credentials"
                                name="file"
                                accept=".json"
                                onChange={(e) => {
                                    void handleFile(e.target.files[0]);
                                }}
                                disabled={!permissionForWrite}
                                className="font-light text-gray-500 leading-5 mb-1"
                            ></input>

                            <textarea
                                className="w-full h-36 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                id="credentials"
                                type="text"
                                placeholder="Paste Credentals JSON"
                                value={googleCredentials}
                                onChange={(e) =>
                                    setGoogleCredentials(e.target.value)
                                }
                                disabled={true}
                            />
                        </div>
                    </>
                )}

                {type === 'cloud_watch' && (
                    <>
                        <div className="mb-2">
                            <label
                                className="block mb-1 font-light text-gray-500 leading-5"
                                htmlFor="accessKeyId"
                            >
                                Access Key ID
                            </label>

                            <div className="flex gap-x-4 items-stretch">
                                <input
                                    className="w-360 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                    id="accessKeyId"
                                    type="text"
                                    placeholder="Enter Access key ID"
                                    value={awsAccessKey}
                                    onChange={(e) =>
                                        setAwsAccessKey(e.target.value)
                                    }
                                    disabled={!permissionForWrite}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label
                                className="block mb-1 font-light text-gray-500 leading-5"
                                htmlFor="accessKeySecret"
                            >
                                Access Key Secret
                            </label>

                            <div className="flex gap-x-4 items-stretch">
                                {isSecretShown ? (
                                    <input
                                        className="w-360 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                        id="accessKeySecret"
                                        type="text"
                                        placeholder="Enter Access key Secret"
                                        value={awsAccessSecret}
                                        onChange={(e) =>
                                            setAwsAccessSecret(e.target.value)
                                        }
                                        disabled={!permissionForWrite}
                                    />
                                ) : (
                                    <input
                                        className="w-360 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                        id="accessKeySecret"
                                        type="password"
                                        placeholder="Enter Access key Secret"
                                        value={awsAccessSecret}
                                        onChange={(e) =>
                                            setAwsAccessSecret(e.target.value)
                                        }
                                        disabled={!permissionForWrite}
                                    />
                                )}
                                <div
                                    className="flex justify-center items-center cursor-pointer"
                                    onClick={() => handleToggleSecret()}
                                >
                                    {isSecretShown ? (
                                        <AiOutlineEyeInvisible
                                            size={20}
                                            color="#A4A5A6"
                                        />
                                    ) : (
                                        <AiOutlineEye
                                            size={20}
                                            color="#A4A5A6"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label
                                className="block mb-1 font-light text-gray-500 leading-5"
                                htmlFor="region"
                            >
                                Region
                            </label>

                            <div className="flex gap-x-4 items-stretch">
                                <input
                                    className="w-360 p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                                    id="region"
                                    type="text"
                                    placeholder="(e.x. eu-west-1)"
                                    value={awsRegion}
                                    onChange={(e) =>
                                        setAwsRegion(e.target.value)
                                    }
                                    disabled={!permissionForWrite}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>

            {permissionForWrite && (
                <div className={`flex justify-between items-center`}>
                    <Button
                        type="button"
                        variant="redText"
                        onClick={handleDeleteClick}
                    >
                        {capitalizeFirstLetter(
                            t('settings_page.sre_tab.remove')
                        )}
                    </Button>
                    {isLoading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : (
                        <div className="flex gap-x-2">
                            <Button
                                type="button"
                                variant="whiteHoverBlue"
                                onClick={() => testBackend()}
                            >
                                Test
                            </Button>
                            <SaveButton
                                saveFunc={
                                    sreBackendData.id
                                        ? handleCreateBackend
                                        : handleUpdateBackend
                                }
                                text={
                                    sreBackendData.name
                                        ? capitalizeFirstLetter(
                                              t('button.update')
                                          )
                                        : capitalizeFirstLetter(
                                              t('button.save')
                                          )
                                }
                                disabled={isUpdateDisabled}
                            />
                        </div>
                    )}
                </div>
            )}
            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title="Remove backend"
            >
                <DeleteContent
                    setModalState={setIsDeleteModalOpen}
                    handleAction={() => {
                        setIsLoading(true);
                        void handleDeleteBackend();
                    }}
                >
                    <p className="text-xl text-gray-500">
                        {capitalizeFirstLetter(
                            t('settings_page.sre_tab.remove_modal_message'),
                            { backend_name: sreBackendData.name }
                        )}
                    </p>
                </DeleteContent>
            </ModalWrapper>
            <ModalWrapper
                modalState={isUpdateModalOpen}
                setModalState={setIsUpdateModalOpen}
                title={capitalizeFirstLetter(t('settings_page.sre_tab.update'))}
            >
                <ModalBackendUpdateContent
                    backendName={sreBackendData.name}
                    sendRequest={() => {
                        setIsLoading(true);
                        void handleUpdateBackendConfirmation();
                    }}
                    setModalState={setIsUpdateModalOpen}
                />
            </ModalWrapper>
        </li>
    );
};

export default SREBackendItem;
