import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import OrganisationSelector from '../organisations-selector';
import { UserContext } from '../../context/UserContext';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { TimeRangeSelectorToggler, TimeRangeSelector } from '../time-period';
import userAvatar from '../../assets/user-avatar.png';
import useOnClickOutside from '../../helpers/closeOnOutsideClick';
import { BurgerMenuIcon } from '../../assets/svg';
import { NAVIGATION_PATHS } from '../../constants';
import useParamsQuery from '../../hooks/useParamsQuery';
import Button from '../shared/button';
import { capitalizeEveryWordFirstLetter } from '../../helpers/textFormatters';

const Header = ({ onToggleMenu, onTourGuideStart, startTourGuide }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const query = useParamsQuery();
    const { organisationsState } = useContext(OrganisationsContext);
    const { userState } = useContext(UserContext);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownMouseOn, setDropdownMouseOn] = useState(false);

    const [isTour, setIsTour] = useState(false);
    const [timeRangeSelectorStatus, setTimeRangeSelectorStatus] =
        useState(false);

    const ref = useRef();
    const buttonRef = useRef();
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const isDemoOrgOnly = () => {
        return (
            organisationsState?.data?.length === 1 &&
            organisationsState?.data[0]?.org_hash === demoOrgHash
        );
    };

    const selectedOrgData = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const selectedOrgHash = selectedOrgData?.org_hash
        ? selectedOrgData?.org_hash
        : null;

    useEffect(() => {
        query.get('tour') === 'true' && setIsTour(true);
    }, []);

    useEffect(() => {
        startTourGuide && setIsTour(true);
    }, [startTourGuide]);

    useEffect(() => {
        localStorage.setItem('isDemoOrgOnly', isDemoOrgOnly());
    }, [organisationsState]);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const logOutFunc = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('isDemo');
        localStorage.removeItem('isSignup');

        navigate(`/login`);
    };

    useOnClickOutside(ref, () => setTimeRangeSelectorStatus(false), [
        buttonRef,
    ]);

    function handleTourStart() {
        if (window.location.pathname !== NAVIGATION_PATHS.overview) {
            navigate(`${NAVIGATION_PATHS.overview}?tour=true`);
        }
        setIsTour(true);
        onTourGuideStart(true);
    }

    return (
        <header className="border-b border-solid border-gray-400 py-2.5 px-4 flex justify-between relative gap-4 items-senter	">
            <div className="flex gap-x-12">
                <Button
                    extraClasses="cursor-pointer"
                    onClick={() => onToggleMenu()}
                >
                    <div className=" flex justify-center items-center">
                        <BurgerMenuIcon />
                    </div>
                </Button>
                <div className="flex gap-x-2 items-center">
                    <OrganisationSelector />
                    {selectedOrgHash === demoOrgHash ? (
                        <Button
                            variant="blue"
                            extraClasses={
                                'h-full flex justify-center items-center font-normal'
                            }
                            onClick={() => handleTourStart()}
                        >
                            {t('header.start_tour')}
                        </Button>
                    ) : null}
                </div>
            </div>

            <div className="flex items-center">
                {selectedOrgHash === demoOrgHash && isDemoOrgOnly() ? (
                    <div className="relative mr-8  h-full cursor-pointer text-center flex items-center justify-center py-1.5 px-3 border border-solid border-theme-tertiary rounded  text-theme-tertiary hover:bg-theme-tertiary hover:text-white ">
                        <Link
                            className="text-center"
                            to={NAVIGATION_PATHS.createOrganisation}
                        >
                            {t('header.get_started')}
                        </Link>
                        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-theme-tertiary opacity-75" />
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-theme-tertiary" />
                        </span>
                    </div>
                ) : null}

                <TimeRangeSelectorToggler
                    ref={buttonRef}
                    timeRangeSelectorStatus={timeRangeSelectorStatus}
                    setTimeRangeSelectorStatus={setTimeRangeSelectorStatus}
                />
                <TimeRangeSelector
                    ref={ref}
                    timeRangeSelectorStatus={timeRangeSelectorStatus}
                    isTour={isTour}
                    setIsTour={setIsTour}
                />

                <div className="relative">
                    <Button
                        extraClasses="m-0 p-0 border-0 rounded overflow-hidden outline-none cursor-pointer focus:outline-gray-2"
                        onClick={() => toggle()}
                        onBlur={() =>
                            !dropdownMouseOn && setDropdownOpen(false)
                        }
                    >
                        {userState.loading || userState.error ? (
                            <img
                                src={userAvatar}
                                alt="User Avatar"
                                className="w-7 h-7"
                            />
                        ) : (
                            <img
                                src={
                                    userState?.data?.photo &&
                                    userState.data.photo !== '' &&
                                    userState.data.photo !==
                                        'data:image/jpeg;base64,'
                                        ? userState.data.photo
                                        : userAvatar
                                }
                                alt="User Avatar"
                                className="w-7 h-7"
                            />
                        )}
                    </Button>
                    {dropdownOpen && (
                        <div
                            className="absolute right-0 bg-white rounded shadow-lg p-0 border border-solid border-gray-300 divide-y divide-solid divide-gray-300 z-10"
                            onMouseEnter={() => setDropdownMouseOn(true)}
                            onMouseLeave={() => setDropdownMouseOn(false)}
                            onClick={() => toggle()}
                        >
                            <div className=" text-center cursor-pointer hover:text-theme-button hover:bg-gray-200">
                                <Link
                                    className="block w-full h-full px-8 py-3 leading-6 no-underline hover:text-theme-button whitespace-nowrap	"
                                    to="/user-settings"
                                >
                                    {t('header.user_settings')}
                                </Link>
                            </div>
                            <div className="flex justify-center items-center px-8 py-3 ">
                                <Button
                                    variant="authGray"
                                    extraClasses="px-4 py-2 "
                                    onClick={logOutFunc}
                                >
                                    {capitalizeEveryWordFirstLetter(
                                        t('authorization.logout')
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
