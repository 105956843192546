import React, { useState } from 'react';
import { Arrow } from '../../../../assets/svg';
import Button from '../../button';

const columnExample = {
    columnId: 'string', // used as a key, for react & for sorting
    columnName: 'string', //is desplayed in the column header Cell
    columnSize: 1, // how much grod-cols will the collum occupy
};

function TableHeader({
    columns,
    handleSort,
    gridColsAmount,
    sortColumnId,
    sortDirection,
    lowerColumn,
}) {
    return (
        <div
            className={`generic-table__header grid grid-cols-${gridColsAmount}`}
        >
            {columns?.map((headerItem) => {
                return (
                    <TableHeaderCell
                        headerItem={headerItem}
                        key={headerItem?.columnId}
                        handleSort={handleSort}
                        sortColumnId={sortColumnId}
                        defultSortDitrection={sortDirection}
                        lowerColumn={lowerColumn}
                    />
                );
            })}
        </div>
    );
}

function TableHeaderCell({
    headerItem,
    handleSort,
    sortColumnId,
    defultSortDitrection,
    lowerColumn,
}) {
    const [sortDirection, setSortDirection] = useState(
        defultSortDitrection === 'start' &&
            lowerColumn(headerItem.columnId) === sortColumnId
    );

    const changeSort = () => {
        setSortDirection(!sortDirection);
        handleSort(headerItem.columnId, sortDirection ? 'start' : 'end');
    };

    return (
        <div className={` col-span-${headerItem.columnSize} `}>
            <Button
                onClick={() => headerItem.canSort && changeSort()}
                extraClasses={`flex items-center text-center  ${
                    headerItem.canSort ? 'can-sort' : ''
                } cursor-text `}
            >
                <p
                    className={`text-gray-700  ${
                        headerItem.columnName === 'date'
                            ? 'text-15 px-2'
                            : 'px-4'
                    }`}
                >
                    {headerItem.columnName}
                </p>
                {headerItem.canSort ? (
                    <div className="cursor-pointer ">
                        <Arrow
                            width={'10'}
                            height={'10'}
                            fill="#323236"
                            transform={
                                sortDirection ? 'scale(1, 1)' : 'scale(1, -1)'
                            }
                        />
                    </div>
                ) : null}
            </Button>
        </div>
    );
}

export default TableHeader;
