import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import {
    Arrow,
    GoogleCloudPlatformIcon,
    AWSCloudWatchIcon,
    PrometheusIcon,
    DatadogIcon,
    DynatraceIcon,
    ElasticsearchIcon,
} from '../../assets/svg';
import { getAdminPermission } from '../../helpers/getPermission';
import Button from '../shared/button';

const AddBackendItem = ({ addBackend }) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const [isServicesListOpen, setIsServicesListOpen] = useState(false);

    const toggleIsServicesListOpen = () =>
        setIsServicesListOpen(!isServicesListOpen);

    const handleAddNewBackend = (service) => {
        toggleIsServicesListOpen();
        addBackend(service);
    };

    const isAdmin = getAdminPermission(organisationsState?.data);

    return (
        <li className="w-480 h-450 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
            {isAdmin ? (
                <>
                    <Button
                        variant="white"
                        extraClasses=" w-56"
                        onClick={() => toggleIsServicesListOpen()}
                    >
                        <span className="mr-2">
                            {t('settings_page.sre_tab.add')}
                        </span>
                        <Arrow
                            width={'12'}
                            height={'8'}
                            fill="#7F8992"
                            transform={
                                isServicesListOpen
                                    ? 'scale(1, 1)'
                                    : 'scale(1, -1)'
                            }
                        />
                    </Button>
                    <div className="relative w-56">
                        {isServicesListOpen && (
                            <ul
                                className="w-56 absolute top-0 left-0 bg-white rounded shadow-default text-gray-500"
                                data-testid="backend-options-dropdown"
                            >
                                <li
                                    className="p-2 flex filter grayscale cursor-pointer"
                                    onClick={() =>
                                        handleAddNewBackend('cloud_monitoring')
                                    }
                                >
                                    <GoogleCloudPlatformIcon />
                                    <span className="ml-1">
                                        Google Cloud Monitoring
                                    </span>
                                </li>
                                <li
                                    className="p-2 flex filter grayscale cursor-pointer"
                                    onClick={() =>
                                        handleAddNewBackend('cloud_watch')
                                    }
                                >
                                    <AWSCloudWatchIcon />
                                    <span className="ml-1">
                                        AWS Cloud Watch
                                    </span>
                                </li>
                                <li
                                    className="p-2 flex filter grayscale"
                                    // onClick={() =>
                                    //     handleAddNewBackend('prometheus')
                                    // }
                                >
                                    <PrometheusIcon />
                                    <span className="ml-1">
                                        Prometheus{' '}
                                        <span className="text-xs">
                                            (coming soon)
                                        </span>
                                    </span>
                                </li>
                                <li
                                    className="p-2 flex filter grayscale"
                                    // onClick={() =>
                                    //     handleAddNewBackend('datadog')
                                    // }
                                >
                                    <DatadogIcon />
                                    <span className="ml-1 ">
                                        Datadog{' '}
                                        <span className="text-xs">
                                            (coming soon)
                                        </span>
                                    </span>
                                </li>
                                <li
                                    className="p-2 flex filter grayscale"
                                    onClick={() =>
                                        handleAddNewBackend('cloud_watch')
                                    }
                                >
                                    <DynatraceIcon />
                                    <span className="ml-1 ">
                                        Dynatrace{' '}
                                        <span className="text-xs">
                                            (coming soon)
                                        </span>
                                    </span>
                                </li>
                                <li
                                    className="p-2 flex filter grayscale"
                                    onClick={() =>
                                        handleAddNewBackend('cloud_watch')
                                    }
                                >
                                    <ElasticsearchIcon />
                                    <span className="ml-1">
                                        Elasticsearch{' '}
                                        <span className="text-xs">
                                            (coming soon)
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        )}
                    </div>
                </>
            ) : (
                <p>
                    You don`t have permission to add a new backend. Please
                    contact your Organisation`s Admin
                </p>
            )}
        </li>
    );
};

export default AddBackendItem;
