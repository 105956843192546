import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import {
    ProjectsProvider,
    PrivateProjectsProvider,
} from '../../context/ProjectsContext';
import TabsNav from '../../components/tabs-nav';
import ErrorBoundary from '../../components/error-boundary';
import RegisterTab from './register-tab';
import ReportTab from './report-tab';
import { useTranslation } from 'react-i18next';

export default function HoursPage() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('register');
    let navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        window.location.pathname === '/hours'
            ? navigate(`${pathname}/${activeTab}`)
            : setActiveTab(window.location.pathname.replace('/hours/', ''));
    }, []);

    useEffect(() => {
        window.location.pathname === '/hours'
            ? navigate(`${pathname}/${activeTab}`)
            : setActiveTab(window.location.pathname.replace('/hours/', ''));
    }, [window.location.pathname]);

    const tabNavsArray = [
        { id: 'register', displayedName: t('hours_page.register') },
        { id: 'report', displayedName: t('hours_page.report') },
    ];

    return (
        <ProjectsProvider>
            <PrivateProjectsProvider>
                <div className="w-full h-screen">
                    <div className="container mx-auto ">
                        <TabsNav
                            active={activeTab}
                            setActiveTab={setActiveTab}
                            tabNavsArray={tabNavsArray}
                        />
                        <div className="">
                            <Routes>
                                <Route
                                    path={`/register`}
                                    element={
                                        <HoursTab activeTab={'register'} />
                                    }
                                />
                                <Route
                                    path={`/report`}
                                    element={<HoursTab activeTab={'report'} />}
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
            </PrivateProjectsProvider>
        </ProjectsProvider>
    );
}

function HoursTab({ activeTab }) {
    switch (activeTab) {
        case 'report':
            return (
                <ErrorBoundary>
                    <ReportTab />
                </ErrorBoundary>
            );
        default:
            return (
                <ErrorBoundary>
                    <RegisterTab />
                </ErrorBoundary>
            );
    }
}
