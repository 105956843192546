import React, { useCallback, useContext, useRef, useState } from 'react';
import {
    createNote,
    deleteNote,
    getNotes,
    updateNote,
} from '../../../api/settings-slo/slo';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import SreNote from './sre-note';
import useOnClickOutside from '../../../helpers/closeOnOutsideClick';
import Loader from '../../../components/loader';
import { UserContext } from '../../../context/UserContext';
import showNotification from '../../../helpers/showNotification';
import moment from 'moment';
import Button from '../../../components/shared/button';
import { useTranslation } from 'react-i18next';

function SreNotes({ serviceName }) {
    const { t } = useTranslation();
    const [reloadCounter, setReloadCounter] = useState(0);
    const { userState } = useContext(UserContext);
    const ref = useRef();
    const notesRequest = useCallback(
        () => getNotes(serviceName),
        [serviceName, reloadCounter]
    );

    const [notesState] = useFetchDataAndSetState(notesRequest, [
        serviceName,
        reloadCounter,
    ]);

    const [isAddingNote, setIsAddingNote] = useState(false);
    const [newNote, setNewNote] = useState('');

    useOnClickOutside(ref, () => setIsAddingNote(false), []);

    async function handleAddingNote() {
        if (!newNote?.length) {
            showNotification(`the note is empty`);
            return;
        }

        let response = { data: null, status: 200 };

        const note = {
            note: newNote,
            creator_info: userState?.data?.user_name,
        };

        try {
            response = await createNote(serviceName, note);

            if (response?.status === 200) {
                setReloadCounter((prevState) => prevState + 1);
            }
        } catch (err) {
            showNotification(
                err.response?.data ||
                    `Note has not been added, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    async function handleEditingNote(updatedNote, noteId) {
        let response = { data: null, status: 200 };

        const note = {
            note: updatedNote,
            creator_info: userState?.data?.user_name,
        };
        try {
            response = await updateNote(serviceName, noteId, note);
            if (response?.status === 200) {
                setReloadCounter((prevState) => prevState + 1);
            }
        } catch (err) {
            showNotification(
                err.response?.data ||
                    `Note has not been updated, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    async function handlDeleteNote(noteId) {
        let response = { data: null, status: 200 };
        try {
            response = await deleteNote(serviceName, noteId);
            if (response?.status === 200) {
                setReloadCounter((prevState) => prevState + 1);
            }
        } catch (err) {
            showNotification(
                err.response?.data ||
                    `Note has not been updated, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    if (notesState?.loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Loader />
            </div>
        );
    }

    const reversedNotes = notesState?.data?.length
        ? [...notesState.data].reverse()
        : null;

    return (
        <div className="text-gray-500 mt-4">
            <p className="mb-2">Notes:</p>
            {reversedNotes?.length ? (
                <div className="flex flex-col gap-2">
                    {reversedNotes.map((note, i) => (
                        <SreNote
                            i={i}
                            key={note.id}
                            note={note}
                            notesLength={notesState?.data?.length}
                            onNoteEdit={handleEditingNote}
                            onNoteDelete={handlDeleteNote}
                        />
                    ))}
                </div>
            ) : null}
            {isAddingNote ? (
                <div
                    type="button"
                    className="w-full p-2 rounded border border-solid border-gray-500 flex gap-x-4"
                    ref={ref}
                >
                    <input
                        id={moment().unix()}
                        value={newNote}
                        type="text"
                        placeholder="Your note..."
                        className="w-full outline-none"
                        autoFocus={true}
                        onBlur={({ target }) => isAddingNote && target.focus()}
                        onChange={(event) => {
                            setNewNote(event.target.value);
                        }}
                        onKeyDown={(event) =>
                            event.key.toLocaleLowerCase() === 'enter' &&
                            handleAddingNote()
                        }
                    />
                    <Button
                        type="button"
                        variant="grayText"
                        onClick={() => handleAddingNote()}
                    >
                        Save
                    </Button>
                </div>
            ) : (
                <Button
                    type="button"
                    extraClasses="w-full mt-2 p-2 rounded border border-solid border-gray-500"
                    onClick={() => setIsAddingNote(true)}
                >
                    {notesState?.data?.length
                        ? t('sre.notes_add')
                        : t('sre.no_notes')}
                </Button>
            )}
        </div>
    );
}

export default SreNotes;
