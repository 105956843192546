import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaCheck } from 'react-icons/fa';
import FormField from '../../pages/sre-page/components/sre-form-field';
import FormikSelect from '../form/parts/FormikSelect';
import { createOrganisationByDemoUser } from '../../api/organisation/OrganisationAPI';
import showNotification from '../../helpers/showNotification';
import Loader from '../../components/loader';
import eula from '../../assets/eula';
import Button from '../shared/button';

// const initialValuesExample = {
//     contactName: '',
//     contactLastName: '',
//     contactEmail: '',
//     contactJobTitle: '',
//     contactGoal: '',
//     companyName: '',
//     monitoringSystem: '',
//     gitSystem: '',
//     pmSystem: '',
//     communicationSystem: '',
// };

const CreateOrganisationUserForm = ({
    handleChangeModalState = () => {},
    onGoToAuth = () => {},
    setShowCreateSuccessScreen = () => {},
    userState = null,
    isInTrial = false,
    organisationsState = null,
    organisationsDispatch = null,
    modalState = null,
    billingPlan = 'trial',
    stepToStart = 0,
    initialValues,
}) => {
    const navigate = useNavigate();
    const [isCreating, setIsCreating] = useState(false);

    const [contactGoalOptions, setContactGoalOption] = useState([
        {
            value: 'Are we developing too many/little features?',
            checked: false,
        },
        {
            value: 'Are our systems stable and fast enough for our customer?',
            checked: false,
        },
        {
            value: 'Are we stressing our teams too much?',
            checked: false,
        },
        {
            value: 'Do we have hidden horrors (leaks) in our code?',
            checked: false,
        },
        {
            value: 'How is the mood in the teams?',
            checked: false,
        },
    ]);

    const [isOther, setIsOther] = useState({
        contactJobTitle: false,
        monitoringSystem: false,
        gitSystem: false,
        pmSystem: false,
        communicationSystem: false,
    });

    const contactJobTitleOptions = [
        { label: 'Founder / CEO', value: 'Founder / CEO' },
        {
            label: 'CTO / VP of Engineering / Development Manager',
            value: 'CTO / VP of Engineering / Development Manager',
        },
        {
            label: 'Agile Coach / Scrum Master',
            value: 'Agile Coach / Scrum Master',
        },
        {
            label: 'Lead Developer / Team Lead / Architect',
            value: 'Lead Developer / Team Lead / Architect',
        },
        {
            label: 'Developer / DevOps / SRE / Engineer / Analyst / Expert',
            value: 'Developer / DevOps / SRE / Engineer / Analyst / Expert',
        },
        { label: 'Other', value: 'Other' },
    ];

    const monitoringSystemOptions = [
        { label: 'Google Cloud Platform', value: 'Google Cloud Platform' },
        { label: 'AWS Cloud Watch', value: 'AWS Cloud Watch' },
        { label: 'Prometheus', value: 'Prometheus' },
        { label: 'DATADOG', value: 'DATADOG' },
        { label: 'elasticsearch', value: 'elasticsearch' },
        { label: 'I don`t know', value: 'I don`t know' },
        { label: 'Other', value: 'Other' },
    ];

    const gitSystemOptions = [
        { label: 'GitHub', value: 'GitHub' },
        { label: 'GitLab', value: 'GitLab' },
        { label: 'BitBucket', value: 'BitBucket' },
        { label: 'DATADOG', value: 'DATADOG' },
        { label: 'I don`t know', value: 'I don`t know' },
        { label: 'Other', value: 'Other' },
    ];
    const pmSystemOptions = [
        { label: 'Jira Software', value: 'Jira Software' },
        { label: 'I don`t know', value: 'I don`t know' },
        { label: 'Other', value: 'Other' },
    ];
    const communicationSystemOptions = [
        { label: 'Slack', value: 'Slack' },
        { label: 'I don`t know', value: 'I don`t know' },
        { label: 'Other', value: 'Other' },
    ];

    const selectFieldOptionsObject = {
        contactJobTitleOptions,
        monitoringSystemOptions,
        gitSystemOptions,
        pmSystemOptions,
        communicationSystemOptions,
    };

    useEffect(() => {
        // handling initial goals array
        const updatedContactGoalOptions = contactGoalOptions.map((item) => {
            const itemInValues =
                initialValues?.contactGoal.length &&
                initialValues?.contactGoal?.find(
                    (value) => value === item.value
                );

            if (itemInValues) {
                return { value: item.value, checked: true };
            } else {
                return item;
            }
        });
        setContactGoalOption(updatedContactGoalOptions);

        // handling initial 'Others'
        const isCSStandart = communicationSystemOptions.find(
            (option) => option.value === initialValues?.communicationSystem
        );
        const isMSStandart = monitoringSystemOptions.find(
            (option) => option.value === initialValues?.monitoringSystem
        );
        const isPSStandart = pmSystemOptions.find(
            (option) => option.value === initialValues?.pmSystem
        );
        const isGSStandart = gitSystemOptions.find(
            (option) => option.value === initialValues?.gitSystem
        );
        const isJobTitletandart = contactJobTitleOptions.find(
            (option) => option.value === initialValues?.contactJobTitle
        );

        if (
            !isCSStandart ||
            !isMSStandart ||
            !isPSStandart ||
            !isGSStandart ||
            !isJobTitletandart
        ) {
            setIsOther({
                contactJobTitle:
                    isJobTitletandart || initialValues?.contactJobTitle === ''
                        ? false
                        : true,
                monitoringSystem:
                    isMSStandart || initialValues?.monitoringSystem === ''
                        ? false
                        : true,
                gitSystem:
                    isGSStandart || initialValues?.gitSystem === ''
                        ? false
                        : true,
                pmSystem:
                    isPSStandart || initialValues?.pmSystem === ''
                        ? false
                        : true,
                communicationSystem:
                    isCSStandart || initialValues?.communicationSystem === ''
                        ? false
                        : true,
            });
        } else {
            setIsOther({
                contactJobTitle: false,
                monitoringSystem: false,
                gitSystem: false,
                pmSystem: false,
                communicationSystem: false,
            });
        }
    }, [initialValues]);

    function handleContactGoalClick(value) {
        let updatedOptions = contactGoalOptions.map((option) => {
            if (option.value === value) {
                return {
                    value,
                    checked: !option.checked,
                };
            }
            return option;
        });
        setContactGoalOption(updatedOptions);
    }

    function saveOrgData(data) {
        onGoToAuth(data);
    }

    async function handleCreateOrganisation(data) {
        const {
            contactEmail,
            contactGoal,
            contactName,
            contactLastName,
            contactJobTitle,
            companyName,
            monitoringSystem,
            communicationSystem,
            gitSystem,
            pmSystem,
        } = data;
        const newOrgData = {
            org_name: companyName,
            contact_first_name: contactName,
            contact_last_name: contactLastName,
            contact_email: contactEmail,
            marketing_data: {
                contact_goal: contactGoal,
                contact_job_title: contactJobTitle,
                monitoring_system: monitoringSystem,
                communication_system: communicationSystem,
                git_system: gitSystem,
                pm_system: pmSystem,
            },
            subscription_level: billingPlan,
        };

        try {
            setIsCreating(true);
            const res = await createOrganisationByDemoUser(newOrgData);
            if (res.status === 200) {
                setIsCreating(false);
                setShowCreateSuccessScreen(true);
                !userState?.data.superadmin &&
                    localStorage.setItem('baseOrgHash', res.data);
                localStorage.setItem('orgHash', res.data);
                const newOrgForOrganisations = {
                    active: true,
                    logo_url: 'Undefined',
                    number_of_users: 1,
                    org_hash: res.data,
                    org_name: companyName,
                    permissions: 'admin',
                    retention_period: 2592000,
                    status: 'active',
                    subscription_level: 'free',
                };

                const orgList = organisationsState?.data.map((org) => {
                    return { ...org, active: false };
                });

                if (organisationsDispatch) {
                    organisationsDispatch({
                        type: 'SET_DATA',
                        payload: [...orgList, newOrgForOrganisations],
                    });
                    setTimeout(() => {
                        navigate('/');
                        setShowCreateSuccessScreen(false);
                    }, 3000);
                }
            }
        } catch (error) {
            setIsCreating(false);
            if (error?.response?.status === 409) {
                showNotification(
                    'Organisation name is not unique. Please change it'
                );
            } else {
                showNotification(error.response?.data || error.message);
            }
        }
    }

    return (
        <div
            className={` h-full flex flex-col ${
                isInTrial && 'w-600 rounded-xl shadow-md m-4'
            }`}
        >
            <div className="px-1 pt-5 flex-grow h-full">
                <FormikStepper
                    initialValues={initialValues}
                    onCancel={handleChangeModalState}
                    isOther={isOther}
                    setIsOther={setIsOther}
                    onCreateOrganisation={
                        isInTrial ? saveOrgData : handleCreateOrganisation
                    }
                    modalState={modalState}
                    selectFieldOptionsObject={selectFieldOptionsObject}
                    isCreating={isCreating}
                    userState={userState}
                    isInTrial={isInTrial}
                    stepToStart={stepToStart}
                >
                    {/* STEP 1 */}
                    <FormikStep
                        validationSchema={Yup.object({
                            contactName: Yup.string()
                                .max(150, 'Maximum 150 characters')
                                .required('Your name is required'),
                            contactLastName: Yup.string()
                                .max(250, 'Maximum 250 characters')
                                .required('Your last name is required'),
                        })}
                        className="flex flex-col gap-4"
                        name="account"
                    >
                        <div className=" flex gap-x-4 mb-4  ">
                            <div>
                                {userState?.data?.first_name?.length ? (
                                    <FormField
                                        name={'Your first name'}
                                        attributeName={'contactName'}
                                        fieldType={'text'}
                                        placeholderText={'Richard Hendricks'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                        value={userState?.data.first_name}
                                        disabled={true}
                                    />
                                ) : (
                                    <FormField
                                        name={'Your first name'}
                                        attributeName={'contactName'}
                                        fieldType={'text'}
                                        placeholderText={'Richard'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                )}
                                <ErrorMessage name="contactName">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                            <div>
                                {userState?.data?.last_name?.length ? (
                                    <FormField
                                        name={'Your last name'}
                                        attributeName={'contactLastName'}
                                        fieldType={'text'}
                                        placeholderText={'Hendricks'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                        value={userState?.data.last_name}
                                        disabled={true}
                                    />
                                ) : (
                                    <FormField
                                        name={'Your last name'}
                                        attributeName={'contactLastName'}
                                        fieldType={'text'}
                                        placeholderText={'Hendricks'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                )}
                                <ErrorMessage name="contactLastName">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                        </div>
                        <div>
                            <FormField
                                name={'Your email address'}
                                attributeName={'contactEmail'}
                                fieldType={'email'}
                                placeholderText={'richard@piedpiper.com'}
                                labelClassName="font-light leading-5"
                                ignoreErrors={true}
                                value={userState?.data.email}
                                disabled={userState?.data.email ? true : false}
                            />
                            <ErrorMessage name="contactEmail">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                    </FormikStep>
                    {/* Step 2 */}
                    <FormikStep
                        className="h-12"
                        name="about yourself"
                        validationSchema={Yup.object({
                            contactJobTitle: Yup.string()
                                .required('Required')
                                .max(150, 'Maximum 150 characters'),
                            contactGoal: Yup.array()
                                .min(1)
                                .required('Minimum 1 is required'),
                        })}
                    >
                        <div className="mb-4">
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What Job Title do you have in the organisation?
                            </p>
                            <Field
                                name="contactJobTitle"
                                component={FormikSelect}
                                options={contactJobTitleOptions}
                            />
                            {isOther.contactJobTitle && (
                                <div className="mt-2">
                                    <FormField
                                        attributeName={'contactJobTitle'}
                                        fieldType={'text'}
                                        placeholderText={'Ented your option...'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                </div>
                            )}
                            <ErrorMessage name="contactJobTitle">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                        <div
                            role="group"
                            aria-labelledby="checkbox-group"
                            className="flex flex-col"
                        >
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What insights do you hope Agile Analytics will
                                give you?
                            </p>
                            {contactGoalOptions.map((option, index) => {
                                return (
                                    <div key={index}>
                                        <label className="block w-full py-2 px-2 mb-2 flex items-center rounded border border-solid border-gray-300">
                                            <p className="flex-grow text-gray-700">
                                                {option.value}
                                            </p>

                                            <div className="relative w-5 h-5 rounded-full overflow-hidden ">
                                                <Field
                                                    type="checkbox"
                                                    name="contactGoal"
                                                    value={option.value}
                                                    className="w-full h-full hidden"
                                                    onClick={(e) => {
                                                        handleContactGoalClick(
                                                            e.target.value
                                                        );
                                                    }}
                                                ></Field>
                                                <div
                                                    className={`absolute top-0 right-0 w-5 h-5 rounded-full flex justify-center items-center overflow-hidden border border-solid ${
                                                        contactGoalOptions.find(
                                                            (item) =>
                                                                item.value ===
                                                                option.value
                                                        )?.checked
                                                            ? 'bg-theme-tertiary border-theme-tertiary'
                                                            : 'border-gray-300'
                                                    }`}
                                                >
                                                    {' '}
                                                    {contactGoalOptions.find(
                                                        (item) =>
                                                            item.value ===
                                                            option.value
                                                    )?.checked && (
                                                        <FaCheck
                                                            size={12}
                                                            color={'#fff'}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                );
                            })}
                            <ErrorMessage name="contactGoal">
                                {(error) => (
                                    <div className="text-red-500 mt-1 text-xs font-light leading-5">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                    </FormikStep>
                    {/* Step 3 */}
                    <FormikStep
                        className="flex flex-col gap-y-2 mb-4 "
                        name="company"
                        validationSchema={Yup.object({
                            companyName: Yup.string()
                                .min(3, 'Must be at least 3 characters long')
                                .max(100, 'Maximum 100 characters')
                                .required('Required')
                                .matches(
                                    /^[A-Za-z0-9 ю_-]*[A-Za-z0-9][A-Za-z0-9 ю_-]*$/,
                                    'Only alphabets, numbers or _, ., - are allowed'
                                ),
                            monitoringSystem: Yup.string().required('Required'),
                            gitSystem: Yup.string().required('Required'),
                            pmSystem: Yup.string().required('Required'),
                            communicationSystem:
                                Yup.string().required('Required'),
                            eula: Yup.boolean().oneOf(
                                [true],
                                'Please accept End use license agreement'
                            ),
                        })}
                    >
                        <div className="">
                            <FormField
                                name={'Your company name'}
                                attributeName={'companyName'}
                                fieldType={'text'}
                                placeholderText={'Pied Piper'}
                                labelClassName="font-light leading-5"
                                ignoreErrors={true}
                            />
                            <ErrorMessage name="companyName">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                        <div>
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What`s your Monitoring & telemetry system?
                            </p>
                            <Field
                                name="monitoringSystem"
                                component={FormikSelect}
                                options={monitoringSystemOptions}
                            />
                            {isOther.monitoringSystem && (
                                <div className="mt-2">
                                    <FormField
                                        attributeName={'monitoringSystem'}
                                        fieldType={'text'}
                                        placeholderText={'Ented your option...'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                </div>
                            )}
                            <ErrorMessage name="monitoringSystem">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                        <div>
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What`s your Git provider system?
                            </p>
                            <Field
                                name="gitSystem"
                                component={FormikSelect}
                                options={gitSystemOptions}
                            />
                            {isOther.gitSystem && (
                                <div className="mt-2">
                                    <FormField
                                        attributeName={'gitSystem'}
                                        fieldType={'text'}
                                        placeholderText={'Ented your option...'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                </div>
                            )}
                            <ErrorMessage name="gitSystem">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                        <div>
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What`s your Project management system?
                            </p>
                            <Field
                                name="pmSystem"
                                component={FormikSelect}
                                options={pmSystemOptions}
                            />
                            {isOther.pmSystem && (
                                <div className="mt-2">
                                    <FormField
                                        attributeName={'pmSystem'}
                                        fieldType={'text'}
                                        placeholderText={'Ented your option...'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                </div>
                            )}
                            <ErrorMessage name="pmSystem">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>
                        <div>
                            <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                What`s your Collaborative communication system?
                            </p>
                            <Field
                                name="communicationSystem"
                                component={FormikSelect}
                                options={communicationSystemOptions}
                            />
                            {isOther.communicationSystem && (
                                <div className="mt-2">
                                    <FormField
                                        attributeName={'communicationSystem'}
                                        fieldType={'text'}
                                        placeholderText={'Ented your option...'}
                                        labelClassName="font-light leading-5"
                                        ignoreErrors={true}
                                    />
                                </div>
                            )}
                            <ErrorMessage name="communicationSystem">
                                {(error) => (
                                    <div className="text-red-500 mt-1  text-xs">
                                        {error}
                                    </div>
                                )}
                            </ErrorMessage>
                        </div>

                        <div>
                            <div className="w-full h-40 mb-2 overflow-y-scroll p-2 relative rounded border border-solid border-gray-300 overflow-x-hidden text-gray-700 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                                {eula()}
                            </div>
                            <div className="text-center">
                                <label className="text-gray-700 text-sm text-center mx-auto ">
                                    <Field type="checkbox" name="eula" /> I
                                    accept End use license agreement
                                </label>
                                <ErrorMessage name="eula">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                        </div>
                    </FormikStep>
                </FormikStepper>
            </div>
        </div>
    );
};

export function FormikStep({ children, className, name }) {
    return (
        <div className={className} name={name}>
            {children}
        </div>
    );
}

export function FormikStepper({ children, ...props }) {
    const {
        onCancel,
        isOther,
        setIsOther,
        onCreateOrganisation,
        modalState,
        selectFieldOptionsObject,
        isCreating,
        userState,
        isInTrial,
        stepToStart,
        initialValues,
    } = props;

    const childrenArray = React.Children.toArray(children);
    const [step, setStep] = useState(0);
    const currentChild = childrenArray[step];

    useEffect(() => {
        +stepToStart && setStep(stepToStart);
    }, [stepToStart]);

    useEffect(() => {
        !modalState && !isInTrial && step !== 0 && setStep(0);
    }, [modalState, isInTrial, step]);

    const statusStyles = {
        active: 'text-theme-tertiary border border-solid border-theme-tertiary',
        disabled: 'bg-gray-300 text-gray-450',
        filled: 'bg-theme-tertiary text-white',
    };

    function isLastStep() {
        return step === childrenArray.length - 1;
    }

    function handleSubmit(values, helpers) {
        isLastStep() ? handleSendData(values, helpers) : setStep(step + 1);
    }

    async function handleSendData(values, helpers) {
        localStorage.setItem('orgDataDraft', JSON.stringify(values));
        onCreateOrganisation(values);
    }

    return (
        <Formik
            {...props}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            validationSchema={currentChild.props?.validationSchema}
        >
            {({ values, setFieldValue, setValues }) => {
                useEffect(() => {
                    userState?.data?.first_name?.length &&
                        setFieldValue(
                            'contactName',
                            userState?.data.first_name
                        );
                    userState?.data?.last_name?.length &&
                        setFieldValue(
                            'contactLastName',
                            userState?.data.last_name
                        );
                    userState?.data?.email &&
                        setFieldValue('contactEmail', userState?.data.email);
                }, []);

                useEffect(() => {
                    setValues(initialValues);
                }, [initialValues]);

                useEffect(() => {
                    const otherFieldsArray = Object.entries(values)?.filter(
                        (value) => value[1] === 'Other'
                    );

                    if (!otherFieldsArray.length) {
                        const oldFieldArray = Object.entries(isOther).filter(
                            (item) => item[1] === true
                        );
                        let updatedFields = oldFieldArray.reduce(
                            (acc, item) => {
                                const isBasicOption = selectFieldOptionsObject[
                                    item[0] + 'Options'
                                ].find(
                                    (option) => option.value === values[item[0]]
                                );
                                if (!isBasicOption) {
                                    return { ...acc, item };
                                }
                                return { ...acc, [item[0]]: false };
                            },
                            {}
                        );

                        setIsOther({ ...isOther, ...updatedFields });
                    }

                    let otherFieldNamesArray = [];
                    if (otherFieldsArray.length) {
                        otherFieldsArray.map((name) => {
                            otherFieldNamesArray.push(name[0]);
                        });

                        let updatedFields = otherFieldNamesArray.reduce(
                            (acc, item) => {
                                return { ...acc, [item]: true };
                            },
                            {}
                        );

                        setIsOther({ ...isOther, ...updatedFields });
                    }
                }, [values]);

                return (
                    <Form autoComplete="off" className="h-full flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                {childrenArray.map((child, index) => {
                                    return (
                                        <p
                                            className="text-theme-tertiary text-xs"
                                            key={index}
                                        >
                                            {child.props?.name?.toUpperCase()}
                                        </p>
                                    );
                                })}
                            </div>
                            <div className="flex justify-between items-center mt-1 mb-12">
                                {childrenArray.map((child, index) => {
                                    let status = '';
                                    if (index === step) {
                                        status = 'active';
                                    } else if (index < step) {
                                        status = 'filled';
                                    } else {
                                        status = 'disabled';
                                    }
                                    if (index === childrenArray.length - 1) {
                                        return (
                                            <div
                                                className="flex flex-shrink items-center"
                                                key={index}
                                            >
                                                <div
                                                    className={`w-6 h-6 flex justify-center items-center mx-4 rounded-full ${statusStyles[status]}`}
                                                >
                                                    <span className="text-sm">
                                                        {' '}
                                                        {index + 1}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className="flex w-full items-center"
                                                key={index}
                                            >
                                                <div
                                                    className={`w-6 h-6 flex justify-center items-center mx-4 rounded-full ${statusStyles[status]}`}
                                                >
                                                    <span className="text-sm">
                                                        {' '}
                                                        {index + 1}
                                                    </span>
                                                </div>
                                                <div
                                                    className={`flex-grow h-px ${
                                                        status === 'filled'
                                                            ? 'bg-theme-tertiary'
                                                            : 'bg-gray-300'
                                                    } `}
                                                ></div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        <div className="flex-grow">{currentChild}</div>

                        <div className="flex justify-end pb-7 gap-4">
                            <Button
                                variant="gray"
                                onClick={() =>
                                    step === 0 ? onCancel() : setStep(step - 1)
                                }
                            >
                                {step === 0 ? 'Cancel' : 'Back'}
                            </Button>
                            <Button
                                type="submit"
                                extraClasses={`px-6 py-2 text-lg leading-6	font-normal font-sans min-w-[110px]`}
                            >
                                {isLastStep() ? (
                                    isCreating ? (
                                        <Loader size={24} />
                                    ) : (
                                        'Create'
                                    )
                                ) : (
                                    'Next'
                                )}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default CreateOrganisationUserForm;
