import React, { useContext, useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { CrossInCircle } from '../../../assets/svg';
import Loader from '../../loader';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import {
    createEmoji,
    getEmoji,
    updateEmoji,
} from '../../../api/settings/EmojiAPI';
import { getAdminPermission } from '../../../helpers/getPermission';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import Button from '../../shared/button';
import SaveButton from '../../shared/save-button';

const KudosSettings = () => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);

    const [permissionForWrite, setPermissionForWrite] = useState(false);

    const selectedOrganisationData = organisationsState.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const request = useCallback(() => getEmoji(), []);

    const [kudosState, kudosDispatch] = useFetchDataAndSetState(request, [
        selectedOrganisationData,
    ]);

    const [initialEmoji, setInitialEmoji] = useState([]);

    useEffect(() => {
        setPermissionForWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    const saveEmojis = async () => {
        try {
            const modified_date = moment().unix();
            const data = {
                emoji_list: kudosState.data,
                modified_date,
            };

            let initialEmojisList = [];

            const response = await getEmoji();
            if (response?.data) {
                initialEmojisList = response.data;
            }

            if (initialEmojisList.length < 1) {
                createEmoji(data)
                    .then((response) => {
                        if (response.status === 200) {
                            setInitialEmoji(kudosState.data);
                            kudosDispatch({
                                type: 'SET_DATA',
                                payload: kudosState.data,
                            });
                        }
                    })
                    .catch((error) => {
                        kudosDispatch({ type: 'SET_ERROR', payload: error });
                    });
            } else {
                updateEmoji(data)
                    .then((response) => {
                        if (response.status === 200) {
                            setInitialEmoji(kudosState.data);
                            kudosDispatch({
                                type: 'SET_DATA',
                                payload: kudosState.data,
                            });
                        }
                    })
                    .catch((error) => {
                        kudosDispatch({ type: 'SET_ERROR', payload: error });
                    });
            }
            return;
        } catch (error) {
            console.log(error);
        }
    };

    const selectNewEmoji = (emoji) => {
        const alreadySelected =
            kudosState?.data?.findIndex((item) => item === emoji.id) + 1;

        if (!kudosState?.data) {
            kudosDispatch({
                type: 'SET_DATA',
                payload: [emoji.id],
            });
            return;
        }

        if (!alreadySelected)
            kudosDispatch({
                type: 'SET_DATA',
                payload: [...kudosState.data, emoji.id],
            });
    };

    const areDisabled = () => {
        const hasChange = _.isEqual(
            _.sortBy(initialEmoji),
            _.sortBy(kudosState.data)
        );

        return hasChange;
    };
    if (kudosState.loading) {
        return (
            <div className="emoji-settings">
                <div className="grid grid-cols-12">
                    <div className="col-span-6">
                        <div className="picker-block">
                            <p className="settings-sub-title">
                                {capitalizeFirstLetter(
                                    t('settings_page.kudos_tab.select')
                                )}
                            </p>
                            <Picker
                                data={data}
                                emojiVersion="14"
                                skin="1"
                                onEmojiSelect={(emoji) => {
                                    return permissionForWrite
                                        ? selectNewEmoji(emoji)
                                        : () => {};
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <div className="selected-emoji-list-block">
                            <p className="settings-sub-title">
                                {capitalizeFirstLetter(
                                    t('settings_page.kudos_tab.selected')
                                )}
                            </p>
                            <div>
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>

                            <SaveButton
                                saveFunc={saveEmojis}
                                disabled={areDisabled()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="emoji-settings">
            <div className="grid grid-cols-12">
                <div className="col-span-6">
                    <div className="picker-block">
                        <p className="settings-sub-title">
                            {capitalizeFirstLetter(
                                t('settings_page.kudos_tab.select')
                            )}
                        </p>
                        <Picker
                            data={data}
                            emojiVersion="14"
                            skin="1"
                            skinTonePosition="none"
                            onEmojiSelect={(emoji) => {
                                return permissionForWrite
                                    ? selectNewEmoji(emoji)
                                    : () => {};
                            }}
                        />
                    </div>
                </div>
                <div className="col-span-6">
                    <div className="selected-emoji-list-block">
                        <p className="settings-sub-title">
                            {capitalizeFirstLetter(
                                t('settings_page.kudos_tab.selected')
                            )}
                        </p>
                        <div className="selected-emoji-list">
                            {kudosState.data.map((emoji) => {
                                return (
                                    <EmojiComponent
                                        key={emoji}
                                        emoji={emoji}
                                        kudosData={kudosState.data}
                                        kudosDispatch={kudosDispatch}
                                        permissionForWrite={permissionForWrite}
                                    />
                                );
                            })}
                        </div>
                        <div className="emoji-save-wrapper">
                            <SaveButton
                                saveFunc={saveEmojis}
                                disabled={areDisabled()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EmojiComponent = ({
    emoji,
    kudosDispatch,
    kudosData,
    permissionForWrite,
}) => {
    const removeEmoji = () => {
        const newEmojiList = kudosData.filter((sEmoji) => sEmoji !== emoji);
        kudosDispatch({ type: 'SET_DATA', payload: newEmojiList });
    };

    return (
        <div className="selected-emoji">
            <em-emoji id={emoji} size={50} set="google"></em-emoji>
            {permissionForWrite ? (
                <Button
                    extraClasses="selected-emoji__remove"
                    onClick={removeEmoji}
                >
                    <CrossInCircle width="18" height="18" fill="#dc3545" />
                </Button>
            ) : null}
        </div>
    );
};

export { KudosSettings };
