import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineEditNote } from 'react-icons/md';
import { getWritePermission } from '../../../helpers/getPermission';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import useOnClickOutside from '../../../helpers/closeOnOutsideClick';
import showNotification from '../../../helpers/showNotification';
import { UserContext } from '../../../context/UserContext';
import Button from '../../../components/shared/button';

function SreNote({ note, notesLength, onNoteEdit, onNoteDelete, i }) {
    const ref = useRef();
    const { organisationsState } = useContext(OrganisationsContext);
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(note?.note ? note?.note : '');

    useOnClickOutside(
        ref,
        () => {
            setIsEdit(false);
            setValue(note?.note ? note?.note : '');
        },
        []
    );

    function handleEditNote() {
        if (!value?.length) {
            showNotification(`the note is empty`);
            return;
        }
        onNoteEdit(value, note.id);
    }

    return (
        <div
            ref={ref}
            className={`w-full p-2 flex justify-between gap-4 items-center  ${
                notesLength > 1 && i !== notesLength - 1
                    ? 'border-b border-solid border-gray-200'
                    : ''
            }`}
        >
            {isEdit ? (
                <input
                    id={note.id}
                    value={value}
                    type="text"
                    placeholder="Your note..."
                    className="w-full outline-none"
                    autoFocus={true}
                    onBlur={({ target }) => target.focus()}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyDown={(event) =>
                        event.key.toLocaleLowerCase() === 'enter' &&
                        handleEditNote()
                    }
                />
            ) : (
                <p>{note.note}</p>
            )}
            <div className="flex gap-4 items-center">
                {isEdit ? null : (
                    <div className="text-xs flex flex-col items-end">
                        <p>{note.creator_info}</p>
                        <p>
                            {typeof note.updated_at === 'string'
                                ? moment(note.updated_at).fromNow()
                                : moment.unix(note.updated_at).fromNow()}
                        </p>
                    </div>
                )}
                {getWritePermission(organisationsState?.data) ? (
                    <div className="flex gap-2 items-center">
                        {isEdit ? (
                            <Button
                                type="button"
                                variant="grayText"
                                onClick={() => handleEditNote()}
                            >
                                Save
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    extraClasses="w-6 h-6 flex justify-center items-center button-with-grey-icon"
                                    onClick={() => setIsEdit(true)}
                                >
                                    <MdOutlineEditNote className="fill-current grey-icon" />
                                </Button>
                                <Button
                                    type="button"
                                    extraClasses="w-6 h-6 flex justify-center items-center button-with-grey-icon"
                                    onClick={() => onNoteDelete(note.id)}
                                >
                                    <AiOutlineDelete className="fill-current grey-icon" />
                                </Button>
                            </>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default SreNote;
