import React, { useContext } from 'react';
import { IoIosAdd, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import moment from 'moment';
import HoursPageTimesheetToolbarDatePicker from './DatePicker';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../../context/OrganisationsContext';
import { getWritePermission } from '../../../../helpers/getPermission';
import Button from '../../../../components/shared/button';

function HoursPageTimesheetToolbar({
    filterDate,
    setFilterDate,
    addRow,
    addDisabled,
    updateDates,
    isEditMode,
}) {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);

    function formatFilterString() {
        const startDate = filterDate.clone().startOf('isoWeek');
        const endDate = filterDate.clone().endOf('isoWeek');

        return `${startDate.format('D')} ${
            startDate.format('M') !== endDate.format('M')
                ? startDate.format('MMMM')
                : ''
        } - ${endDate.format('D')} ${endDate.format('MMMM')} ${endDate.format(
            'YYYY'
        )} (Week ${startDate.format('W')})`;
    }

    function subtractWeek() {
        setFilterDate((prev) => prev.clone().subtract(1, 'week'));
        updateDates();
    }

    function addWeek() {
        setFilterDate((prev) => prev.clone().add(1, 'week'));
        updateDates();
    }

    function setFilterDateNow() {
        setFilterDate(moment());
        updateDates();
    }

    return (
        <div className="flex justify-between items-center ">
            <div className="text-lg font-light text-gray-500">
                {formatFilterString()}
            </div>
            <div className="flex justify-between items-center space-x-4">
                {isEditMode ? null : (
                    <>
                        <HoursPageTimesheetToolbarDatePicker
                            filterDate={filterDate}
                            setFilterDate={setFilterDate}
                            updateDates={updateDates}
                        />
                        <div className="flex justify-between items-center ">
                            <Button
                                variant={'transpatentHoverGray'}
                                extraClasses={'h-8'}
                                onClick={subtractWeek}
                            >
                                <IoIosArrowBack />
                            </Button>
                            <Button
                                variant={'transpatentHoverGray'}
                                extraClasses={'h-8'}
                                onClick={setFilterDateNow}
                            >
                                {t('hours_page.timesheet.this_week')}
                            </Button>
                            <Button
                                variant={'transpatentHoverGray'}
                                extraClasses={'h-8'}
                                onClick={addWeek}
                            >
                                <IoIosArrowForward />
                            </Button>
                        </div>
                    </>
                )}

                {getWritePermission(organisationsState?.data) ? (
                    <Button
                        variant="auth"
                        onClick={addRow}
                        extraClasses={'h-8'}
                        title={'Add hours'}
                        disabled={addDisabled}
                    >
                        <IoIosAdd size={20} /> {t('button.add')}
                    </Button>
                ) : null}
            </div>
        </div>
    );
}

export default HoursPageTimesheetToolbar;
