import React, { useEffect, useState } from 'react';
import HoursPageTimesheetOverviewBodyRow from './row';
import { useTranslation } from 'react-i18next';

function HoursPageTimesheetOverviewBody({
    data,
    setData,
    isEditMode,
    setIsEditMode,
    selectedProjects,
    weekRange,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
    isSavingAllowed,
    setIsSavingAllowed,
}) {
    const { t } = useTranslation();

    return (
        <div className="text-gray-500">
            {!data.length && !selectedProjects.length && (
                <p className="flex my-4 justify-center text-center">
                    {t('hours_page.timesheet.no_projects')}
                </p>
            )}
            {!data?.projects?.length &&
                selectedProjects &&
                selectedProjects
                    ?.sort((a, b) =>
                        a?.toLowerCase().localeCompare(b?.toLowerCase())
                    )
                    ?.map((project, i) => (
                        <HoursPageTimesheetOverviewBodyRow
                            key={project ? project : 'new-row' + i}
                            data={data}
                            setData={setData}
                            project={project}
                            selectedProjects={selectedProjects}
                            days={[]}
                            weekRange={weekRange}
                            setEmptyRow={setEmptyRow}
                            triggerUpdate={triggerUpdate}
                            userHash={userHash}
                            viewerHash={viewerHash}
                            filterDate={filterDate}
                            isEditMode={isEditMode}
                            isSavingAllowed={isSavingAllowed}
                            setIsSavingAllowed={setIsSavingAllowed}
                        />
                    ))}
            {data?.newProjects?.map((item, i) => (
                <HoursPageTimesheetOverviewBodyRow
                    key={item.project ? item.project : 'new-row-select' + i}
                    data={data}
                    setData={setData}
                    project={item.project}
                    days={item.days}
                    weekRange={weekRange}
                    setEmptyRow={setEmptyRow}
                    triggerUpdate={triggerUpdate}
                    selectedProjects={selectedProjects}
                    userHash={userHash}
                    viewerHash={viewerHash}
                    filterDate={filterDate}
                    isEditMode={isEditMode}
                    isSavingAllowed={isSavingAllowed}
                    setIsSavingAllowed={setIsSavingAllowed}
                    isNew={true}
                />
            ))}
            {data?.projects?.map((item, i) => (
                <HoursPageTimesheetOverviewBodyRow
                    key={item.project ? item.project : 'new-row-no-select' + i}
                    data={data}
                    setData={setData}
                    project={item.project}
                    days={item.days}
                    weekRange={weekRange}
                    setEmptyRow={setEmptyRow}
                    triggerUpdate={triggerUpdate}
                    selectedProjects={selectedProjects}
                    userHash={userHash}
                    viewerHash={viewerHash}
                    filterDate={filterDate}
                    isEditMode={isEditMode}
                    isSavingAllowed={isSavingAllowed}
                    setIsSavingAllowed={setIsSavingAllowed}
                    isNew={false}
                />
            ))}
        </div>
    );
}

export default HoursPageTimesheetOverviewBody;
