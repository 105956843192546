import React, { useContext, useEffect, useState } from 'react';
import ProjectsTableDefault from '../default/table';
import Card from '../../../components/card';
import Loader from '../../../components/loader';
import { IoIosAdd } from 'react-icons/io';
import {
    ProjectsContext,
    PrivateProjectsContext,
} from '../../../context/ProjectsContext';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import {
    createPrivateProjects,
    createProjects,
    deletePrivateProjects,
    deleteProjects,
    getPrivateProjects,
    getProjects,
    updatePrivateProjects,
    updateProjects,
} from '../../../api/hours/HoursAPI';
import { useTranslation } from 'react-i18next';
import { getAdminPermission } from '../../../helpers/getPermission';
import Button from '../../../components/shared/button';
import { UserContext } from '../../../context/UserContext';

function HoursPageProjects({ type }) {
    const { t } = useTranslation();

    const isPrivate = type === 'private';

    const { userState } = useContext(UserContext);

    const projects = isPrivate ? PrivateProjectsContext : ProjectsContext;
    const { projectsState, projectsDispatch } = useContext(projects);

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const [userHash, setUserHash] = useState(
        selectedOrgHash === demoOrgHash
            ? 'URICHAR'
            : userState?.data?.user_hash || null
    );

    useEffect(() => {
        if (isPrivate) {
            if (selectedOrgHash === demoOrgHash) {
                setUserHash('URICHAR');
            } else {
                setUserHash(userState?.data?.user_hash || null);
            }
        }
        void fetchProjects();
    }, [selectedOrgHash]);

    async function fetchProjects() {
        projectsDispatch({ type: 'SET_LOADING' });
        try {
            const res = isPrivate
                ? await getPrivateProjects(userHash)
                : await getProjects();
            if (res.status === 202 && isPrivate) {
                projectsDispatch({ type: 'SET_NO_LOADING' });
            } else if (res.status === 204) {
                projectsDispatch({ type: 'SET_DATA', payload: [] });
            } else {
                projectsDispatch({ type: 'SET_DATA', payload: res.data });
            }
        } catch (err) {
            projectsDispatch({ type: 'SET_ERROR', payload: err });
        }
    }

    function addRow() {
        projectsDispatch({
            type: 'SET_EMPTY_ROW',
            payload: { name: '', description: '' },
        });
    }

    async function addFirstProject(project) {
        projectsDispatch({ type: 'SET_LOADING' });
        try {
            const res = isPrivate
                ? await createPrivateProjects(userHash, [project])
                : await createProjects([project]);
            if (res.status === 202) {
                projectsDispatch({ type: 'SET_NO_LOADING', payload: res.data });
            } else if (res) {
                projectsDispatch({ type: 'SET_DATA', payload: res.data });
            }
        } catch (err) {
            projectsDispatch({ type: 'SET_ERROR', payload: err });
        }
    }

    async function handleUpdateProjects(prevRowState, updatedRow) {
        const updatedProject = updatedRow.values;
        const filteredProjects = projectsState?.data.filter(
            (project) => project.name
        );

        // check if there is a list of projects
        if (!filteredProjects.length) {
            await addFirstProject(updatedProject);
        } else {
            // check if it is an updated project or a new one
            const isUpdatedRow = filteredProjects.find(
                (project) => project.name === prevRowState.values.name
            );
            let updatedProjects = filteredProjects;
            if (isUpdatedRow) {
                updatedProjects = filteredProjects.map((project) => {
                    if (project.name !== prevRowState.values.name) {
                        return project;
                    } else {
                        return updatedProject;
                    }
                });
            } else {
                updatedProjects = [...filteredProjects, updatedProject];
            }

            projectsDispatch({ type: 'SET_LOADING' });
            try {
                const res = isPrivate
                    ? await updatePrivateProjects(userHash, updatedProjects)
                    : await updateProjects(updatedProjects);
                if (res.status === 202) {
                    projectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    projectsDispatch({ type: 'SET_DATA', payload: res.data });
                }
            } catch (err) {
                projectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        }
    }

    async function handleDeleteProject(projectToDelete) {
        const updatedProjects = projectsState?.data.filter(
            (project) => project.name !== projectToDelete.name
        );

        if (!updatedProjects.length) {
            projectsDispatch({ type: 'SET_LOADING' });
            try {
                const res = isPrivate
                    ? await deletePrivateProjects(userHash)
                    : await deleteProjects();
                if (res.status === 202 && isPrivate) {
                    projectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    projectsDispatch({ type: 'SET_DATA', payload: [] });
                }
            } catch (err) {
                projectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        } else {
            projectsDispatch({ type: 'SET_LOADING' });
            try {
                const res = isPrivate
                    ? await updatePrivateProjects(userHash, updatedProjects)
                    : await updateProjects(updatedProjects);
                if (res.status === 202) {
                    projectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    projectsDispatch({ type: 'SET_DATA', payload: res.data });
                }
            } catch (err) {
                projectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        }
    }

    function renderActionButtons() {
        if (getAdminPermission(organisationsState?.data)) {
            return (
                <Button
                    onClick={addRow}
                    extraClasses={'h-8'}
                    variant={'auth'}
                    title={isPrivate ? 'Add private project' : 'Add project'}
                    disabled={projectsState?.data?.find(
                        (item) => item.name === ''
                    )}
                >
                    <IoIosAdd size={20} /> {t('button.add')}
                </Button>
            );
        }
    }

    if (projectsState?.loading)
        return (
            <Card
                title={
                    isPrivate
                        ? t('hours_page.private_projects.title')
                        : t('hours_page.organisation_projects.title')
                }
            >
                <div className="flex mt-8 justify-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </Card>
        );

    if (projectsState?.error)
        return (
            <Card
                title={
                    isPrivate
                        ? t('hours_page.private_projects.title')
                        : t('hours_page.organisation_projects.title')
                }
            >
                <p className="flex mt-8 justify-center text-center">
                    {projectsState?.error.response?.data ||
                        projectsState?.error.message ||
                        'Oops! Something went wrong!'}
                </p>
            </Card>
        );

    return (
        <Card
            title={
                isPrivate
                    ? t('hours_page.private_projects.title')
                    : t('hours_page.organisation_projects.title')
            }
            renderActionButtons={renderActionButtons}
        >
            {projectsState?.data ? (
                <ProjectsTableDefault
                    data={projectsState?.data}
                    updateProjects={handleUpdateProjects}
                    deleteProject={handleDeleteProject}
                    type={type}
                />
            ) : null}
        </Card>
    );
}

export default HoursPageProjects;
