import React from 'react';
import { useState } from 'react';
import { ImBin } from 'react-icons/im';
import { deleteProjectHours } from '../../../../../../api/hours/HoursAPI';
import Loader from '../../../../../../components/loader';
import showNotification from '../../../../../../helpers/showNotification';
import ModalWrapper from '../../../../../../components/shared/modals/modal-wrapper';
import Button from '../../../../../../components/shared/button';
import DeleteContent from '../../../../../../components/shared/modals/delete-content';
import { capitalizeFirstLetter } from '../../../../../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';

function HoursPageTimesheetOverviewBodyActions({
    isEditMode,
    project,
    triggerUpdate,
    filterDate,
    userHash,
    isNew,
    data,
    setData,
}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    async function handleProjectDelete() {
        setLoading(true);
        try {
            const res = await deleteProjectHours(userHash, project, {
                params: {
                    filterDate: filterDate.clone().day(1).format('YYYY-MM-DD'),
                },
            });
            setLoading(false);
            res && triggerUpdate();
        } catch (err) {
            setLoading(false);
            showNotification(
                `${err.response?.data || err.message || 'Failed to delete'}`
            );
        }
    }

    return (
        <div className="flex justify-end px-2 w-48 ">
            {loading ? (
                <div>
                    <Loader color={'#C2C7D7'} size={12} speedMultiplier={0.8} />
                </div>
            ) : isEditMode ? (
                <Button
                    extraClasses={`text-red-600 transform hover:scale-110 hover:text-red-700 disabled:opacity-50`}
                    onClick={() => {
                        if (isNew) {
                            const updated = {
                                projects: data?.projects,
                                newProjects: data?.newProjects?.filter(
                                    (p) => project !== p?.project
                                ),
                            };
                            setData(updated);
                        } else {
                            setModalOpen(true);
                        }
                    }}
                >
                    <ImBin />
                </Button>
            ) : null}

            <ModalWrapper
                modalState={modalOpen}
                setModalState={setModalOpen}
                title="Remove project"
            >
                <DeleteContent
                    handleAction={handleProjectDelete}
                    setModalState={setModalOpen}
                >
                    <p className="text-xl text-gray-500">
                        {capitalizeFirstLetter(
                            t('hours_page.delete_message', { project: project })
                        )}
                    </p>
                </DeleteContent>
            </ModalWrapper>
        </div>
    );
}

export default HoursPageTimesheetOverviewBodyActions;
