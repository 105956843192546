import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Loader from '../loader';
import { getSwarmRepos, testProvider } from '../../api/swarm/SwarmAPI.js';
import { Github, Gitlab, Bitbucket } from '../../assets/svg.js';
import showNotification from '../../helpers/showNotification.js';
import {
    createGitProvider,
    deleteGitProvider,
    updateGitProvider,
} from '../../api/settings/GitProviderAPI';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import ModalWrapper from '../shared/modals/modal-wrapper/index.js';
import Button from '../shared/button/index.js';
import DeleteContent from '../shared/modals/delete-content/index.js';
import SaveButton from '../shared/save-button/index.js';

const VCSProviderItem = ({
    vcsProviderData,
    permissionToWrite,
    onDeleteEmptyItem,
    onUpdateProvider,
}) => {
    const { t } = useTranslation();
    const { access_token, provider, provider_id, id, user_name } =
        vcsProviderData;
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [isKeyTouched, setIsKeyTouched] = useState(false);
    const [bitbucketUserName, setBitbucketUserName] = useState('');
    const [projects, setProjects] = useState({
        data: null,
        loading: false,
        error: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        let data = {
            provider,
            name: provider_id,
        };

        !provider_id && setIsNew(true);

        access_token && handleTestKeyAndFetchProjects(data);
        !name && setName(provider_id);
        !key && setKey(access_token);
        !bitbucketUserName && setBitbucketUserName(user_name);
    }, [vcsProviderData]);

    useEffect(() => {
        name === provider_id && key === access_token
            ? setIsUpdateDisabled(true)
            : setIsUpdateDisabled(false);
    }, [name, key]);

    useEffect(() => {
        if (access_token === key) {
            setIsKeyTouched(false);
        } else {
            setIsKeyTouched(true);
        }
    }, [key]);

    const renderLogoBlock = () => {
        if (provider) {
            if (provider.toLowerCase().startsWith('gitlab')) {
                return (
                    <div className="flex items-center gap-x-7 mb-4">
                        <Gitlab />
                        <h2 className="font-display font-light text-xl leading-6 text-black">
                            GitLab
                        </h2>
                    </div>
                );
            } else if (provider.toLowerCase().startsWith('github')) {
                return (
                    <div className="flex items-center gap-x-7 mb-4">
                        <Github />
                        <h2 className="font-display font-light text-xl leading-6 text-black">
                            GitHub
                        </h2>
                    </div>
                );
            } else if (provider.toLowerCase().startsWith('bitbucket')) {
                return (
                    <div className="flex items-center gap-x-7 mb-4">
                        <Bitbucket />
                        <h2 className="font-display font-light text-xl leading-6 text-black">
                            Bitbucket
                        </h2>
                    </div>
                );
            } else {
                console.log('invalid provider type');
            }
        }
    };

    const testApiKeySymbols = (key) => {
        const regexpTest = /^[A-Za-z0-9._-]*[A-Za-z0-9][A-Za-z0-9._-]*$/.test(
            key
        );
        if (!regexpTest) {
            showNotification(
                "Key must be an empty string or consist of alphanumeric characters, '-', '_' or '.'",
                'error'
            );
            return false;
        }
        return true;
    };

    const testApiKeyLetters = (key) => {
        const regexpTestFirst = /[a-zA-Z0-9]+/.test(key[0]);
        const regexpTestLast = /[a-zA-Z0-9]+/.test(key[key.length - 1]);
        if (!regexpTestFirst || !regexpTestLast) {
            showNotification(
                'Key must start and end with an alphanumeric character',
                'error'
            );
            return false;
        }
        return true;
    };

    const handleTestKeyAndFetchProjects = async (data, isTest = false) => {
        try {
            setProjects((state) => ({ ...state, loading: true }));
            let res;
            if (isTest) {
                res = await testProvider(data);
            } else {
                res = await getSwarmRepos(data);
            }

            if (res?.status === 200) {
                isTest && showNotification('Token is valid', 'success');
                Array.isArray(res.data)
                    ? setProjects({
                          loading: false,
                          error: null,
                          data: res.data,
                      })
                    : setProjects({
                          loading: false,
                          error: res.data,
                          data: null,
                      });
            } else {
                showNotification(res?.response?.data ?? res?.message);
            }
        } catch (error) {
            setProjects((state) => ({
                ...state,
                loading: false,
                error: error?.response?.data ?? error.message,
            }));
        }

        setProjects((state) => ({ ...state, loading: false }));
    };

    const testKey = () => {
        if (!testApiKeySymbols(key) || !testApiKeyLetters(key)) {
            return;
        }

        let data = {
            provider,
            name: name,
        };

        if (isNew || isKeyTouched) {
            data = {
                provider,
                access_token: key,
            };

            if (provider === 'bitbucket') {
                data = { ...data, user_name: bitbucketUserName };
            }
        }

        key || (!key && !isNew)
            ? handleTestKeyAndFetchProjects(data, true)
            : showNotification(
                  capitalizeFirstLetter(
                      t('settings_page.git_tab.token_required')
                  ),
                  'error'
              );
    };

    const handleCreateProvider = async () => {
        if (!name || !key) {
            showNotification(
                capitalizeFirstLetter(
                    t('settings_page.git_tab.name_and_token_required')
                ),
                'error'
            );
            return;
        }

        if (provider === 'bitbucket' && !bitbucketUserName) {
            showNotification(
                capitalizeFirstLetter(
                    t('settings_page.git_tab.username_required')
                ),
                'error'
            );
            return;
        }

        if (!testApiKeySymbols(key) || !testApiKeyLetters(key)) {
            return;
        }

        setIsLoading(true);
        let data = {
            provider,
            access_token: key,
            provider_id: name,
            modified_date: moment().unix(),
        };
        if (provider === 'bitbucket') {
            data = { ...data, user_name: bitbucketUserName };
        }

        try {
            const response = await createGitProvider(data);
            setIsLoading(false);
            response.status === 200 &&
                showNotification(
                    capitalizeFirstLetter(t('settings_page.git_tab.created')),
                    'success'
                );

            response.status === 200 && setIsKeyTouched(false);
            response.status === 200 && onUpdateProvider();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message}`,
                'error'
            );
        }
    };

    const handleUpdateProvider = async () => {
        if (!isNew && !key) {
            showNotification(
                capitalizeFirstLetter(
                    t('settings_page.git_tab.name_and_token_required')
                ),
                'error'
            );
            return;
        }

        if (!name) {
            showNotification(
                capitalizeFirstLetter('Provider Name is required'),
                'error'
            );
            return;
        }
        if (name === provider_id && key === access_token) {
            showNotification(
                capitalizeFirstLetter(
                    t('settings_page.git_tab.update_warning')
                ),
                'error'
            );
            return;
        }

        if (!testApiKeySymbols(key) || !testApiKeyLetters(key)) {
            return;
        }

        setIsLoading(true);
        let data = {
            provider,
            provider_id: name,
            modified_date: moment().unix(),
        };

        if (key !== vcsProviderData?.access_token) {
            data = { ...data, access_token: key };
        }

        try {
            const response = await updateGitProvider(provider_id, data);
            setIsLoading(false);
            response.status === 200 &&
                showNotification(
                    capitalizeFirstLetter(t('settings_page.git_tab.updated')),
                    'success'
                );
            response.status === 200 && setIsKeyTouched(false);
            response.status === 200 && onUpdateProvider();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message}`,
                'error'
            );
        }
    };

    const handleDeleteClick = () => {
        id ? onDeleteEmptyItem(id) : setIsDeleteModalOpen(true);
    };

    const deleteProvider = async () => {
        setIsLoading(true);
        try {
            const response = await deleteGitProvider(name);
            setIsLoading(false);
            response.status === 200 &&
                showNotification('Provider successfully deleted', 'success');
            response.status === 200 && onUpdateProvider();
        } catch (error) {
            setIsLoading(false);
            showNotification(
                `${error.response?.data || error.message}`,
                'error'
            );
        }
    };

    return (
        <li className="w-480 h-450 flex flex-col bg-white rounded-lg shadow-workspace-item p-4">
            {renderLogoBlock()}

            <div className="mb-2">
                <label
                    className="block mb-1 font-light text-gray-500 leading-5"
                    htmlFor={'name' + name}
                >
                    {capitalizeFirstLetter(t('settings_page.git_tab.name'))}
                </label>
                <input
                    className="w-60	p-1.5 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                    id={'name' + name}
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!permissionToWrite}
                />
            </div>
            {provider === 'bitbucket' && (
                <div className="mb-2">
                    <label
                        className="block mb-1 font-light text-gray-500 leading-5"
                        htmlFor={'username' + bitbucketUserName}
                    >
                        Username
                    </label>
                    <input
                        className="w-60	p-1.5 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                        id={'username' + bitbucketUserName}
                        type="text"
                        placeholder="Enter Bitbucket username"
                        value={bitbucketUserName}
                        onChange={(e) => setBitbucketUserName(e.target.value)}
                        disabled={!permissionToWrite}
                    />
                </div>
            )}
            <div className="mb-2">
                <label
                    className="block mb-1 font-light text-gray-500 leading-5"
                    htmlFor={'access-token' + key}
                >
                    {provider === 'bitbucket'
                        ? capitalizeFirstLetter(
                              t('settings_page.git_tab.app_password')
                          )
                        : capitalizeFirstLetter(
                              t('settings_page.git_tab.token')
                          )}
                </label>

                <div className="flex gap-x-4 items-stretch">
                    <input
                        className={`${
                            permissionToWrite ? 'w-360' : 'w-full'
                        } p-2 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray`}
                        id="access-token"
                        type="password"
                        placeholder={
                            provider === 'bitbucket'
                                ? `Enter ${capitalizeFirstLetter(
                                      t('settings_page.git_tab.app_password')
                                  )}`
                                : `Enter ${capitalizeFirstLetter(
                                      t('settings_page.git_tab.token')
                                  )}`
                        }
                        value={key}
                        onChange={(e) => setKey(e.target.value.trim())}
                        disabled={!permissionToWrite}
                    />
                    {permissionToWrite ? (
                        <Button
                            variant="whiteHoverBlue"
                            onClick={() => testKey()}
                        >
                            Test
                        </Button>
                    ) : null}
                </div>
            </div>

            <div className=" flex-grow flex flex-col mb-6">
                <p className="block mb-1 font-light text-gray-500 leading-5">
                    {capitalizeFirstLetter(t('settings_page.git_tab.projects'))}
                </p>
                {projects.loading && (
                    <div className="flex-grow text-center mt-4">
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    </div>
                )}
                {projects.error && !projects.loading && (
                    <div className="flex-grow mt-4">
                        <p className="text-sm rounded p-2 bg-red-text bg-opacity-20 text-red-text">
                            {projects.error}
                        </p>
                    </div>
                )}
                {((!projects.error && !projects.loading && !projects.data) ||
                    (projects.data && projects.data.length === 0)) && (
                    <div className="flex-grow mt-4">
                        <p className="text-sm text-gray-500">
                            {t('settings_page.git_tab.no_projects')}
                        </p>
                    </div>
                )}
                {projects.data &&
                    !projects.error &&
                    !projects.loading &&
                    projects.data.length !== 0 && (
                        <ul
                            className={`py-2	px-3 flex-grow ${
                                provider === 'bitbucket'
                                    ? 'max-h-24'
                                    : 'max-h-155'
                            } overflow-auto border border-gray-300 rounded divide-y divide-solid divide-gray-300 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400`}
                        >
                            {projects.data.map((project) => (
                                <li
                                    className="py-1 text-11 leading-3 text-theme-button hover:text-theme-button"
                                    key={project.url || project.repo_url}
                                >
                                    <p className="text-black font-bold ">
                                        {project.name || project.repo_name}
                                    </p>
                                    <a
                                        href={`https://${
                                            project.url || project.repo_url
                                        }`}
                                        target="_blank"
                                    >
                                        {project.url || project.repo_url}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
            </div>

            {permissionToWrite && (
                <div className={`flex justify-between items-center`}>
                    <Button variant="redText" onClick={handleDeleteClick}>
                        {capitalizeFirstLetter(
                            t('settings_page.git_tab.remove')
                        )}
                    </Button>
                    {isLoading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : (
                        <SaveButton
                            saveFunc={
                                provider_id
                                    ? handleUpdateProvider
                                    : handleCreateProvider
                            }
                            text={
                                provider_id
                                    ? capitalizeFirstLetter(t('button.update'))
                                    : capitalizeFirstLetter(t('button.save'))
                            }
                            disabled={isUpdateDisabled}
                        />
                    )}
                </div>
            )}

            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title="Remove provider"
            >
                <DeleteContent
                    handleAction={deleteProvider}
                    setModalState={setIsDeleteModalOpen}
                >
                    <p className="text-xl text-gray-500">
                        Are you sure you want to remove{' '}
                        <span className="italic">{provider_id}</span> provider?
                    </p>
                </DeleteContent>
            </ModalWrapper>
        </li>
    );
};

export default VCSProviderItem;
