import { useTranslation } from 'react-i18next';
import { capitalizeEveryWordFirstLetter } from '../../helpers/textFormatters';
import ErrorBoundary from '../error-boundary';
import TableWrapper from '../shared/table/table-wrapper';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import moment from 'moment';
import { useState, useContext } from 'react';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { getStockTable } from '../../api/stock/StockAPI';

function RepositoryStockTable({ repository }) {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_end, date_start } = timePeriodState;
    const [loading, setLoading] = useState(true);

    const defaultSortingColumnId = 'last_activity';
    const defaultSortingDirection = 'end';
    const columns = [
        {
            canSort: true,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.branch')
            ),
            columnId: 'branch',
            columnSize: 2,
        },
        {
            canSort: true,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.ahead')
            ),
            columnId: 'ahead',
            columnSize: 1,
        },
        {
            canSort: true,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.behind')
            ),
            columnId: 'behind',
            columnSize: 1,
        },
        {
            canSort: true,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.modified_lines')
            ),
            columnId: 'modified lines',
            columnSize: 2,
        },
        {
            canSort: true,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.last_activity')
            ),
            columnId: 'last activity',
            columnSize: 2,
        },
        {
            canSort: false,
            columnName: capitalizeEveryWordFirstLetter(
                t('stock_page.table.risk_category')
            ),
            columnId: 'risk',
            columnSize: 1,
            colorScheme: 'normal',
        },
    ];
    const gridColsAmount = columns
        .map((obj) => obj.columnSize)
        .reduce((acc, size) => acc + size, 0);

    async function fetchTableData(start, end, newSort, newDirection) {
        const params = {
            params: {
                repository: repository.repo_name,
                start: start ? start : '0',
                end: end ? end : repository.table_rows - 1,
                sort: newSort ? newSort : defaultSortingColumnId,
                direction:
                    newDirection === 'end' ? 'end_to_start' : 'start_to_end',
                date_start: date_start,
                date_end: date_end,
            },
        };

        !loading && setLoading(true);

        try {
            const res = await getStockTable(params);

            if (res?.status === 204) {
                setLoading(false);
                return [];
            } else if (res?.status === 200) {
                setLoading(false);
                return res?.data;
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return null;
        }
    }

    async function getTableRows(params) {
        const rows = await fetchTableData(
            params?.start,
            params?.end,
            params?.sort,
            params?.direction
        );
        const rowsDataWithTypesAndExtraClasses = rows?.map((row) => {
            return {
                branch: {
                    value: row?.branch,
                    type: 'string',
                    extraClasses: null,
                    colsSpan: columns.find((col) => col?.columnId === 'branch')
                        ?.columnSize,
                },
                ahead: {
                    value: row?.ahead,
                    type: 'number',
                    extraClasses: null,
                    colsSpan: columns.find((col) => col?.columnId === 'ahead')
                        ?.columnSize,
                },
                behind: {
                    value: row?.behind,
                    type: 'number',
                    extraClasses: null,
                    colsSpan: columns.find((col) => col?.columnId === 'behind')
                        ?.columnSize,
                },
                modified_lines: {
                    value: row?.modified_lines,
                    type: 'number',
                    extraClasses: null,
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'modified lines'
                    )?.columnSize,
                },
                last_activity: {
                    value: moment(row?.last_activity).format('YYYY MMM D'),
                    type: 'date',
                    extraClasses: null,
                    colsSpan: columns.find(
                        (col) => col?.columnId === 'last activity'
                    )?.columnSize,
                },
                risk: {
                    value: row?.risk,
                    type: 'level',
                    extraClasses: null,
                    colsSpan: columns.find((col) => col?.columnId === 'risk')
                        ?.columnSize,
                },
                error_message: {
                    value: row?.error_message,
                    type: 'error',
                    extraClasses: null,
                    colsSpan: gridColsAmount,
                },
            };
        });
        const data = { rows: rowsDataWithTypesAndExtraClasses, error: null };
        return data;
    }
    return (
        <WhitePanelContentWrapper
            className="mb-8"
            key={repository['repo_name']}
        >
            <ErrorBoundary>
                <TableWrapper
                    title={repository['repo_url']}
                    paginationType={'pages'}
                    columns={columns}
                    gridColsAmount={gridColsAmount}
                    onGetTableRows={getTableRows}
                    defaultSortingColumnId={defaultSortingColumnId}
                    defaultSortingDirection={defaultSortingDirection}
                    rowsQuantity={repository['table_rows']}
                    noDataFound={repository['no_stock_data']}
                    noDataFoundTrueText={t('stock_page.table.no_data')}
                    noDataFoundFalseText={t('stock_page.table.no_stock')}
                />
            </ErrorBoundary>
        </WhitePanelContentWrapper>
    );
}

export default RepositoryStockTable;
