import React, { useState, useRef } from 'react';
import { getAdminPermission } from '../../helpers/getPermission';
import { ImPencil } from 'react-icons/im';
import useOnClickOutside from '../../helpers/closeOnOutsideClick';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import UpdateOrganisationFormField from '../user-update-organisation-field';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import { FaSave, FaUndo } from 'react-icons/fa';
import Button from '../shared/button';

function UsersTabName({ userName, orgsState, handleUpdate }) {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const ref = useRef();
    const btnRef = useRef();

    useOnClickOutside(ref, () => isEdit && setIsEdit(false), [btnRef]);

    const initialValues = {
        name: userName,
    };

    async function handleSubmit(values) {
        const res = await handleUpdate(values);
        if (res) {
            setIsEdit(false);
        }
    }

    return (
        <>
            <p className="value" title={userName}>
                {userName}
            </p>
            {getAdminPermission(orgsState.data) ? (
                <div className="relative">
                    <Button
                        ref={btnRef}
                        extraClasses={`mr-2 text-gray-400 transform hover:text-gray-500 disabled:opacity-50`}
                        onClick={() => setIsEdit(!isEdit)}
                    >
                        <ImPencil />
                    </Button>
                    {isEdit ? (
                        <div
                            className="absolute top-full left-0 bg-white rounded p-2 shadow z-20 w-fit"
                            ref={ref}
                        >
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values) => handleSubmit(values)}
                                enableReinitialize
                            >
                                {({ errors, touched, values, setValues }) => {
                                    return (
                                        <Form className="w-fit">
                                            <div className="flex gap-x-2 w-72">
                                                <UpdateOrganisationFormField
                                                    name={capitalizeFirstLetter(
                                                        t(
                                                            'settings_page.users_tab.name'
                                                        )
                                                    )}
                                                    attributeName={'name'}
                                                    fieldType={'text'}
                                                    placeholderText={`${capitalizeFirstLetter(
                                                        t(
                                                            'settings_page.users_tab.name'
                                                        )
                                                    )}`}
                                                    error={errors.name}
                                                    touched={touched.name}
                                                    value={values.name}
                                                    required={true}
                                                    disabled={false}
                                                    showErrors={false}
                                                />
                                                <Button
                                                    extraClasses={`text-gray-400 transform hover:scale-110 hover:text-gray-500 disabled:opacity-50`}
                                                    type="button"
                                                    onClick={() => {
                                                        setValues(
                                                            initialValues
                                                        );
                                                        setIsEdit(false);
                                                    }}
                                                >
                                                    <FaUndo />
                                                </Button>
                                                <Button
                                                    extraClasses={`text-green-700 transform hover:scale-110 hover:text-green-800 disabled:opacity-50`}
                                                    type="submit"
                                                >
                                                    <FaSave />
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}

export default UsersTabName;
