// Social login constants
export * from './socialLogin';
export * from './jiraAuth';
export * from './navigationPaths';

export const MONDAY = 1;
export const DAYS_IN_WEEK = 7;
export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const YEARS = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];

export const PRICING = {
    retentionPrice: {
        annual: 0.5,
        monthly: 1,
    },
    contributorPrice: {
        basic: { annual: 3, monthly: 7 },
        professional: { annual: 8, monthly: 14 },
        enterprise: { annual: 17, monthly: 22 },
    },
};

export const FEATURES_FLAGS = [
    {
        name: 'sloProjectIdSelectFeature',
        isActive:
            process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_API_URL?.includes('maxeda'),
    },
    {
        name: 'passwordSignupFeature',
        isActive:
            process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_API_URL?.includes('maxeda'),
    },
    { name: 'alwaysOn', isActive: true },
];

export const ASSETS_BASE_PATH = process.env.REACT_APP_API_URL?.includes(
    'maxeda'
)
    ? 'https://storage.googleapis.com/zensoftware-media-agile-analytics-maxeda-8f33'
    : 'https://storage.googleapis.com/zensoftware-media-agile-analytics-prod-d7e7';

export const TIMERANGE_SELECTOR_PARAMS = [
    { amount: 6, entity: 'days', index: 1 },
    { amount: 13, entity: 'days', index: 2 },
    { amount: 2, entity: 'months', index: 3 },
    { amount: 3, entity: 'months', index: 4 },
];

// Error Budjets chart and SLOs
export const ERROR_BUDGET_CHART_COLORS = [
    '#7902D7',
    '#F8C238',
    '#15A2BB',
    '#81BC42',
    '#D6DA33',
    '#484A53',
];

export const ERROR_BUDGET_CHART_SLO_COLORS = [
    'before:bg-[#7902D7]',
    'before:bg-[#F8C238]',
    'before:bg-[#15A2BB]',
    'before:bg-[#81BC42]',
    'before:bg-[#D6DA33]',
    'before:bg-[#484A53]',
];
