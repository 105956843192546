import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import Loader from '../../../components/loader';
import ErrorIndicator from '../../../components/error-indicator';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../../helpers/textFormatters';

function CronjobStatus({ cronjobState }) {
    const { t } = useTranslation();

    if (cronjobState.loading)
        return (
            <div
                className="bg-white rounded-lg shadow-md w-full p-4 flex flex-col "
                id="job-status"
            >
                <div className="flex justify-center items-center ">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </div>
        );

    if (cronjobState.error)
        return (
            <div
                className="bg-white rounded-lg shadow-md w-full p-4 flex flex-col items-start"
                id="job-status"
            >
                <h2 className="mb-4 font-display text-28 text-gray-700 font-light border-b-2 border-solid border-theme-primary">
                    {capitalizeEveryWordFirstLetter(t('sre.last_job_status'))}
                </h2>
                <ErrorIndicator error={cronjobState.error} />
            </div>
        );

    return (
        <div
            className="bg-white rounded-lg shadow-md w-full p-4 flex flex-col items-start"
            id="job-status"
        >
            <h2 className="font-display text-28 text-gray-700 font-light border-b-2 border-solid border-theme-primary">
                {capitalizeEveryWordFirstLetter(t('sre.last_job_status'))}
            </h2>
            <div className="mt-4 font-display text-gray-500 font-light leading-5">
                {cronjobState?.data ? (
                    <>
                        <p className="mb-4">
                            <span className="font-medium">
                                {capitalizeFirstLetter(t('sre.status'))}:
                            </span>{' '}
                            {capitalizeFirstLetter(
                                t(
                                    `sre.${cronjobState?.data?.status?.toLowerCase()}`
                                )
                            )}
                        </p>
                        <p className="mb-4">
                            <span className="font-medium">{`${capitalizeFirstLetter(
                                t('sre.job')
                            )} ${t('common.name')}:`}</span>{' '}
                            {cronjobState?.data?.job_name}
                        </p>
                        <p className="mb-4">
                            <span className="font-medium">{`${capitalizeFirstLetter(
                                t('sre.last')
                            )} ${t('sre.run')}:`}</span>{' '}
                            {moment(cronjobState?.data?.last_run)
                                .local()
                                .format('dddd, MMMM Do YYYY, H:mm:ss')}
                        </p>
                        {cronjobState?.data?.failed_containers ? (
                            <>
                                <p className="mb-1">
                                <span className="font-medium">
                                    {capitalizeFirstLetter(t('sre.last_job_error_service'))}:
                                </span>
                                </p>
                                <ul className="ml-2 list-disc list-inside">
                                    {cronjobState?.data?.failed_containers?.map((falied_slo) => (
                                        <li key={falied_slo}>
                                            {capitalizeFirstLetter(
                                                falied_slo
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (<></>)}

                    </>
                ) : (
                    <p className="mb-4">
                        {capitalizeFirstLetter(t('sre.no_jobs'))}
                    </p>
                )}
            </div>
        </div>
    );
}

export default CronjobStatus;
