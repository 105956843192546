import React, { useState } from 'react';
import Button from '../../../../../components/shared/button';
import showNotification from '../../../../../helpers/showNotification';
import { updateHours } from '../../../../../api/hours/HoursAPI';
import Loader from '../../../../../components/loader';

function HoursPageTimesheetOverviewFooter({
    weekRange,
    data,
    resetTableData,
    isEditMode,
    setIsEditMode,
    isSavingAllowed,
    userHash,
    setEmptyRow,
    triggerUpdate,
}) {
    const [loading, setLoading] = useState(false);

    let totalHours = 0;

    async function saveChanges() {
        const allProjects = [...data?.projects, ...data?.newProjects];
        if (allProjects?.find((item) => !item?.project)) {
            showNotification('All rows should have a selected project');
            return;
        }
        setLoading(true);

        const formattedData = allProjects?.map((project) => {
            return {
                project: project.project,
                days: formatDays(project?.days),
            };
        });

        try {
            await updateHours(userHash, formattedData);
            setEmptyRow([]);
            triggerUpdate();
            setLoading(false);
            setIsEditMode(false);
        } catch (err) {
            setLoading(false);
            showNotification(
                `${err.response?.data || err.message || 'Failed to update'}`
            );
        }
    }

    function formatDays(days) {
        const formattedDays = days.map((day) => {
            const formattedDay = {
                ...day,
                hours: +day.hours.toString().replace(',', '.'),
                comment: day?.comment?.comment,
            };
            return formattedDay;
        });

        return formattedDays;
    }

    return (
        <div className="flex justify-between py-3 items-center">
            <div className="w-64 px-4 font-bold"></div>
            <div className="grid grid-cols-8 justify-between w-[512px]">
                {weekRange.map((day, index) => {
                    let hours = 0;

                    const formattedData = [
                        ...data?.projects,
                        ...data?.newProjects,
                    ];

                    if (formattedData?.length) {
                        for (const project of formattedData) {
                            if (!project.days) continue;

                            for (const item of project.days) {
                                if (item.date === day.format('YYYY-MM-DD')) {
                                    hours += +item.hours;
                                }
                            }
                        }
                    }

                    totalHours += +hours;

                    return (
                        <div
                            key={index}
                            className="px-4 w-16 font-bold text-left"
                        >
                            {+hours.toString().replace('.', ',') || 0}
                        </div>
                    );
                })}
                <div className="font-bold px-4 w-16 text-right">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
            <div className="flex gap-2 items-center justify-end w-48">
                {loading ? (
                    <div className="flex justify-end">
                        <Loader
                            color={'#C2C7D7'}
                            size={16}
                            speedMultiplier={0.8}
                        />
                    </div>
                ) : isEditMode ? (
                    <>
                        <Button
                            variant="transpatentHoverGray"
                            onClick={() => resetTableData()}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="auth"
                            onClick={() => saveChanges()}
                            disabled={!isSavingAllowed}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <Button variant="auth" onClick={() => setIsEditMode(true)}>
                        Edit
                    </Button>
                )}
            </div>
        </div>
    );
}

export default HoursPageTimesheetOverviewFooter;
