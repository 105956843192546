import React, { useContext } from 'react';
import Select from 'react-select';
import { BiCommentAdd, BiCommentDetail, BiCommentEdit } from 'react-icons/bi';
import _ from 'lodash';
import {
    ProjectsContext,
    PrivateProjectsContext,
} from '../../../../../../context/ProjectsContext';
import showNotification from '../../../../../../helpers/showNotification';
import Button from '../../../../../../components/shared/button';

function HoursPageTimesheetOverviewBodyRowEdit({
    data,
    setData,
    formValues,
    weekRange,
    selectedProjects,
    currentProject,
    userHash,
    viewerHash,
    isSavingAllowed,
    setIsSavingAllowed,
    isNew,
    openComment,
}) {
    let totalHours = 0;

    const projectsContext = useContext(ProjectsContext);
    const privateProjectsContext = useContext(PrivateProjectsContext);

    let allProjects = projectsContext?.projectsState?.data?.length
        ? [...projectsContext?.projectsState.data]
        : [];

    if (userHash === viewerHash) {
        allProjects = privateProjectsContext?.projectsState.data?.length
            ? [...allProjects, ...privateProjectsContext?.projectsState.data]
            : [...allProjects];
    }

    const notSelectedProjects = allProjects.filter((project) => {
        const isSelected = selectedProjects?.find(
            (selectedProject) =>
                selectedProject === project.name ||
                (data?.newProjects.find((p) => p?.project === project.name) &&
                    !currentProject)
        );
        if (!isSelected) {
            return project;
        }
    });

    const projectOptions = notSelectedProjects
        .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map(({ name }) => ({ value: name, label: name }));

    function handleChangeProject(project) {
        setData({
            projects: data?.projects,
            newProjects: data?.newProjects?.map((p) => {
                if (p?.project === currentProject) {
                    {
                        return { ...formValues, project: project?.value };
                    }
                } else {
                    return p;
                }
            }),
        });
    }

    function handleChangeHours(event) {
        const name = event.target.name;
        let value = event.target.value;
        let weekDays = formValues.days;

        if (+value > 24) {
            showNotification(
                'Day total mustn`t exceed 24 hours',
                'error',
                'hours-warning'
            );
            return;
        }

        const dayOtherProjectslHoursArray = [
            ...data?.projects,
            ...data?.newProjects,
        ].map((project) => {
            // don`t count current project previous hours
            if (!project.project || project.project === formValues.project) {
                return 0;
            } else {
                const dateHours = project.days.find((day) => day.date === name);
                return +dateHours?.hours;
            }
        });

        const dayOtherProjectslHours = dayOtherProjectslHoursArray.reduce(
            (acc, item) => (acc += +item),
            0
        );

        const dayTotalHours = +dayOtherProjectslHours + +value;

        if (value[0] === ',' || value.length > 4) {
            return;
        }

        if (dayTotalHours > 24) {
            showNotification(
                'Day total mustn`t exceed 24 hours',
                'error',
                'hours-warning'
            );
            return;
        }

        if (weekDays.find((item) => item.date === name)) {
            // Update or create
            weekDays = weekDays.map((item) => {
                if (item.date === name) {
                    return {
                        ...item,
                        hours: value
                            .replace(/[^\\,\d]/g, '')
                            .replace(',,', ','),
                    }; //deleting all symbols whish are not numbers or ,
                }

                return item;
            });
        } else {
            weekDays.push({
                date: name,
                hours: +value.replace(/[^\\.\d]/g, '').replace(',,', ','),
            });
        }

        const updatedData = {
            projects: data?.projects?.map((p) => {
                if (currentProject === p?.project) {
                    return { ...formValues, days: weekDays };
                } else {
                    return p;
                }
            }),
            newProjects: data?.newProjects?.map((p) => {
                if (currentProject === p?.project) {
                    return { ...formValues, days: weekDays };
                } else {
                    return p;
                }
            }),
        };

        setData(updatedData);

        if (!isSavingAllowed) {
            setIsSavingAllowed(true);
        }
    }

    function updateComment(e, dateString) {
        const updated = {
            projects: data?.projects?.map((p) => {
                if (currentProject === p?.project) {
                    return {
                        ...p,
                        days: p.days?.map((dayItem) => {
                            if (dayItem?.date === dateString) {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        comment: e.target.value,
                                    },
                                };
                            } else {
                                return dayItem;
                            }
                        }),
                    };
                } else {
                    return p;
                }
            }),
            newProjects: data?.newProjects?.map((p) => {
                if (currentProject === p?.project) {
                    return {
                        ...p,
                        days: p.days?.map((dayItem) => {
                            if (dayItem?.date === dateString) {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        comment: e.target.value,
                                    },
                                };
                            } else {
                                return dayItem;
                            }
                        }),
                    };
                } else {
                    return p;
                }
            }),
        };
        setData(updated);

        if (!isSavingAllowed) {
            setIsSavingAllowed(true);
        }
    }

    return (
        <>
            <div className="w-64 px-4">
                {isNew ? (
                    <Select
                        name={'project'}
                        value={projectOptions?.find(
                            (item) => item.value === formValues?.project
                        )}
                        options={projectOptions}
                        onChange={handleChangeProject}
                    />
                ) : (
                    <p>{currentProject}</p>
                )}
            </div>
            <div className="grid grid-cols-8 justify-between w-[512px] relative">
                {weekRange.map((day) => {
                    const dateString = day.format('YYYY-MM-DD');
                    const matched = formValues.days?.find(
                        (item) => item.date === dateString
                    );
                    totalHours +=
                        +matched?.hours.toString().replace(',', '.') || 0;

                    const currentComment = [
                        ...data?.projects,
                        ...data?.newProjects,
                    ]
                        ?.find((p) => p.project === currentProject)
                        ?.days?.find((d) => d?.date === dateString)?.comment;

                    return (
                        <>
                            <div
                                className="px-2 w-16 text-right flex flex-col items-center gap-1 relative"
                                key={day}
                            >
                                <input
                                    name={dateString}
                                    className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-14 "
                                    onChange={handleChangeHours}
                                    value={matched?.hours
                                        .toString()
                                        .replace('.', ',')}
                                    placeholder={0}
                                />
                            </div>
                            <Button
                                extraClasses={`row-start-2 p-1 flex items-center justify-end basic-transition ${
                                    currentComment?.isOpen
                                        ? 'text-gray-400'
                                        : 'text-gray-300 hover:text-gray-400'
                                } `}
                                onClick={() => {
                                    openComment(dateString);
                                }}
                            >
                                {currentComment?.isOpen ? (
                                    <BiCommentEdit size={22} />
                                ) : currentComment?.comment?.length ? (
                                    <BiCommentDetail size={22} />
                                ) : (
                                    <BiCommentAdd size={22} />
                                )}
                            </Button>
                            {currentComment?.isOpen ? (
                                <input
                                    name={dateString + 'comment'}
                                    className=" row-start-3 cols-span-7 place-self-start p-1 placeholder-gray-300 text-gray-700 bg-white
             rounded text-sm border border-gray-300 focus:border-gray-400 outline-none  text-left w-[512px]"
                                    onChange={(e) => {
                                        updateComment(e, dateString);
                                    }}
                                    value={currentComment.comment}
                                    placeholder={'Your comment...'}
                                />
                            ) : null}
                        </>
                    );
                })}
                <div className="px-4 w-16 text-right text-gray-400 flex items-center justify-end">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
        </>
    );
}

export default HoursPageTimesheetOverviewBodyRowEdit;
