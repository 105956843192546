import React, { useEffect, useState } from 'react';
import InputText from '../form/parts/InputText';
import { ArrowRight } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../form/parts/ErrorMessage';
import { loginWithPassword, checkLoginOption } from '../../api/auth/AuthAPI';
import renderLoginOptions from '../../helpers/auth/renderLoginOptions';
import useParamsQuery from '../../hooks/useParamsQuery';
import Button from '../shared/button';

function AuthLoginForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const query = useParamsQuery();

    const [showLoginBlock, setShowLoginBlock] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loginOption, setLoginOption] = useState(null);

    useEffect(() => {
        if (query.get('error')) {
            setShowLoginBlock(true);
            setErrorMessage(query.get('error'));
        }
        if (query.get('state') || query.get('id_token') || query.get('code')) {
            setShowLoginBlock(true);
        }
    }, [query]);

    function getLoginOption(email) {
        localStorage.getItem('email') !== email &&
            localStorage.removeItem('login_option');
        setIsLoading(true);
        checkLoginOption({ email: email })
            .then((response) => {
                if (response.status === 204) {
                    setLoginOption(null);
                    setIsLoading(false);
                    setShowLoginBlock(!showLoginBlock);
                } else if (response.status === 200) {
                    localStorage.setItem('email', email);
                    response.data.login_option &&
                        localStorage.setItem(
                            'login_option',
                            response.data.login_option
                        );
                    response.data.org_hash &&
                        localStorage.setItem('orgHash', response.data.org_hash);
                    response.data.org_hash &&
                        localStorage.setItem(
                            'baseOrgHash',
                            response.data.org_hash
                        );
                    setLoginOption(response.data.login_option);
                    setIsLoading(false);
                    setShowLoginBlock(!showLoginBlock);
                } else {
                    setErrorMessage('Invalid email');
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setErrorMessage(
                    error?.response?.data ||
                        error.message ||
                        'Oops! Something went wrong!'
                );
                setIsLoading(false);
            });
    }

    // Try login with password?
    function tryLoginWithPassword(values, setSubmittingFinished) {
        setIsLoading(true);
        loginWithPassword({
            email: values.email,
            password: values.password,
        })
            .then((response) => {
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem(
                    'refreshToken',
                    response.data.refreshToken
                );
                setSubmittingFinished();
                navigate('/');
            })
            .catch((error) => {
                setSubmittingFinished();
                if (error?.response?.status == 400) {
                    setErrorMessage('Invalid login credentials');
                } else {
                    setErrorMessage('Oops! Something went wrong!');
                }
            });
        setIsLoading(false);
    }

    function toggleShowLoginBlock() {
        setShowLoginBlock(!showLoginBlock);
        setErrorMessage('');
    }

    return (
        <div>
            <p className="text-center mb-20 font-display text-6xl text-gray-700 font-bold ">
                Login
            </p>
            {errorMessage && (
                <div className="rounded bg-red-200 text-red-600 px-2 py-2 mb-8">
                    {errorMessage}
                </div>
            )}

            <Formik
                initialValues={{
                    email: localStorage.getItem('email'),
                    password: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('common.form.invalid_email_address'))
                        .required(t('common.form.required')),
                    password: Yup.string()
                        .min(6, t('common.form.too_short'))
                        .required(t('common.form.required')),
                })}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    tryLoginWithPassword(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, errors, touched }) => {
                    const isInvalidEmail = touched.email && errors.email;

                    return (
                        <Form>
                            <div className="mt-5 mb-3 flex flex-col items-stretch w-full sm:w-80">
                                {showLoginBlock ? (
                                    <>
                                        <Button
                                            onClick={toggleShowLoginBlock}
                                            variant="authTransparentGray"
                                            extraClasses={
                                                'px-2 py-2 text-gray-500 text-lg flex gap-x-2 mb-4 hover:bg-gray-100 rounded '
                                            }
                                        >
                                            <ArrowRight
                                                fill={'#7F8992'}
                                                className="transform rotate-180 mt-1.5"
                                            />
                                            {t('login_page.button.back')}
                                        </Button>

                                        {renderLoginOptions(
                                            'login',
                                            loginOption,
                                            isSubmitting,
                                            errors,
                                            values,
                                            setErrorMessage
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <InputText
                                            type="email"
                                            name={'email'}
                                            placeholder={t(
                                                'login_page.form.placeholder.email'
                                            )}
                                            className={'text-lg'}
                                        />
                                        <ErrorMessage className={'mt-1'}>
                                            {t(errors.email)}
                                        </ErrorMessage>
                                    </>
                                )}
                            </div>

                            <div className="flex justify-between flex-wrap">
                                {showLoginBlock ? (
                                    <></>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            getLoginOption(values.email)
                                        }
                                        extraClasses={
                                            'w-full flex space-x-4 justify-center pl-2 py-2 items-center text-lg'
                                        }
                                        isLoading={isLoading}
                                        disabled={
                                            !values.email || isInvalidEmail
                                        }
                                        variant="auth"
                                    >
                                        <span className={'mr-auto'}>
                                            {t('login_page.button.continue')}
                                        </span>
                                        <ArrowRight fill={'#ffffff'} />
                                    </Button>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default AuthLoginForm;
