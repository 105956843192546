import React from 'react';
import Button from '../../../../../../components/shared/button';
import { BiCommentDetail } from 'react-icons/bi';

function HoursPageTimesheetOverviewBodyRowView({
    project,
    days,
    weekRange,
    openComment,
}) {
    let totalHours = 0;
    return (
        <>
            <div className="w-64 px-4">{project}</div>
            <div className="grid grid-cols-8 justify-between w-[512px] relative gap-y-2">
                {weekRange.map((day, index) => {
                    const matched = days?.find(
                        (item) => item.date === day.format('YYYY-MM-DD')
                    );
                    totalHours += matched?.hours || 0;
                    const dateString = day.format('YYYY-MM-DD');
                    const currentComment = days?.find(
                        (d) => d?.date === dateString
                    )?.comment;

                    return (
                        <>
                            <div
                                key={index}
                                className="px-4 w-16  flex gap-1 items-center"
                            >
                                <p className="text-right ">
                                    {matched?.hours
                                        .toString()
                                        .replace('.', ',') || 0}
                                </p>
                                <Button
                                    extraClasses={`row-start-2  flex items-center justify-end basic-transition shadow-none ${
                                        currentComment?.isOpen
                                            ? 'text-gray-500'
                                            : 'text-gray-300 hover:text-gray-500'
                                    } `}
                                    onClick={() => {
                                        openComment(dateString);
                                    }}
                                >
                                    {
                                        currentComment?.comment?.length ? (
                                            <BiCommentDetail size={18} />
                                        ) : null
                                        // <BiComment size={18} />
                                    }
                                </Button>
                            </div>
                            {days?.find((day) => day?.comment?.isOpen) ? (
                                <p
                                    className={`row-start-3 cols-span-7 place-self-start col-start-1 p-1 ${
                                        currentComment?.isOpen
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    } text-gray-500 bg-white basic-transition
             rounded text-sm border border-solid border-gray-300   text-left w-[512px]`}
                                >
                                    {currentComment?.comment
                                        ? currentComment?.comment
                                        : 'No comment added yet.'}
                                </p>
                            ) : null}
                        </>
                    );
                })}
                <div className="px-4 w-16 text-right text-gray-400 ">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
        </>
    );
}

export default HoursPageTimesheetOverviewBodyRowView;
