import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../button';

const DeleteContent = ({
    children,
    handleAction,
    setModalState,
    disabledDelete = false,
    onCancel = () => {},
    redBtnText = null,
    closeBtnText = null,
}) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        onCancel();
        setModalState(false);
    };

    const handleDelete = _.debounce(() => {
        handleAction();
        setModalState(false);
    }, 1000);

    return (
        <div className="flex flex-col  py-4 h-full">
            {children}
            <div className="ml-auto mt-auto flex gap-x-4">
                <Button
                    variant="gray"
                    extraClasses={'min-w-[110px]'}
                    onClick={handleChangeModalState}
                >
                    {closeBtnText ? closeBtnText : t('button.close')}
                </Button>
                <Button
                    variant="red"
                    extraClasses={'min-w-[110px]'}
                    onClick={handleDelete}
                    disabled={disabledDelete}
                >
                    {redBtnText ? redBtnText : t('button.remove')}
                </Button>
            </div>
        </div>
    );
};

export default DeleteContent;
