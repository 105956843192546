import React, { useEffect, useState } from 'react';
import HoursPageTimesheetOverviewHeader from './header';
import HoursPageTimesheetOverviewBody from './body';
import HoursPageTimesheetOverviewFooter from './footer';

function HoursPageTimesheetOverview({
    data,
    isEditMode,
    setIsEditMode,
    weekRange,
    selectedProjects,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
    setDisableAddingRow,
}) {
    const [tableData, setTableData] = useState(data);
    const [isSavingAllowed, setIsSavingAllowed] = useState(false);

    useEffect(() => {
        setTableData({
            projects: data?.projects,
            newProjects:
                data?.newProjects?.length > 1
                    ? [data?.newProjects[0], ...tableData?.newProjects]
                    : data?.newProjects,
        });
    }, [data]);

    useEffect(() => {
        if (!tableData?.newProjects?.find((p) => !p?.project)) {
            setDisableAddingRow(false);
        }
    }, [tableData]);

    function resetTableData() {
        setTableData(data);
        setIsSavingAllowed(false);
        setIsEditMode(false);
        setEmptyRow([]);
    }

    return (
        <div>
            <HoursPageTimesheetOverviewHeader weekRange={weekRange} />
            <HoursPageTimesheetOverviewBody
                data={tableData}
                setData={setTableData}
                weekRange={weekRange}
                triggerUpdate={triggerUpdate}
                selectedProjects={selectedProjects}
                setEmptyRow={setEmptyRow}
                userHash={userHash}
                viewerHash={viewerHash}
                filterDate={filterDate}
                isEditMode={isEditMode}
                isSavingAllowed={isSavingAllowed}
                setIsSavingAllowed={setIsSavingAllowed}
            />
            {data?.projects.length || data?.newProjects.length ? (
                <HoursPageTimesheetOverviewFooter
                    weekRange={weekRange}
                    data={tableData}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    resetTableData={resetTableData}
                    isSavingAllowed={isSavingAllowed}
                    userHash={userHash}
                    setEmptyRow={setEmptyRow}
                    triggerUpdate={triggerUpdate}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export default HoursPageTimesheetOverview;
