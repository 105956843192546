import HoursPageProjects from '../projects';
import HoursPageTimesheet from '../timesheet';
import { useTranslation } from 'react-i18next';

export default function RegisterTab() {
    const { t } = useTranslation();

    return (
        <>
            <HoursPageTimesheet />
            <div className="flex flex-wrap lg:flex-nowrap lg:space-x-8">
                <HoursPageProjects type="organisation" />
                <HoursPageProjects type="private" />
            </div>
        </>
    );
}
