import React from 'react';
import InputText from '../form/parts/InputText';
import InputPassword from '../form/parts/InputPassword';
import ErrorMessage from '../../components/form/parts/ErrorMessage';
import { ArrowRight } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import Button from '../shared/button';

function AuthPasswordFormFields({ endpoint, values, errors, isSubmitting }) {
    const { t } = useTranslation();

    function renderInputs() {
        switch (endpoint) {
            case 'login':
                return (
                    <>
                        <InputText
                            type="email"
                            name={'email'}
                            placeholder={t('login_page.form.placeholder.email')}
                            className={'mb-2 text-lg'}
                            value={values?.email ? values?.email : ''}
                        />
                        <InputPassword
                            name={'password'}
                            placeholder={t(
                                'login_page.form.placeholder.password'
                            )}
                            className={'text-lg'}
                        />
                    </>
                );
            case 'invite':
                return (
                    <>
                        <div className="flex gap-x-4 items-stretch justify-stretch">
                            <div>
                                <InputText
                                    type="text"
                                    name={'first_name'}
                                    placeholder={'First name'}
                                    className={'mb-2 text-lg'}
                                    value={
                                        values?.first_name
                                            ? values?.first_name
                                            : ''
                                    }
                                />
                            </div>
                            <div>
                                <InputText
                                    type="text"
                                    name={'last_name'}
                                    placeholder={'Last name'}
                                    className={'mb-2  text-lg '}
                                    value={
                                        values?.last_name
                                            ? values?.last_name
                                            : ''
                                    }
                                />
                            </div>
                        </div>
                        <InputText
                            type="email"
                            name={'email'}
                            placeholder={t('login_page.form.placeholder.email')}
                            disabled={true}
                            className={'mb-2 text-lg'}
                            value={values?.email ? values?.email : ''}
                        />
                    </>
                );
            case 'signup':
            case 'trial':
            case 'explore':
            case 'demo':
            default:
                return (
                    <>
                        <div className="flex gap-x-4 items-stretch justify-stretch">
                            <div>
                                <InputText
                                    type="text"
                                    name={'first_name'}
                                    placeholder={'First name'}
                                    className={'mb-2 text-lg'}
                                    value={
                                        values?.first_name
                                            ? values?.first_name
                                            : ''
                                    }
                                />
                            </div>
                            <div>
                                <InputText
                                    type="text"
                                    name={'last_name'}
                                    placeholder={'Last name'}
                                    className={'mb-2  text-lg '}
                                    value={
                                        values?.last_name
                                            ? values?.last_name
                                            : ''
                                    }
                                />
                            </div>
                        </div>
                        <InputText
                            type="email"
                            name={'email'}
                            placeholder={t('login_page.form.placeholder.email')}
                            className={'mb-2 text-lg'}
                            value={values?.email ? values?.email : ''}
                        />
                    </>
                );
        }
    }

    return (
        <>
            {renderInputs()}

            <ErrorMessage>{t(errors?.password)}</ErrorMessage>

            <Button
                extraClasses={
                    'mt-4 w-full flex space-x-4 justify-center py-2 items-center text-lg px-2'
                }
                isLoading={isSubmitting}
                type={'submit'}
                variant="auth"
            >
                <span className={'mr-auto'}>
                    {endpoint === 'login'
                        ? t('login_page.button.login')
                        : t('signup_page.button.signup') + ' with password'}
                </span>
                <ArrowRight fill={'#ffffff'} />
            </Button>
        </>
    );
}

export default AuthPasswordFormFields;
