import React, { useMemo } from 'react';
import { useContext } from 'react';
import {
    ProjectsContext,
    PrivateProjectsContext,
} from '../../../../context/ProjectsContext';
import TableEditable from '../../../../components/table/editable';
import { useTranslation } from 'react-i18next';

function ProjectsTableDefault({ data, updateProjects, deleteProject, type }) {
    const { t } = useTranslation();

    const { projectsState } = useContext(
        type === 'private' ? PrivateProjectsContext : ProjectsContext
    );

    const columns = useMemo(
        () => [
            {
                Header: t('hours_page.organisation_projects.project_name'),
                accessor: 'name',
                width: 200,
            },
            {
                Header: t('hours_page.organisation_projects.description'),
                accessor: 'description',
                width: '',
            },
        ],
        []
    );

    function validateForm(selectedRow) {
        let errorMessages = {};

        if (!selectedRow.values.name?.length) {
            errorMessages = { ...errorMessages, name: 'Required' };
        }

        if (
            selectedRow.values.name &&
            selectedRow.values.name !== selectedRow.original.name &&
            !checkIfProjectNameIsUnique(selectedRow.values.name)
        ) {
            errorMessages = { ...errorMessages, name: 'Must be unique' };
        }

        return errorMessages;
    }

    function checkIfProjectNameIsUnique(value) {
        return (
            [...projectsState.data].find(
                (item) => item.name.toLowerCase() === value.toLowerCase()
            ) === undefined
        );
    }

    return (
        <TableEditable
            data={data}
            columns={columns}
            validateForm={validateForm}
            updateProjects={updateProjects}
            deleteProject={deleteProject}
            type={type}
        />
    );
}

export default ProjectsTableDefault;
