import React from 'react';
import Rodal from 'rodal';
import { RxCross2 } from 'react-icons/rx';
import Button from '../../button';

const ModalWrapper = ({
    modalState,
    setModalState,
    width = 600,
    height = 300,
    additionalStylesObject = {},
    title = 'Modal',
    children,
    additionalOnCloseFunc = () => {},
    isFit,
}) => {
    const handleChangeModalState = () => {
        setModalState(false);
        additionalOnCloseFunc();
    };

    if (isFit) {
        return (
            <Rodal
                visible={modalState}
                width={width}
                showCloseButton={false}
                closeOnEsc
                customStyles={{
                    padding: 0,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    ...additionalStylesObject,
                    color: '#fff',
                    height: 'fit-content',
                }}
                onClose={handleChangeModalState}
            >
                <div className="flex items-center bg-theme-tertiary-dark rounded-t-xl  py-2 px-7 gap-2">
                    <h3
                        className={`flex-grow font-display font-normal text-2xl mb-0 text-white `}
                    >
                        {title}
                    </h3>
                    <Button
                        extraClasses="text-white w-6 h-full flex justify-center items-center cursor-pointer"
                        onClick={handleChangeModalState}
                    >
                        <RxCross2 size={16} color="#fff" />
                    </Button>
                </div>
                <div className="flex-grow h-fit px-7 py-2 flex flex-col ">
                    {children}
                </div>
            </Rodal>
        );
    }

    return (
        <Rodal
            visible={modalState}
            width={width}
            height={height}
            showCloseButton={false}
            closeOnEsc
            customStyles={{
                padding: 0,
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                ...additionalStylesObject,
                color: '#fff',
            }}
            onClose={handleChangeModalState}
        >
            <div className="flex items-center bg-theme-tertiary-dark rounded-t-xl  py-2 px-7 gap-2">
                <h3
                    className={`flex-grow font-display font-normal text-2xl mb-0 text-white `}
                >
                    {title}
                </h3>
                <Button
                    extraClasses="text-white w-6 h-full flex justify-center items-center cursor-pointer"
                    onClick={handleChangeModalState}
                >
                    <RxCross2 size={16} color="#fff" />
                </Button>
            </div>
            <div className="flex-grow px-7 py-2 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                {children}
            </div>
        </Rodal>
    );
};

export default ModalWrapper;
