import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Loader from '../../../loader';

function SelectCell({ value, row }) {
    const [loading, setLoading] = useState(false);
    const [currentOption, setCurrentOption] = useState(value?.value);

    useEffect(() => {
        setCurrentOption(value?.value);
    }, [value]);

    const customStylesSelect = {
        placeholder: (provided, state) => ({
            ...provided,
            color: '#fff',
            opacity: '1',
        }),
        container: (provided, state) => ({
            ...provided,
            background:
                currentOption?.value === 'solve' ? '#15A2BB' : '#FF6384',
            width: '100px',
            borderRadius: '4px',
            display: 'inline-block',
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            boxShadow: 'transparent',
            background: 'transparent',
            minHeight: '24px',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),

        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0px 2px',
            opacity: '1',
            cursor: 'pointer',
            color: '#fff',
            '&:hover': { color: '#fff' },
        }),

        singleValue: (provided, state) => ({
            ...provided,
            margin: '0',
            padding: '1px',
            color: '#fff',
            fontSize: '12px',
            lineHeight: '24px',
            maxWidth: '100%',
            textTransform: 'uppercase',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
        }),
        menuList: (provided, state) => ({
            padding: ' 0',
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: '0 0 0 -1px',
            width: 'calc(100%)',
            borderRadius: '4px',
            border:
                currentOption?.value === 'solve'
                    ? '1px solid rgba(21, 162, 187, 0.5)'
                    : ' 1px solid rgba(255, 99, 132, 0.5)',
        }),
        option: (provided, state) => {
            return {
                ...provided,
                background: '#fff',
                '&:hover': {
                    background:
                        currentOption?.value === 'solve'
                            ? 'rgba(21, 162, 187, 0.5)'
                            : 'rgba(255, 99, 132, 0.5)',
                    color: '#fff',
                },
                margin: '0',
                padding: ' 0',
                cursor: 'pointer',
                minHeight: '24px',
                color: currentOption?.value === 'solve' ? '#15A2BB' : '#FF6384',
                fontSize: '12px',
                lineHeight: '24px',
                maxWidth: '100%',
                textTransform: 'uppercase',
            };
        },
    };

    async function handleChange(option) {
        setLoading(true);
        await value?.onSelectChange(option, row);
        setCurrentOption(option);
        setLoading(false);
    }

    return (
        <div
            className={`col-span-1 text-center select-cell p-0 flex  items-center`}
        >
            {!loading ? (
                <Select
                    options={value?.selectOptions}
                    isSearchable={false}
                    placeholder="Action"
                    styles={customStylesSelect}
                    defaultValue={currentOption}
                    onChange={(val) => handleChange(val)}
                />
            ) : (
                <div className="m-auto">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            )}
        </div>
    );
}

export default SelectCell;
