import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';
import Button from '../shared/button';

function DefaultBillingPlanContent({
    planData,
    isYearly,
    permissionForWrite,
    selectedPlan,
    setConfiguredPlan,
    orgData,
}) {
    const { t } = useTranslation();
    const {
        name,
        value,
        description,
        priceText,
        translationKey,
        eurosPerMonth,
        featuresPlain,
        featuresListItems,
        eurosPerMonthYearly,
        eurosPerMonthMonthly,
    } = planData;

    const [showDescriptions, setShowDescriptions] = useState({});

    useEffect(() => {
        let planDescriptions = featuresListItems.reduce((acc, feature) => {
            const updatedAcc = {
                ...acc,
                [feature.name]: {
                    description: feature.description || null,
                    isShowing: false,
                },
            };
            return updatedAcc;
        }, {});

        setShowDescriptions(planDescriptions);
    }, []);

    function handleChangeDescriptionStatus(featureName) {
        const updated = {
            ...showDescriptions,
            [featureName]: {
                description: showDescriptions[featureName].description,
                isShowing: !showDescriptions[featureName].isShowing,
            },
        };

        setShowDescriptions(updated);
    }

    return (
        <>
            {' '}
            <div className="py-3 px-6 border-b border-b-1 border-custom-gray-400  w-full bg-white rounded-t-lg">
                <div className="mb-4">
                    <h3
                        className={`${
                            description ? 'mb-2' : 'mb-0'
                        } font-display font-extrabold text-28 text-gray-700`}
                    >
                        {name}
                    </h3>
                    {description && <p>{description}</p>}
                </div>
                <div className=" h-12">
                    {eurosPerMonthYearly && eurosPerMonthMonthly ? (
                        <p className=" flex gap-x-1 items-end">
                            <span className="text-sm font-display text-gray-700 font-bold">
                                from
                            </span>{' '}
                            <span className="font-display text-gray-700 text-4xl font-bold">
                                {`€${
                                    isYearly
                                        ? eurosPerMonthYearly
                                        : eurosPerMonthMonthly
                                }`}
                            </span>{' '}
                            <span className="text-sm flex flex-col">
                                <span className="font-display text-gray-700 font-bold  leading-0">
                                    {t('settings_page.org_tab.month')}
                                </span>
                            </span>
                        </p>
                    ) : value === 'trial' ? (
                        <p className="font-display font-light text-28 text-gray-700">
                            {translationKey
                                ? capitalizeFirstLetter(t(translationKey))
                                : priceText}
                            <span className="text-sm font-display font-light text-gray-500">
                                {' '}
                                {t('settings_page.org_tab.expires')}{' '}
                                {moment
                                    .unix(orgData.created_date + 5184000)
                                    .format('DD.MM.YY')}
                            </span>
                        </p>
                    ) : (
                        <p className="font-display font-light text-28 text-gray-700">
                            {translationKey
                                ? capitalizeFirstLetter(t(translationKey))
                                : priceText}
                        </p>
                    )}
                </div>

                {permissionForWrite ? (
                    value === 'enterprise-plus' && value !== selectedPlan ? (
                        <a
                            href="https://www.agileanalytics.cloud/contact-sales?pricing=enterprise-plus"
                            className={` block text-tertiary-300 btn w-full rounded border-2 border-tertiary-300 transform hover:bg-tertiary-300 hover:text-white shadow-lg text-center`}
                            target="_blank"
                        >
                            Contact us
                        </a>
                    ) : (
                        <Button
                            extraClasses={`${
                                selectedPlan === value
                                    ? 'bg-tertiary-300 text-white'
                                    : 'text-tertiary-300'
                            } btn w-full rounded border-2 border-tertiary-300 transform hover:bg-tertiary-300 hover:text-white shadow-lg`}
                            onClick={(e) => {
                                e.preventDefault();
                                setConfiguredPlan(value);
                            }}
                        >
                            {selectedPlan === value
                                ? capitalizeFirstLetter(
                                      t('settings_page.org_tab.chosen_plan')
                                  )
                                : capitalizeFirstLetter(
                                      t('settings_page.org_tab.switch_plan')
                                  )}
                        </Button>
                    )
                ) : (
                    <p
                        className={`h-8 ${
                            selectedPlan === value
                                ? 'bg-tertiary-300 text-white rounded  flex justify-center items-center'
                                : ''
                        }`}
                    >
                        {selectedPlan === value
                            ? capitalizeFirstLetter(
                                  t('settings_page.org_tab.chosen_plan')
                              )
                            : ''}
                    </p>
                )}
            </div>
            <div className="flex-1 py-3 px-6 bg-bg-gray-400 rounded-b-lg flex flex-col justify-between">
                <div className="flex-1">
                    <p className="uppercase font-semibold text-gray-700 mb-2">
                        {t('settings_page.org_tab.included').toUpperCase()}
                    </p>

                    <ul>
                        {featuresPlain.map((feature, index) => {
                            switch (feature.type) {
                                case 'department size':
                                    return (
                                        <li
                                            className="mb-2 text-left"
                                            key={index}
                                        >
                                            {capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.max_department'
                                                )
                                            )}{' '}
                                            <span className="font-bold">
                                                {feature.amount}
                                            </span>{' '}
                                            {t(
                                                'settings_page.org_tab.engineers'
                                            )}
                                        </li>
                                    );
                                case 'retention':
                                    return (
                                        <li
                                            className="mb-2 text-left"
                                            key={index}
                                        >
                                            {capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.retention'
                                                )
                                            )}{' '}
                                            <span className="font-bold">
                                                {feature.amount[0]}
                                            </span>{' '}
                                            <span className="font-bold">
                                                {feature.amount[1] === 'days'
                                                    ? t(
                                                          'settings_page.org_tab.days'
                                                      )
                                                    : t(
                                                          'settings_page.org_tab.months'
                                                      )}
                                            </span>{' '}
                                        </li>
                                    );
                                default:
                                    return (
                                        <li
                                            className="mb-2 text-left"
                                            key={index}
                                        >
                                            {feature.text
                                                .split(' ')
                                                .map((word, ind) => {
                                                    const isBold =
                                                        feature.boldWords.find(
                                                            (boldWord) =>
                                                                boldWord.trim() ===
                                                                word
                                                        );
                                                    if (isBold) {
                                                        return (
                                                            <span
                                                                className="font-semibold"
                                                                key={ind}
                                                            >
                                                                {isBold + ' '}
                                                            </span>
                                                        );
                                                    }
                                                    return word + ' ';
                                                })}
                                        </li>
                                    );
                            }
                        })}
                        {featuresListItems.map((feature, index) => (
                            <li className="mt-4" key={index}>
                                <div className="flex justify-between gap-x-2 items-center">
                                    <div className="flex gap-x-2 items-center">
                                        <div className="w-5">
                                            <FaCheck
                                                fill={'#81BC42'}
                                                size={18}
                                            />
                                        </div>

                                        <p>
                                            {feature.translationKey
                                                ? capitalizeEveryWordFirstLetter(
                                                      t(feature.translationKey)
                                                  )
                                                : feature.name}
                                        </p>
                                    </div>
                                    {/* TODO:  */}
                                    <div
                                        className="flex-none rounded-full w-5 h-5 bg-gray-500 flex justify-center items-center cursor-pointer"
                                        onClick={() =>
                                            handleChangeDescriptionStatus(
                                                feature.name
                                            )
                                        }
                                    >
                                        <p className="text-white font-semibold font-display p-0 m-0">
                                            i
                                        </p>
                                    </div>
                                </div>
                                {showDescriptions[feature.name]?.isShowing && (
                                    <div className="text-left mt-1 rounded bg-white p-2 shadow-md text-sm overflow-hidden">
                                        {feature.description ||
                                            'No description'}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default DefaultBillingPlanContent;
