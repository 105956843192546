import { useState } from 'react';
import DeleteContent from '../shared/modals/delete-content';

const ModalDeleteOrg = ({ setModalState, sendRequest, orgName }) => {
    const [isDeletingAllowed, setIsDeletingAllowed] = useState(false);

    const handleDelete = () => {
        isDeletingAllowed && sendRequest();
        isDeletingAllowed && setModalState(false);
    };

    function handleInputChange(value) {
        setIsDeletingAllowed(value.toLowerCase() === 'delete');
    }

    return (
        <DeleteContent
            setModalState={setModalState}
            handleAction={handleDelete}
            disabledDelete={!isDeletingAllowed}
        >
            <div className="flex flex-col pb-2 pt-5 h-fit text-gray-500 gap-4">
                <p className="text-xl text-gray-500">
                    Are you sure you want to delete{' '}
                    <span className="italic">{orgName}</span>?
                </p>
                <div>
                    <label htmlFor="delete">{'Type "DELETE" to proceed'}</label>
                    <input
                        id="delete"
                        type="text"
                        title={'Awaits for delete'}
                        className=" mt-2 border-solid border-2 border-gray-300 rounded-md px-2 py-1 w-full focus:outline-none focus:border-theme-tertiary disabled:cursor-not-allowed disabled:bg-gray-200"
                        onChange={(e) => handleInputChange(e.target.value)}
                    />
                    {isDeletingAllowed ? (
                        <p className="text-red-text text-sm h-6"></p>
                    ) : (
                        <p className="text-red-text text-sm h-6">
                            Type "DELETE" to proceed
                        </p>
                    )}
                </div>
            </div>
        </DeleteContent>
    );
};

export default ModalDeleteOrg;
