import React from 'react';
import { toast } from 'react-toastify';
import 'rodal/lib/rodal.css';
import debounce from 'lodash/debounce';
import Button from '../shared/button';

const SIConfirmationModalContent = ({
    modalTextBody,
    successNotification,
    request,
    isDisabledActionBtn = false,
    ModalExtraBody = () => null,
    mainColorClass = 'red',
    actionTextBtn = 'Ok',
    setModalState,
}) => {
    const successNotify = (message) => toast.success(message);
    const errorNotify = (message) => toast.error(message);

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleTrainTask = debounce(() => {
        if (typeof request !== 'function') {
            throw Error('Request is not a function');
        }
        request()
            .then((response) => {
                if (response.status === 200) {
                    successNotify(successNotification);
                    setModalState(false);
                }
                if (Array.isArray(response)) {
                    successNotify(successNotification);
                    setModalState(false);
                }
            })
            .catch((error) => {
                errorNotify(`Error: ${error.message}`);
            });
    }, 1000);

    return (
        <div className="flex flex-col  pb-4 pt-4 gap-4 h-full">
            <p className="text-xl text-gray-500">{modalTextBody}</p>
            <ModalExtraBody />
            <div className="ml-auto mt-auto flex gap-4">
                <Button
                    variant="gray"
                    extraClasses="min-w-[110px]"
                    onClick={handleChangeModalState}
                >
                    Cancel
                </Button>

                <Button
                    variant="blue"
                    extraClasses="min-w-[110px] font-medium font-sans text-lg rounded-lg"
                    onClick={() => handleTrainTask()}
                    disabled={isDisabledActionBtn}
                >
                    {actionTextBtn}
                </Button>
            </div>
        </div>
    );
};

export default SIConfirmationModalContent;
