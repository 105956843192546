import React, { useContext, useEffect, useState } from 'react';
import HoursPageTimesheetOverviewBodyRowView from './View';
import HoursPageTimesheetOverviewBodyRowEdit from './Edit';
import HoursPageTimesheetOverviewBodyActions from './ActionButtons';
import { getWritePermission } from '../../../../../../helpers/getPermission';
import { OrganisationsContext } from '../../../../../../context/OrganisationsContext';

function HoursPageTimesheetOverviewBodyRow({
    data,
    setData,
    isEditMode,
    project,
    selectedProjects,
    days,
    weekRange,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
    isSavingAllowed,
    setIsSavingAllowed,
    isNew,
}) {
    const { organisationsState } = useContext(OrganisationsContext);
    const [showEdit, setShowEdit] = useState(!project?.length);

    const [formValues, setFormValues] = useState(
        [...data?.projects, ...data?.newProjects]?.find(
            (item) => item?.project === project
        )
    );

    useEffect(() => {
        setFormValues(
            isNew
                ? data?.newProjects?.find((item) => item?.project === project)
                : data?.projects?.find((item) => item?.project === project)
        );
    }, [data]);

    function toggleShowEdit() {
        setShowEdit((prevState) => !prevState);
    }

    function openComment(dateString) {
        const updated = {
            projects: data?.projects?.map((p) => {
                if (project === p?.project) {
                    return {
                        ...p,
                        days: p.days?.map((dayItem) => {
                            if (dayItem?.date === dateString) {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        isOpen: !dayItem.comment?.isOpen,
                                    },
                                };
                            } else {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        isOpen: false,
                                    },
                                };
                            }
                        }),
                    };
                } else {
                    return p;
                }
            }),
            newProjects: data?.newProjects?.map((p) => {
                if (project === p?.project) {
                    return {
                        ...p,
                        days: p.days?.map((dayItem) => {
                            if (dayItem?.date === dateString) {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        isOpen: !dayItem.comment?.isOpen,
                                    },
                                };
                            } else {
                                return {
                                    ...dayItem,
                                    comment: {
                                        ...dayItem?.comment,
                                        isOpen: false,
                                    },
                                };
                            }
                        }),
                    };
                } else {
                    return p;
                }
            }),
        };
        setData(updated);
    }

    return (
        <div className="flex justify-between border-b border-solid border-gray-300 py-3 bg-gray-100 odd:bg-white">
            {isEditMode ? (
                <HoursPageTimesheetOverviewBodyRowEdit
                    data={data}
                    setData={setData}
                    weekRange={weekRange}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    selectedProjects={selectedProjects}
                    currentProject={project}
                    userHash={userHash}
                    viewerHash={viewerHash}
                    isSavingAllowed={isSavingAllowed}
                    setIsSavingAllowed={setIsSavingAllowed}
                    isNew={isNew}
                    openComment={openComment}
                />
            ) : (
                <HoursPageTimesheetOverviewBodyRowView
                    project={project}
                    days={days}
                    weekRange={weekRange}
                    setData={setData}
                    openComment={openComment}
                />
            )}

            {getWritePermission(organisationsState?.data) ? (
                <HoursPageTimesheetOverviewBodyActions
                    isEditMode={isEditMode}
                    project={project}
                    triggerUpdate={triggerUpdate}
                    filterDate={filterDate}
                    userHash={userHash}
                    isNew={isNew}
                    data={data}
                    setData={setData}
                />
            ) : (
                <div className="w-48"></div>
            )}
        </div>
    );
}

export default HoursPageTimesheetOverviewBodyRow;
