import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JiraIcon, AzureDevOpsIcon } from '../../assets/svg';
import WorkspaceAuthorization from '../workspace-authorization';
import WorkspaceWebhook from '../workspace-webhook';
import WorkspaceProgressBar from '../workspace-progress-bar';
import Loader from '../loader';
import { checkJiraWebhooks } from '../../api/settings/sprintInsightsAPI';
import showNotification from '../../helpers/showNotification';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import ModalWrapper from '../shared/modals/modal-wrapper';
import Button from '../shared/button';
import DeleteContent from '../shared/modals/delete-content';
import SaveButton from '../shared/save-button';

const WorkspaceItem = ({
    workspace,
    onDelete,
    isInOrg = true,
    loading = false,
    permissionForWrite,
}) => {
    const { t } = useTranslation();
    const [name, setName] = useState(null);
    const [source, setSource] = useState(null);
    const [authorizedUser, setAuthorizedUser] = useState(null);
    const [webhookId, setWebhookId] = useState(false);
    const [error, setError] = useState('');
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [isWebhookCreated, setIsWebhookCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTesting, setIsTesting] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        workspace.error && setIsLoading(false);
        workspace.error && setError(workspace.error);
        setName(workspace.name);
        setSource(workspace.source);
        setAuthorizedUser(workspace.modified_user);
        setWebhookId(workspace.webhook);
    }, [workspace]);

    useEffect(() => {
        authorizedUser && setIsAuthorised(true);
        webhookId && setIsWebhookCreated(true);
    }, [authorizedUser, webhookId]);

    const handleDeleteClick = () => {
        if (!authorizedUser) {
            setIsLoading(true);
            onDelete(source, name, null);
            return;
        }
        setIsDeleteModalOpen(true);
    };

    async function handleTestConnection() {
        setIsTesting(true);
        try {
            const res = await checkJiraWebhooks(workspace.modified_user.hash);
            setIsTesting(false);
            if (res.data === 'OK') {
                showNotification('Connection works', 'success');
                return;
            } else {
                setIsAuthorised(null);
                setIsWebhookCreated(null);
                setError(res.data);
                showNotification(res.data);
            }
        } catch (err) {
            setIsTesting(false);
            showNotification(err.response?.data || err.message);
        }
    }

    return (
        <li className="w-480 h-300 bg-white rounded-lg shadow-workspace-item px-4 pt-4 px-2">
            <div className="flex items-center gap-x-2 mb-6">
                {source === 'jira'
                    ? isWebhookCreated && <JiraIcon width={24} height={24} />
                    : !isWebhookCreated && (
                          <div className="filter grayscale">
                              <AzureDevOpsIcon width={24} height={24} />
                          </div>
                      )}
                {source === 'azure'
                    ? isWebhookCreated && (
                          <AzureDevOpsIcon width={24} height={24} />
                      )
                    : !isWebhookCreated && (
                          <div className="filter grayscale">
                              <JiraIcon width={24} height={24} />
                          </div>
                      )}
                {isAuthorised && (
                    <p className="font-display text-xl leading-6">
                        {capitalizeFirstLetter(
                            t('settings_page.si_tab.connected')
                        )}{' '}
                        <span className="font-bold font-display">{name}</span>
                    </p>
                )}
            </div>
            {isTesting ? (
                <div className="w-full flex justify-center items-center my-8">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            ) : (
                <WorkspaceProgressBar
                    service={source}
                    isServiceAuthorised={error ? null : isAuthorised}
                    isServiceWebhooksAdded={error ? null : isWebhookCreated}
                />
            )}
            <div className="flex justify-between divide-solid divide-x divide-gray-divide mb-4">
                <WorkspaceAuthorization
                    workspace={workspace}
                    error={error}
                    isInOrg={isInOrg}
                    loading={loading}
                    permissionForWrite={permissionForWrite}
                />
                <WorkspaceWebhook
                    workspace={workspace}
                    onCreate={(id) => setWebhookId(id)}
                    isInOrg={isInOrg}
                    loading={loading}
                    permissionForWrite={permissionForWrite}
                />
            </div>
            {permissionForWrite ? (
                <div
                    className={`flex ${
                        isInOrg || loading ? 'justify-between' : 'justify-end'
                    } items-center`}
                >
                    {loading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : isInOrg ? (
                        isLoading ? (
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        ) : (
                            <Button
                                onClick={handleDeleteClick}
                                variant="redText"
                            >
                                {capitalizeFirstLetter(
                                    t('settings_page.si_tab.remove')
                                )}
                            </Button>
                        )
                    ) : null}
                    <SaveButton
                        saveFunc={handleTestConnection}
                        text={capitalizeFirstLetter(
                            t('settings_page.si_tab.test')
                        )}
                        style={{
                            margin: '0',
                            color: '#fff',
                            border: 'none',
                            backgroundColor: '#1FB8D3',
                            fontFamily: `${['Exo\\ 2', 'sans-serif', 'Arial']}`,
                            fontWeight: '600',
                            lineHeight: '17px',
                        }}
                        disabled={!Boolean(workspace.modified_user?.hash)}
                    />
                </div>
            ) : null}

            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title="Remove connection"
            >
                <DeleteContent
                    handleAction={() => {
                        setIsLoading(true);
                        onDelete(source, name, workspace.modified_user.hash);
                    }}
                    setModalState={setIsDeleteModalOpen}
                >
                    <p className="text-xl text-gray-500">
                        {t('settings_page.si_tab.sure_disconnect')}
                        <span className="italic">{name}</span>
                        {'?'}
                    </p>
                </DeleteContent>
            </ModalWrapper>
        </li>
    );
};

export default WorkspaceItem;
