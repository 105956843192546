import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import Button from '../shared/button';

const ModalBackendUpdateContent = ({
    backendName,
    sendRequest,
    setModalState,
}) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleUpdate = _.debounce(() => {
        sendRequest();
        setModalState(false);
    }, 1000);

    return (
        <div className="flex flex-col pt-5 h-full">
            <p className="text-xl text-gray-500">
                {capitalizeFirstLetter(
                    t('settings_page.sre_tab.update_modal_message_1', {
                        backend_name: backendName,
                    })
                )}
            </p>
            <p className="text-xl text-gray-500">
                {capitalizeFirstLetter(
                    t('settings_page.sre_tab.update_modal_message_2')
                )}
            </p>
            <div className="ml-auto mt-auto">
                <Button
                    variant="gray"
                    extraClasses={'min-w-[110px]'}
                    onClick={handleChangeModalState}
                >
                    {t('button.close')}
                </Button>
                <Button
                    variant="blue"
                    extraClasses={'min-w-[110px]'}
                    onClick={handleUpdate}
                >
                    {t('button.update')}
                </Button>
            </div>
        </div>
    );
};

export default ModalBackendUpdateContent;
