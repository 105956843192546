import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Google,
    ArrowRight,
    Facebook,
    Linkedin,
    Microsoft,
} from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import useParamsQuery from '../../hooks/useParamsQuery';
import { useNavigate } from 'react-router';
import { loginWithSocial, signupWithSocial } from '../../api/auth/AuthAPI';
import Loader from '../../components/loader';
import { useState } from 'react';
import { STATE_PARAMS_KEY } from '../../constants';
import handleEmailSubscription from '../../helpers/mailchimpSubscription';
import logoAgileAnalytics from '../../assets/logo-new-dark-with-text.png';
import { createLink, getStateKey } from '../../helpers/auth/createAuthLink';
import Button from '../shared/button';

function AuthSocialButton({ setErrorMessage, social, endpoint, disabled }) {
    const { t } = useTranslation();
    const query = useParamsQuery();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const urlParams = new URLSearchParams(localStorage.getItem('q'));
    const entries = urlParams.entries();
    let params = {};
    for (const entry of entries) {
        params = {
            ...params,
            [entry[0]]: entry[1],
        };
    }
    useEffect(() => {
        if (query.get('signup-with') === social) {
            const url = createLink(social, endpoint);
            window.location.href = url;
        }

        if (
            query.get(STATE_PARAMS_KEY) ||
            (query.get('id_token') && social === 'microsoft')
        ) {
            let stateObj = null;

            if (query.get(STATE_PARAMS_KEY)) {
                stateObj = JSON.parse(query.get(STATE_PARAMS_KEY));
            }

            if (
                (stateObj && stateObj?.se === social) ||
                (query.get('id_token') && social === 'microsoft')
            ) {
                const stateKey = getStateKey(social);
                if (
                    stateObj?.st === localStorage.getItem(stateKey) ||
                    (!stateObj &&
                        query.get('id_token') &&
                        social === 'microsoft')
                ) {
                    setIsLoading(true);
                    const code =
                        social === 'microsoft'
                            ? query.get('id_token')
                            : query.get('code');
                    switch (endpoint) {
                        case 'explore':
                        case 'demo':
                            signupWithSocial(social, code, null, null, endpoint)
                                .then((response) => {
                                    localStorage.setItem(
                                        'email',
                                        response.data.email || ''
                                    );
                                    if (response?.data?.idToken) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.idToken
                                        );
                                    }
                                    if (response?.data?.id_token) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.id_token
                                        );
                                    }
                                    localStorage.setItem(
                                        'baseOrgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'orgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'login_option',
                                        social
                                    );
                                    localStorage.setItem(
                                        'firstDemoSession',
                                        true
                                    );

                                    localStorage.removeItem('isSignup');
                                    if (response.data.org_hash) {
                                        navigate(
                                            `/?org_hash=${response.data.org_hash}`
                                        );
                                    } else {
                                        navigate('/');
                                    }
                                })
                                .catch((error) => {
                                    setIsLoading(false);
                                    setErrorMessage(
                                        error.response?.data ||
                                            error.message ||
                                            'Oops! Something went wrong!'
                                    );
                                });
                            break;
                        case 'invite':
                            signupWithSocial(
                                social,
                                code,
                                localStorage.getItem('inviteOrg'),
                                localStorage.getItem('inviteRole'),
                                endpoint
                            )
                                .then((response) => {
                                    localStorage.setItem(
                                        'email',
                                        response.data.email || ''
                                    );
                                    if (response?.data?.idToken) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.idToken
                                        );
                                    }
                                    if (response?.data?.id_token) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.id_token
                                        );
                                    }
                                    localStorage.setItem(
                                        'baseOrgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'orgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'login_option',
                                        social
                                    );

                                    localStorage.removeItem('isSignup');
                                    localStorage.removeItem('inviteOrg');
                                    localStorage.removeItem('inviteRole');

                                    if (response.data.org_hash) {
                                        navigate(
                                            `/?org_hash=${response.data.org_hash}`
                                        );
                                    } else {
                                        navigate('/');
                                    }
                                })
                                .catch((error) => {
                                    localStorage.removeItem('inviteOrg');
                                    localStorage.removeItem('inviteRole');
                                    setIsLoading(false);

                                    setErrorMessage(
                                        error.response?.data ||
                                            error.message ||
                                            'Oops! Something went wrong!'
                                    );
                                });

                            break;
                        case 'signup':
                        case 'trial':
                            const orgData = JSON.parse(
                                localStorage.getItem('orgDataDraft')
                            );
                            const formattedOrgData = {
                                org_name: orgData.companyName,
                                contact_first_name: orgData.contactName,
                                contact_last_name: orgData.contactLastName,
                                contact_email: orgData.contactEmail,
                                marketing_data: {
                                    contact_goal: orgData.contactGoal,
                                    contact_job_title: orgData.contactJobTitle,
                                    monitoring_system: orgData.monitoringSystem,
                                    communication_system:
                                        orgData.communicationSystem,
                                    git_system: orgData.gitSystem,
                                    pm_system: orgData.pmSystem,
                                },
                                subscription_level: orgData.billingPlan,
                                newsletter: orgData.newsLetter,
                            };
                            signupWithSocial(
                                social,
                                code,
                                null,
                                'admin',
                                'signup',
                                formattedOrgData
                            )
                                .then((response) => {
                                    localStorage.setItem(
                                        'email',
                                        response.data.email || ''
                                    );
                                    if (response?.data?.idToken) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.idToken
                                        );
                                    }
                                    if (response?.data?.id_token) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.id_token
                                        );
                                    }
                                    localStorage.setItem(
                                        'baseOrgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'orgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'login_option',
                                        social
                                    );

                                    ReactGA.event('trial_auth_success', {
                                        social: social,
                                        campaign: params['utm_campaign'],
                                        medium: params['utm_medium'],
                                        source: params['utm_source'],
                                        ...params,
                                    });
                                    localStorage.removeItem('isSignup');
                                    localStorage.removeItem('orgDataDraft');

                                    handleEmailSubscription(
                                        response.data.email,
                                        ['trial subscription'],
                                        'users'
                                    ).catch((error) =>
                                        console.log(
                                            'error from mailchimp',
                                            error
                                        )
                                    );

                                    if (orgData?.newsLetter) {
                                        handleEmailSubscription(
                                            response.data.email,
                                            ['trial subscription'],
                                            'newsletter'
                                        );
                                    }
                                    setIsLoading(false);
                                    if (response.data.org_hash) {
                                        navigate(
                                            `/?org_hash=${response.data.org_hash}`
                                        );
                                    } else {
                                        navigate('/');
                                    }
                                })
                                .catch((error) => {
                                    setIsLoading(false);

                                    if (
                                        orgData?.newsLetter &&
                                        localStorage.getItem('email')
                                    ) {
                                        handleEmailSubscription(
                                            localStorage.getItem('email'),
                                            ['failed trial subscription'],
                                            'newsletter'
                                        );
                                    }

                                    setErrorMessage(
                                        error.response?.data ||
                                            error.message ||
                                            'Oops! Something went wrong!'
                                    );
                                });
                            break;
                        case 'login':
                        default:
                            loginWithSocial(code, social)
                                .then((response) => {
                                    localStorage.setItem(
                                        'email',
                                        response.data.email || ''
                                    );
                                    if (response?.data?.idToken) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.idToken
                                        );
                                    }
                                    if (response?.data?.id_token) {
                                        localStorage.setItem(
                                            'token',
                                            response.data.id_token
                                        );
                                    }
                                    localStorage.setItem(
                                        'orgHash',
                                        response.data.org_hash
                                    );
                                    localStorage.setItem(
                                        'login_option',
                                        social
                                    );
                                    localStorage.setItem(
                                        'baseOrgHash',
                                        response.data.org_hash
                                    );
                                    if (response.data.org_hash) {
                                        navigate(
                                            `/?org_hash=${response.data.org_hash}`
                                        );
                                    } else {
                                        navigate('/');
                                    }
                                })
                                .catch((error) => {
                                    setIsLoading(false);
                                    setErrorMessage(
                                        error.response?.data ||
                                            error.message ||
                                            'Oops! Something went wrong!'
                                    );
                                    navigate(location.pathname);
                                });
                    }
                } else {
                    setErrorMessage(
                        'Failed to parse userdata. Please try again'
                    );
                }

                localStorage.removeItem(stateKey);
            } else {
                navigate(location.pathname);
            }
        }
    }, []);

    function renderIcon(social) {
        switch (social) {
            case 'facebook':
                return <Facebook width="24px" height="24px" />;
            case 'microsoft':
                return <Microsoft width="24px" height="24px" />;
            case 'linkedin':
                return <Linkedin width="24px" height="24px" />;
            case 'google':
            default:
                return <Google width="24px" height="24px" />;
        }
    }

    return (
        <Button
            variant="auth"
            id="auth-btn"
            data-testid="auth-btn"
            onClick={() => {
                const urlToClick = createLink(social, endpoint);
                window.location.assign(urlToClick);
            }}
            extraClasses={`w-full flex py-2 items-center border-gray-400  text-lg`}
            disabled={disabled}
        >
            {renderIcon(social)}
            <span className={'ml-4 flex-grow text-left'} data-testid="auth-btn">
                {endpoint !== 'login'
                    ? t('signup_page.button.continue_social_signup')
                    : t('login_page.button.continue_social_login')}{' '}
                <span className={'capitalize'}>{social}</span>{' '}
            </span>
            <ArrowRight fill={'#9ca3af'} />
            {isLoading ? (
                endpoint === 'trial' || endpoint === 'signup' ? (
                    <div className="fixed top-0 left-0 w-screen h-screen min-h-screen flex flex-col gap-8 justify-center items-center bg-gray-50 z-50">
                        <div className="p-2 w-full bg-gray-50">
                            <NavLink to="/" className="" data-testid="logo">
                                <img
                                    src={logoAgileAnalytics}
                                    alt="Agile Analytics logo"
                                    className="w-80 m-auto"
                                />
                            </NavLink>
                        </div>
                        <div className="loader-wrapper pt-8 flex flex-col items-center justify-center gap-4">
                            <p className="text-gray-400">
                                Creating organisation...
                            </p>
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            'bg-white fixed w-full inset-0 min-h-screen flex justify-center items-center z-50'
                        }
                    >
                        <Loader size={48} />
                    </div>
                )
            ) : null}
        </Button>
    );
}

export default AuthSocialButton;
