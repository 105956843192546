import { useTranslation } from 'react-i18next';
import Button from '../shared/button';

const SuggestTourModalContent = ({ sendRequest = async () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col py-4 h-full justify-between">
            <p className="text-xl text-gray-500">
                Take a quick tour of our demo organization to familiarize
                yourself with our platform and its features. It only takes a few
                minutes!
            </p>
            <Button
                type="submit"
                variant="darkBuue"
                onClick={() => sendRequest()}
            >
                Start the tour
            </Button>
        </div>
    );
};

export default SuggestTourModalContent;
