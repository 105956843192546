import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from './textFormatters';
import Loader from '../components/loader';
import {
    ERROR_BUDGET_CHART_COLORS,
    ERROR_BUDGET_CHART_SLO_COLORS,
} from '../constants';

export function formatDuration(milliseconds) {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    const hoursText = hours === 1 ? 'hour' : 'hours';
    const minutesTest = minutes === 1 ? 'minute' : 'minutes';
    let hourStr = hours > 0 ? `${hours} ${hoursText}` : '';
    let minuteStr = minutes > 0 ? `${minutes} ${minutesTest}` : '';

    if (!hourStr && !minuteStr) {
        return '0 minutes'; // Handle case where both hours and minutes are 0
    }

    return [hourStr, minuteStr].filter(Boolean).join(' ');
}

export function formatPlotLinesTooltip(item, start, end, groupRangeString) {
    return `<div class="overflow-visible relative p-2 w-4 h-4 rounded-full bg-white flex justify-center items-center border border-solid border-gray-chart-light text-gray-chart-light  hover:border-gray-chart-dark hover:text-gray-chart-dark  basic-transition text-[10px]">${
        item?.deployments?.length
            ? item?.deployments?.length > 9
                ? '9+'
                : item?.deployments?.length
            : '1'
    }<div class="${
        item?.timestamp * 1000 - start - (end - start) / 2.5 < 0
            ? '-left-2'
            : '-right-2'
    } label-tooltip-container  absolute -top-[76px]  overflow-hidden -z-50"><div class=" cursor-default label-tooltip basic-transition bg-white  text-gray-chart-dark flex flex-col justify-center text-[12px] "><p class='px-2 pt-1   break-words overflow-hidden bg-white'><b>Deployment date:</b> ${moment
        .unix(item?.timestamp)
        .format(
            'HH:mm:ss D.M.YY'
        )}</p><p class=' overflow-hidden bg-white px-2 break-all whitespace-normal'><b>Project:</b> ${
        item?.repository
    }</p><p class='overflow-hidden bg-white px-2 break-words font-bold'>Status: <span class='${
        item?.status === 'success' ? 'text-success-green-300' : 'text-red-text'
    }'>${item?.status}</span></p>
                                  ${
                                      item?.type === 'group'
                                          ? `<p class="px-2 break-words overflow-hidden bg-white">
                                       <b>
                                           ...and ${
                                               item?.deployments?.length - 1
                                           }
                                           more
                                       </b>
                                       in the next ${groupRangeString}
                                   </p>`
                                          : ''
                                  }</p></div></div></div>`;
}

function setDeployments(list, dep) {
    if (list?.length) {
        if (list[list?.length - 1]?.deployments?.length) {
            return [...list[list?.length - 1]?.deployments, dep];
        } else {
            return [list[list?.length - 1], dep];
        }
    }
    return [];
}

export function formatPlotLines(deployments, start, end) {
    const groupRange = +((+end.toFixed(0) - +start.toFixed(0)) / 60).toFixed(0);
    const groupRangeString = formatDuration(groupRange);
    const groupedDeployments = deployments?.reduce((acc, dep) => {
        if (dep?.timestamp * 1000 < start || dep?.timestamp * 1000 > end) {
            return acc;
        }
        if (
            !acc?.length ||
            dep?.timestamp * 1000 - acc[acc?.length - 1]?.timestamp * 1000 >
                groupRange
        ) {
            return [...acc, dep];
        } else {
            const copiedAcc = acc;
            return [
                ...copiedAcc.slice(0, copiedAcc?.length - 1),
                {
                    ...acc[acc?.length - 1],
                    type: 'group',
                    deployments: setDeployments(copiedAcc, dep),
                },
            ];
        }
    }, []);

    let formattedList = groupedDeployments?.length
        ? groupedDeployments.map((item) => {
              return {
                  value: item?.timestamp * 1000,
                  color: '#666666',
                  width: 1,
                  dashStyle: 'LongDash',
                  zIndex: 2,
                  label: {
                      useHTML: true,
                      formatter: function () {
                          return formatPlotLinesTooltip(
                              item,
                              start,
                              end,
                              groupRangeString
                          );
                      },
                      y: -15,
                      x: -1,
                      align: 'center',
                      rotation: 0,
                      style: {
                          fontSize: 14,
                          // position: 'absolute',
                          overflow: 'visible',
                      },
                  },
              };
          })
        : null;
    return formattedList;
}

export function filterDataEvents(fullData, min, max) {
    const filtered = fullData?.map((dataset) => {
        const updatedData = dataset?.data?.filter((item) => {
            if (item?.length && item[0] >= min && item[0] <= max) {
                return item;
            }
        });
        return { ...dataset, data: [...updatedData] };
    });
    return filtered;
}

export function NoChartData() {
    const { t } = useTranslation();
    return (
        <div className="mr-5" style={{ width: '100%', height: '190px' }}>
            <p className="text-center text-gray-500 text-xl p-4">
                {capitalizeFirstLetter(t('sre.no_data'))}
            </p>
        </div>
    );
}

export function ChartLoading() {
    const { t } = useTranslation();
    const [loadingItemIndex, setLoadingItemIndex] = useState(0);

    useEffect(() => {
        if (
            t('common.loading_texts', { returnObjects: true })?.length - 2 >
            loadingItemIndex
        ) {
            setTimeout(() => {
                setLoadingItemIndex((prev) => prev + 1);
            }, 3000);
        } else {
            setTimeout(() => {
                setLoadingItemIndex(0);
            }, 3000);
        }
    }, [loadingItemIndex]);

    return (
        <div
            className="mr-5 flex justify-center items-center"
            style={{ width: '100%', height: '394px' }}
        >
            <div className="flex flex-col items-center justify-center gap-4">
                <Loader
                    color={'#C2C7D7'}
                    size={35}
                    speedMultiplier={0.8}
                    css={{ margin: '30px auto', display: 'block' }}
                />
                <p className="text-gray-400 text-sm">
                    {t(`common.loading_texts.${loadingItemIndex}`)}...
                </p>
            </div>
        </div>
    );
}

export function generateErrorBudgetChartOptionsBase(
    dataFeaturesVisibility,
    setDataFeaturesVisibility,
    setZoomTimeperiod,
    isInTeam,
    step
) {
    const { t } = useTranslation();
    return {
        colors: ERROR_BUDGET_CHART_COLORS,
        chart: {
            type: 'line',
            height: 460,
            zooming: {
                type: 'x',
            },
            spacingTop: 40,
            events: {
                selection: function (event) {
                    if (event.xAxis) {
                        const selectedMin = event.xAxis[0]?.min;
                        const selectedMax = event.xAxis[0]?.max;

                        setZoomTimeperiod([selectedMin, selectedMax]);
                    } else {
                        setZoomTimeperiod(null);
                    }
                },
                render: function () {
                    this?.resetZoomButton?.hide();

                    const chartContainer = this.container.parentNode;
                    chartContainer.style.overflow = 'visible';
                },
            },
        },

        title: {
            text: '',
        },
        yAxis: {
            //primary Y axis
            max: 100,

            ceiling: 100,
            alignTicks: false,
            endOnTick: false,
            labels: {
                format: '{value}%',
            },
            title: {
                enabled: false,
            },

            plotBands: [
                {
                    color: '#f4f9ee',
                    from: 0,
                    to: 10000000000,
                },
                {
                    // mark the weekend
                    color: '#f9ebeb',
                    from: -10000000000,
                    to: 0,
                },
            ],
            // always show max tick
            tickPositioner: function () {
                let tickPositions = this.tickPositions,
                    lastTick = tickPositions[tickPositions.length - 1],
                    max = this.options.max;

                if (lastTick > max) {
                    tickPositions.pop(); // remove last tick
                    tickPositions.push(max);
                }
            },
        },
        xAxis: {
            crosshair: true,
            type: 'datetime',
            labels: {
                format: '{value:%d %b %H:%M}',
                rotation: -50,
                align: 'right',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                // negativeColor: '#FF6384',
                opacity: 0.6,
                marker: {
                    enabled: false,
                },
                events: {
                    legendItemClick: function () {
                        const name = this?.userOptions?.slo_name
                            ? this?.userOptions?.service
                                ? this?.userOptions?.slo_name +
                                  ' ' +
                                  this?.userOptions?.service
                                : this?.userOptions?.slo_name
                            : this?.userOptions?.title;
                        setDataFeaturesVisibility((prevState) => {
                            return {
                                ...prevState,
                                [name]: !dataFeaturesVisibility[name],
                            };
                        });
                    },
                },
            },
        },
        tooltip: {
            shared: true,
            positioner: function (labelWidth, labelHeight, point) {
                const tooltipX =
                    point.plotX < 250
                        ? point.plotX + 70
                        : point.plotX - this.label.width + 40;
                const tooltipY =
                    (point?.plotY ? point?.plotY : 0) + this.label.height;
                return {
                    x: tooltipX,
                    y: tooltipY,
                };
            },
            formatter: function () {
                return this.points.reduce(function (s, point) {
                    return (
                        s +
                        '<br/>' +
                        `<span style="color:${point.color}">${
                            point?.series?.userOptions?.slo_name
                                ? isInTeam
                                    ? point?.series?.userOptions?.slo_name +
                                      '/' +
                                      point?.series?.userOptions?.service
                                    : point?.series?.userOptions?.slo_name
                                : point?.series?.userOptions?.title
                        }</span>` +
                        `${isInTeam ? '' : ' ' + t('sre.last') + ' ' + step}` +
                        ': ' +
                        (point.y.toFixed(3) == 100 ? 100 : point.y.toFixed(3)) +
                        '%'
                    );
                }, moment(this.x).format('dddd, MMM D, HH:mm:ss'));
            },
            // outside: true,
        },

        legend: {
            labelFormatter: function () {
                return this.userOptions.slo_name
                    ? `<b>SLO: </b><span>${
                          this.userOptions.slo_name ?? ''
                      }</span><br>
                <b>${capitalizeFirstLetter(
                    isInTeam
                        ? 'service'
                        : t('hours_page.hours_report.description')
                )}: </b><span>${
                          isInTeam
                              ? this.userOptions.service
                              : this.userOptions.slo_description ?? ''
                      }</span><br> 

                <b>${t('sre.slo_target')}: </b><span>${
                          this.userOptions.slo_target ?? ''
                      }</span><br>`
                    : `<b>${this.userOptions.title ?? ''}</b>`;
            },
            events: {
                itemClick: function (e) {
                    const visibility = e.legendItem.visible
                        ? 'visible'
                        : 'hidden';
                    if (
                        !confirm(
                            'The series is currently ' +
                                visibility +
                                '. Do you want to change ' +
                                'that?'
                        )
                    ) {
                        return false;
                    }
                },
            },
        },
    };
}

export function generateEventsChartOptionsBase(
    setZoomTimeperiod,
    eventsVisibility,
    setEventsVisibility,
    step
) {
    const { t } = useTranslation();
    return {
        colors: ['#FF6384', '#8ec358'],
        chart: {
            type: 'column',
            height: 220,
            zooming: {
                type: 'x',
                resetButton: {
                    position: { x: 0, y: -50 },
                    theme: {
                        display: 'none',
                    },
                },
            },
            events: {
                selection: function (event) {
                    if (event.xAxis) {
                        const selectedMin = event.xAxis[0]?.min;
                        const selectedMax = event.xAxis[0]?.max;

                        setZoomTimeperiod([selectedMin, selectedMax]);
                    } else {
                        setZoomTimeperiod(null);
                    }
                },
                render: function () {
                    this?.resetZoomButton?.hide();
                },
            },
        },
        title: {
            text: '',
        },
        yAxis: {
            //secondary Y axis
            labels: {
                format: '{value}',
            },
            title: {
                enabled: false,
            },
            min: 0,
        },

        xAxis: {
            crosshair: true,
            type: 'datetime',
            labels: {
                format: '{value:%d %b %H:%M}',
                rotation: -50,
                align: 'right',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                minPointLength: 2,
                events: {
                    legendItemClick: function () {
                        setEventsVisibility((prevState) => {
                            if (eventsVisibility) {
                                return {
                                    ...prevState,
                                    [this?.userOptions?.title]: {
                                        ...prevState[this?.userOptions?.title],
                                        [this?.userOptions?.name]:
                                            !prevState[
                                                this?.userOptions?.title
                                            ][this?.userOptions?.name],
                                    },
                                };
                            }
                        });
                    },
                },
            },
        },

        tooltip: {
            formatter: function () {
                return this.points.reduce(
                    function (s, point) {
                        return (
                            point?.series.name +
                            ' ' +
                            t('sre.in_the_last_time_step') +
                            step +
                            ': ' +
                            '<b>' +
                            point?.y +
                            '</b><br/>' +
                            s
                        );
                    },
                    this.points.length > 1
                        ? 'Total: ' + '<b>' + this.points[0].total + '</b>'
                        : ''
                );
            },
            shared: true,
        },

        legend: {
            labelFormatter: function () {
                return this.userOptions.title
                    ? `<span><b>${this.userOptions.title + ':' ?? ''} ${
                          this.userOptions.name ?? ''
                      }</b></span><br>`
                    : `<b>${this.userOptions.name ?? ''}</b>`;
            },
        },
    };
}

export function getSloColor(i, isTeam = false) {
    let colorIndex = i;

    if (isTeam) {
        colorIndex += 3;
    }
    while (colorIndex >= ERROR_BUDGET_CHART_SLO_COLORS?.length) {
        colorIndex = colorIndex - ERROR_BUDGET_CHART_SLO_COLORS?.length;
    }

    return ERROR_BUDGET_CHART_SLO_COLORS[colorIndex];
}
