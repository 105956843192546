import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Loader from '../components/loader';
import SetPasswordForm from '../components/set-password-form';
import useParamsQuery from '../hooks/useParamsQuery';
import { checkToken, resendEmail } from '../api/auth/AuthAPI';
import showNotification from '../helpers/showNotification';
import ErrorIndicator from '../components/error-indicator';
import { ArrowRight } from '../assets/svg';
import Button from '../components/shared/button';

function SetPasswordPage() {
    const { t } = useTranslation();
    const query = useParamsQuery();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [globalError, setGlobalError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(true);
    const [emailStatus, setEmailStatus] = useState(null);
    const tokenFromUrl = query.get('idToken');

    useEffect(() => {
        if (!tokenFromUrl) {
            navigate('/');
        } else {
            localStorage.setItem('token', tokenFromUrl);
            void handleCheckToken();
        }
    }, []);

    async function handleCheckToken() {
        try {
            const res = await checkToken();
            res && setIsTokenValid(true);
            setIsLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                setGlobalError('');
                setIsTokenValid(false);
                setIsLoading(false);
            } else {
                console.log(error.response);
                setGlobalError(error);
                setIsLoading(false);
            }
        }
    }

    async function handleResendEmail() {
        // TODO: add API for resending email
        setEmailStatus('sending');
        try {
            const res = await resendEmail();
            res && setEmailStatus('sent');
        } catch (error) {
            setEmailStatus(null);
            showNotification(
                error.response?.data ||
                    error.message ||
                    error ||
                    'Oops! Something went wrong'
            );
        }
    }

    return (
        <>
            {isLoading ? (
                <div
                    className={
                        'z-50 bg-white fixed w-full inset-0 min-h-screen flex justify-center items-center'
                    }
                >
                    <Loader size={48} />
                </div>
            ) : null}
            <div className="w-screen h-full min-h-screen flex justify-center items-center bg-gray-50">
                <div
                    className={` w-full sm:w-2/3 md:w-[500px] h-full min-h-screen sm:min-h-0 sm:h-auto / p-4 sm:p-8 / bg-white sm:rounded-md sm:shadow-lg`}
                >
                    <div className="flex flex-col content-center">
                        <div className="py-2">
                            <div className="font-display text-2xl text-gray-500 text-center">
                                {t('set_password_page.sub_title')}
                            </div>
                            <h1 className="font-display font-extrabold text-4xl text-gray-800 text-center mb-2">
                                Agile Analytics
                            </h1>
                            <div className="font-display text-lg text-gray-500 text-center">
                                {t('set_password_page.extra_title')}
                            </div>
                        </div>

                        {globalError ? (
                            <>
                                <ErrorIndicator error={globalError} />
                                <Button
                                    onClick={() => navigate('/')}
                                    extraClasses={
                                        'mt-4 px-2 py-1 text-gray-500 flex gap-x-2'
                                    }
                                    variant="authTransparentGray"
                                >
                                    <ArrowRight
                                        fill={'#7F8992'}
                                        className="transform rotate-180 mb-4"
                                    />
                                    {t('set_password_page.button.back')}
                                </Button>
                            </>
                        ) : isTokenValid ? (
                            <>
                                {errorMessage && (
                                    <div className="bg-red-200 text-red-600 text-sm p-2 rounded">
                                        {errorMessage}
                                    </div>
                                )}

                                <SetPasswordForm
                                    setErrorMessage={setErrorMessage}
                                    navigate={navigate}
                                />
                            </>
                        ) : (
                            <>
                                <ErrorIndicator error={'Invalid token'} />
                                {(!emailStatus ||
                                    emailStatus === 'sending') && (
                                    <Button
                                        extraClasses={
                                            'w-full mt-4 flex space-x-4 justify-center py-2 items-center'
                                        }
                                        isLoading={
                                            emailStatus === 'sending'
                                                ? true
                                                : false
                                        }
                                        onClick={handleResendEmail}
                                        type={'submit'}
                                        variant="auth"
                                    >
                                        <span className={'mr-auto'}>
                                            {t(
                                                'set_password_page.button.send_email'
                                            )}
                                        </span>
                                    </Button>
                                )}
                                {emailStatus === 'sent' && (
                                    <p className="text-gray-500 mt-4 text-center">
                                        {t('set_password_page.email_sent')}
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetPasswordPage;
