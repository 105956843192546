import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { Arrow, JiraIcon, AzureDevOpsIcon } from '../../assets/svg';
import Loader from '../loader';
import { getAdminPermission } from '../../helpers/getPermission';
import { capitalizeEveryWordFirstLetter } from '../../helpers/textFormatters';
import Button from '../shared/button';

const AddWorkspaceItem = ({
    addWorkspace,
    isInOrg = true,
    loading = false,
}) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const [isServicesListOpen, setIsServicesListOpen] = useState(false);

    const toggleIsServicesListOpen = () =>
        setIsServicesListOpen(!isServicesListOpen);

    const handleAddNewWorkspace = (service) => {
        toggleIsServicesListOpen();
        addWorkspace(service);
    };

    if (loading) {
        return (
            <li className="w-480 h-300 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
                <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
            </li>
        );
    }

    return (
        <li className="w-480 h-300 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
            {isInOrg ? (
                getAdminPermission(organisationsState?.data) ? (
                    <>
                        <Button
                            className="w-160 "
                            onClick={() => toggleIsServicesListOpen()}
                            variant="white"
                        >
                            <span className="mr-2">
                                {capitalizeEveryWordFirstLetter(
                                    t('settings_page.si_tab.add')
                                )}
                            </span>
                            <Arrow
                                width={'12'}
                                height={'8'}
                                fill="#7F8992"
                                transform={
                                    isServicesListOpen
                                        ? 'scale(1, 1)'
                                        : 'scale(1, -1)'
                                }
                            />
                        </Button>
                        <div className="relative w-160">
                            {isServicesListOpen && (
                                <ul className="w-160 absolute top-0 left-0 bg-white rounded shadow-default text-gray-500">
                                    <li
                                        className="p-2 flex filter grayscale cursor-pointer"
                                        onClick={() =>
                                            handleAddNewWorkspace('jira')
                                        }
                                    >
                                        <JiraIcon />
                                        <span className="ml-1 ">
                                            Jira Workspace
                                        </span>
                                    </li>
                                    <li
                                        className="p-2 flex filter grayscale cursor-pointer"
                                        onClick={() =>
                                            handleAddNewWorkspace('azure')
                                        }
                                    >
                                        <AzureDevOpsIcon />
                                        <span className="ml-1 ">
                                            Azure DevOps
                                        </span>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </>
                ) : (
                    <p className="font-display text-xl text-center text-custom-gray-400">
                        You don`t have permission to add a new ticket feed.
                        Please contact your Organisation`s Admin
                    </p>
                )
            ) : (
                <p className="p-4 font-display text-xl text-center text-gray-500">
                    Tickets feed adding is unavailable. You are not a part of
                    this organisation.
                </p>
            )}
        </li>
    );
};

export default AddWorkspaceItem;
