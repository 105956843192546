import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { countriesListOptions } from '../../helpers/countriesList';
import UpdateOrganisationFormField from '../user-update-organisation-field';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import Button from '../shared/button';

const UserUpdateOrganisationForm = ({
    organisationData = null,
    onSubmit,
    action = 'manage',
    permissionForWrite,
}) => {
    let formattedOrganisationData = {
        orgName: organisationData.org_name,
        orgHash: organisationData.org_hash,
        orgAddress: organisationData?.org_address || '',
        orgPostal: organisationData?.org_postal || '',
        orgCity: organisationData?.org_city || '',
        orgCountry: organisationData?.org_country || '',
        contactFirstName: organisationData?.contact_first_name || '',
        contactLastName: organisationData?.contact_last_name || '',
        contactPhone: organisationData?.contact_phone || '',
        contactEmail: organisationData?.contact_email || '',
    };

    const { t } = useTranslation();

    const [organisationDataChanged, setOrganisationDataChanged] =
        useState(false);

    const initialValues = {
        orgName: '',
        orgHash: '',
        orgAddress: '',
        orgPostal: '',
        orgCity: '',
        orgCountry: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',
    };

    const validationSchema = Yup.object({
        orgName: Yup.string()
            .min(3, 'Must be at least 3 characters long')
            .max(100, 'Maximum 100 characters')
            .required('Required')
            .matches(
                /^[A-Za-z0-9 ._-]*[A-Za-z0-9][A-Za-z0-9 ._-]*$/,
                'Only alphabets, numbers or _, ., - are allowed'
            ),
        orgHash: Yup.string().required('Required'),
        orgAddress: Yup.string().max(400, 'Maximum 400 characters'),
        orgPostal: Yup.string().max(20, 'Maximum 20 characters'),
        orgCity: Yup.string().max(150, 'Maximum 150 characters'),
        orgCountry: Yup.string().max(150, 'Maximum 100 characters'),
        contactFirstName: Yup.string().max(150, 'Maximum 150 characters'),
        contactLastName: Yup.string().max(250, 'Maximum 250 characters'),
        contactPhone: Yup.string()
            .max(30, 'Maximum 30 characters')
            .matches(
                /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
                'Phone number is not valid'
            ),
        contactEmail: Yup.string()
            .max(150, 'Maximum 150 characters')
            .email('Email mast be valid'),
    });

    function handleSubmit(values) {
        const {
            orgName,
            orgAddress,
            orgPostal,
            orgCity,
            orgCountry,
            contactFirstName,
            contactLastName,
            contactPhone,
            contactEmail,
        } = values;
        const formattedData = {
            org_name: orgName,
            org_address: orgAddress,
            org_postal: orgPostal,
            org_city: orgCity,
            org_country: orgCountry,
            contact_first_name: contactFirstName,
            contact_last_name: contactLastName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
        };
        onSubmit(formattedData);
    }
    return (
        <div>
            <Formik
                initialValues={formattedOrganisationData || initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    setFieldTouched,
                }) => {
                    return (
                        <Form
                            onChange={() => {
                                setOrganisationDataChanged(true);
                            }}
                        >
                            <div className="flex gap-x-64 pb-4">
                                <div className="w-80">
                                    <p className="mb-2 font-medium text-gray-700 ">
                                        {capitalizeFirstLetter(
                                            t('settings_page.org_tab.title')
                                        )}
                                    </p>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t('common.name')
                                            )}
                                            attributeName={'orgName'}
                                            fieldType={'text'}
                                            placeholderText={`${capitalizeFirstLetter(
                                                t('settings_page.org_tab.title')
                                            )} ${t('common.name')}`}
                                            error={errors.orgName}
                                            touched={touched.orgName}
                                            value={values.orgName}
                                            required={true}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={'Organisation hash'}
                                            attributeName={'orgName'}
                                            fieldType={'text'}
                                            error={errors.orgHash}
                                            touched={touched.orgHash}
                                            value={values.orgHash}
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.address'
                                                )
                                            )}
                                            attributeName={'orgAddress'}
                                            fieldType={'text'}
                                            placeholderText={`${capitalizeFirstLetter(
                                                t('settings_page.org_tab.title')
                                            )} ${t(
                                                'settings_page.org_tab.address'
                                            )}`}
                                            error={errors.orgAddress}
                                            touched={touched.orgAddress}
                                            value={values.orgAddress}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className=" flex gap-x-2">
                                        <div className="w-32">
                                            <UpdateOrganisationFormField
                                                name={capitalizeFirstLetter(
                                                    t(
                                                        'settings_page.org_tab.postal'
                                                    )
                                                )}
                                                attributeName={'orgPostal'}
                                                fieldType={'text'}
                                                placeholderText={capitalizeFirstLetter(
                                                    t(
                                                        'settings_page.org_tab.postal'
                                                    )
                                                )}
                                                error={errors.orgPostal}
                                                touched={touched.orgPostal}
                                                value={values.orgPostal}
                                                required={false}
                                                disabled={!permissionForWrite}
                                            />
                                        </div>
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t('settings_page.org_tab.city')
                                            )}
                                            attributeName={'orgCity'}
                                            fieldType={'text'}
                                            placeholderText={`${capitalizeFirstLetter(
                                                t('settings_page.org_tab.city')
                                            )} code`}
                                            error={errors.orgCity}
                                            touched={touched.orgCity}
                                            value={values.orgCity}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            className="block text-gray-500 text-m font-light mb-2"
                                            htmlFor={'orgCountry'}
                                        >
                                            {capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.country'
                                                )
                                            )}{' '}
                                            <span className="text-xs">
                                                {` (${t(
                                                    'settings_page.org_tab.optional'
                                                )})`}
                                            </span>
                                        </label>
                                        <Select
                                            id={'orgCountry'}
                                            value={countriesListOptions.find(
                                                (country) =>
                                                    country.value ===
                                                    values.orgCountry
                                            )}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'orgCountry',
                                                    e.value
                                                );
                                            }}
                                            onBlur={setFieldTouched}
                                            error={errors.orgCountry}
                                            touched={touched.orgCountry}
                                            options={countriesListOptions}
                                            styles={selectStyles()}
                                            isDisabled={!permissionForWrite}
                                        />
                                    </div>
                                </div>
                                <div className="w-80 flex flex-col">
                                    <p className="mb-2 font-medium text-gray-700 ">
                                        {capitalizeFirstLetter(
                                            t(
                                                'settings_page.org_tab.contact_person'
                                            )
                                        )}
                                    </p>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.person_first_name'
                                                )
                                            )}
                                            attributeName={'contactFirstName'}
                                            fieldType={'text'}
                                            placeholderText={capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.first_name'
                                                )
                                            )}
                                            error={errors.contactFirstName}
                                            touched={touched.contactFirstName}
                                            value={values.contactFirstName}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.person_last_name'
                                                )
                                            )}
                                            attributeName={'contactLastName'}
                                            fieldType={'text'}
                                            placeholderText={capitalizeFirstLetter(
                                                t(
                                                    'settings_page.org_tab.last_name'
                                                )
                                            )}
                                            error={errors.contactLastName}
                                            touched={touched.contactLastName}
                                            value={values.contactLastName}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={capitalizeFirstLetter(
                                                t('settings_page.org_tab.phone')
                                            )}
                                            attributeName={'contactPhone'}
                                            fieldType={'tel'}
                                            placeholderText={capitalizeFirstLetter(
                                                t('settings_page.org_tab.phone')
                                            )}
                                            error={errors.contactPhone}
                                            touched={touched.contactPhone}
                                            value={values.contactPhone}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className="">
                                        <UpdateOrganisationFormField
                                            name={'Email'}
                                            attributeName={'contactEmail'}
                                            fieldType={'tel'}
                                            placeholderText={'Email'}
                                            error={errors.contactEmail}
                                            touched={touched.contactEmail}
                                            value={values.contactEmail}
                                            required={false}
                                            disabled={!permissionForWrite}
                                        />
                                    </div>
                                    <div className="flex-1 flex justify-end items-end">
                                        <Button
                                            extraClasses="px-8"
                                            variant="blue"
                                            type="submit"
                                            disabled={!organisationDataChanged}
                                        >
                                            {action === 'create'
                                                ? capitalizeFirstLetter(
                                                      t('button.create')
                                                  )
                                                : capitalizeFirstLetter(
                                                      t('button.save')
                                                  )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const selectStyles = () => ({
    container: (provided, state) => ({
        ...provided,
        border: state.isDisabled ? '' : '1px solid #DADDE8',
        borderRadius: '4px',
        backgroundColor: state.isDisabled ? '#DADDE8' : '',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#DADDE8',
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#323236',
        lineHeight: '24px',
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#7F8992',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#323236',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '0',
    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: state.isSelected ? '' : 'pointer',
        color: state.isSelected ? '#C2C7D7' : '#000',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected ? '#fff' : '#e6e6e6',
        },
    }),
});

export default UserUpdateOrganisationForm;
