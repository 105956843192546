import React from 'react';
import './styles.scss';
import SelectCell from '../table-cells/select-cell';

function TableRow({ rowData, gridColsAmount }) {
    const defaultLevelColorSheme = 'normal'; //could be 'inverted'

    return (
        <div
            className={`grid grid-cols-${gridColsAmount} text-gray-500`}
            data-testid="generic-table-row"
        >
            {Object.entries(rowData)
                .filter(
                    (item) =>
                        item[1]?.type !== 'error' ||
                        (item[1]?.type === 'error' && item[1]?.value)
                )
                .map(([key, value], index) => {
                    switch (value?.type) {
                        case 'select':
                            return <SelectCell value={value} row={rowData} />;
                        case 'error':
                            return (
                                <div
                                    className={`col-span-${value?.colsSpan} w-full rounded my-1 rounded bg-red-100`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <p
                                        className="truncate px-4 py-1 text-sm text-red-text text-center"
                                        title={`${value.value}`}
                                        id={`${key}_${value}_${index}`}
                                    >
                                        {value?.value}
                                    </p>
                                </div>
                            );
                        case 'level':
                            return (
                                <div
                                    className={`col-span-${
                                        value?.colsSpan
                                    } level level-${
                                        value?.colorScheme
                                            ? value?.colorScheme
                                            : defaultLevelColorSheme
                                    }-${value?.value} `}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                </div>
                            );

                        case 'number':
                        case 'string':
                        default:
                            return (
                                <div
                                    className={`col-span-${value?.colsSpan} flex  items-center`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <p
                                        className="truncate px-4 py-2.5"
                                        title={`${value.value}`}
                                        id={`${key}_${value}_${index}`}
                                    >
                                        {value?.value}
                                    </p>
                                </div>
                            );
                    }
                })}
        </div>
    );
}

export default TableRow;
