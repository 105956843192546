import React, { useCallback, useContext, useState, useEffect } from 'react';
import ErrorBudgetsChart from './sre-chart-error-budgets';
import StubChart from './sre-chart-stub';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { capitalizeEveryWordFirstLetter } from '../../../helpers/textFormatters';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import {
    getErrorBudgets,
    getServiceByName,
} from '../../../api/settings-slo/slo';
import { TimePeriodContext } from '../../../context/TimePeriodContext';
import SloCard from './sre-slo-card';
import ServiceEditForm from './sre-service-edit-form';
import { getSloColor } from '../../../helpers/sreChartHelpers';
import SreNotes from './sre-notes';
import Button from '../../../components/shared/button';

function SREService({
    service,
    services,
    servicesDispatch,
    setIsEditing,
    sortedDeploymentFreqData,
    isVisible,
}) {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;

    const [serviceStatus, setServiceStatus] = useState({
        modalState: {
            manage: false,
        },
        service: {
            value: service?.service ? service?.service : 'default',
            label: t('sre.select_service'),
        },
    });

    const serviceRequest = useCallback(() => {
        if (
            serviceStatus.service.value &&
            !['default', 'handlerAddService', 'handlerManageService'].includes(
                serviceStatus.service.value
            )
        ) {
            return getServiceByName(serviceStatus.service.value);
        }

        return Promise.resolve({ status: 204 });
    }, [serviceStatus.service.value]);

    const [serviceState, serviceDispatch] = useFetchDataAndSetState(
        serviceRequest,
        [service]
    );

    const [isEdit, setIsEdit] = useState(false);
    const [step, setStep] = useState({
        value: '24 hours',
        label: `24 ${t('sre.hours_step')}`,
    });

    const featuresList = serviceState?.data?.features?.map(
        (feature) => feature.feature_name
    );

    const request = useCallback(async () => {
        let response = { data: null, status: 200 };

        if (featuresList?.length) {
            try {
                const values = await Promise.allSettled(
                    featuresList.map((feature) =>
                        getErrorBudgets(
                            serviceState?.data?.service,
                            dateTimeStart,
                            dateTimeEnd,
                            feature,
                            step?.value
                        )
                    )
                );

                if (values.length) {
                    if (!values.find((value) => value.status === 'fulfilled')) {
                        response = {
                            ...response,
                            status: 404,
                            error: values[0].reason,
                        };
                    } else {
                        let status = 200;

                        if (
                            !values.find(
                                (value) =>
                                    value.status === 'fulfilled' &&
                                    value.value.status !== 204
                            )
                        ) {
                            status = 204;
                        }

                        response = {
                            ...response,
                            data: values
                                .filter((value) => value.status === 'fulfilled')
                                .map((value) =>
                                    value.value.data
                                        ? value.value.data
                                        : undefined
                                )
                                .filter((value) => value !== undefined),
                            status: 200,
                        };
                    }
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            response = { data: [], status: 204 };
        }

        return response;
    }, [dateTimeStart, dateTimeEnd, serviceState.data, step]);

    const [chartState] = useFetchDataAndSetState(request, [
        dateTimeStart,
        dateTimeEnd,
    ]);

    const stepOptions = [
        { value: '1 hours', label: `1 ${t('sre.hours_step')}` },
        { value: '12 hours', label: `12  ${t('sre.hours_step')}` },
        { value: '24 hours', label: `24  ${t('sre.hours_step')}` },
        { value: '48 hours', label: `48  ${t('sre.hours_step')}` },
    ];

    useEffect(() => {
        setServiceStatus({
            modalState: {
                manage: false,
            },
            service: {
                value: service?.service ? service?.service : 'default',
                label: t('sre.select_service'),
            },
        });
    }, [service]);

    return (
        <div
            className={`${
                isVisible ? 'flex' : 'hidden'
            } mb-10 bg-white px-9 py-4 rounded-lg shadow-md`}
        >
            <div className="w-full">
                <div className="">
                    {isEdit ? (
                        <ServiceEditForm
                            service={service}
                            serviceState={serviceState}
                            serviceDispatch={serviceDispatch}
                            services={services}
                            servicesDispatch={servicesDispatch}
                            onGoBack={() => {
                                setIsEdit(false);
                                setIsEditing(false);
                            }}
                        />
                    ) : (
                        <div className="flex justify-between mb-6 items-start">
                            <div className="fle flex-col">
                                <h1 className="font-display text-28 text-gray-700 font-light border-b-2 border-solid border-theme-primary">
                                    {capitalizeEveryWordFirstLetter(
                                        service?.service
                                            ? service?.service
                                            : 'No name'
                                    )}
                                </h1>
                                {service?.description ? (
                                    <p className="mt-4 text-gray-500 ">
                                        {service?.description}
                                    </p>
                                ) : null}
                                {service?.url ? (
                                    <div className="flex mt-2 gap-1 items-center">
                                        <p className="text-xs text-gray-500 font-light">
                                            Operational dashboard URL:{' '}
                                        </p>
                                        <a
                                            className="block text-xs text-theme-tertiary underline "
                                            target="_blank"
                                            href={service?.url}
                                        >
                                            {service?.url}
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <Button
                                type="button"
                                variant="whiteHoverBlue"
                                onClick={() => {
                                    setIsEdit(true);
                                    setIsEditing(true);
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                </div>

                <div className={`${isEdit ? 'hidden' : null} h-fit`}>
                    {serviceState?.data?.features?.length &&
                    !chartState?.loading ? (
                        <>
                            <div className="flex items-center justify-between ">
                                <p className="mb-1 font-display text-2xl font-light ">
                                    SLOs:
                                </p>
                                {selectedOrgHash === demoOrgHash ? (
                                    ''
                                ) : (
                                    <div className="w-48 mb-2">
                                        <Select
                                            isSearchable={false}
                                            options={stepOptions}
                                            value={step}
                                            onChange={(option) => {
                                                setStep(option);
                                            }}
                                            styles={setStepsSelectStyle()}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="w-full grid gap-4 grid-cols-4 mb-8 items-stretch">
                                {serviceState?.data?.features.map((slo, i) => (
                                    <SloCard
                                        key={slo.slo_name}
                                        sloData={serviceState?.data?.features.find(
                                            (feature) => {
                                                return (
                                                    feature.feature_name ===
                                                    slo.feature_name
                                                );
                                            }
                                        )}
                                        chartData={chartState?.data?.find(
                                            (chart) => {
                                                return (
                                                    chart.feature ===
                                                    slo.feature_name
                                                );
                                            }
                                        )}
                                        service={service?.service}
                                        step={step}
                                        color={getSloColor(i)}
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}

                    {!chartState?.loading ? (
                        <div className="flex items-center justify-between ">
                            <p className="my-2 font-display text-2xl font-light ">
                                Error Budget:
                            </p>
                        </div>
                    ) : null}

                    {[
                        'default',
                        'handlerAddService',
                        'handlerManageService',
                    ].includes(serviceStatus.service.value) ||
                    !serviceStatus.service.value ? (
                        <StubChart />
                    ) : (
                        <ErrorBudgetsChart
                            selectedService={serviceStatus?.service?.value}
                            services={services?.data}
                            serviceState={serviceState}
                            chartState={chartState}
                            step={step.value}
                            deploymentsList={sortedDeploymentFreqData}
                        />
                    )}

                    <SreNotes serviceName={serviceStatus?.service?.value} />
                </div>
            </div>
        </div>
    );
}

export default SREService;

const setStepsSelectStyle = () => ({
    control: (provided) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#dbdee9',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});
