import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import Loader from '../loader';
import { createJiraWebhook } from '../../api/settings/sprintInsightsAPI';
import showNotification from '../../helpers/showNotification';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';
import SaveButton from '../shared/save-button';

const WorkspaceWebhook = ({
    workspace,
    onCreate,
    isInOrg = true,
    loading = false,
    permissionForWrite,
}) => {
    const { t } = useTranslation();
    const { userState } = useContext(UserContext);
    const { name, source, webhook, modified_user } = workspace;
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [webhookId, setWebhookId] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        modified_user?.name
            ? setIsButtonActive(true)
            : setIsButtonActive(false);
    }, [modified_user]);

    useEffect(() => {
        setWebhookId(webhook);
    }, [webhook]);

    const buttonStyles = {
        true: {
            margin: '0',
            color: '#1FB8D3',
            borderColor: '#1FB8D3',
            fontFamily: `${['Exo\\ 2', 'sans-serif', 'Arial']}`,
            fontWeight: '600',
            lineHeight: '20px',
        },
        false: {
            margin: '0',
            color: '#C2C7D7',
            borderColor: '#C2C7D7',
            fontFamily: `${['Exo\\ 2', 'sans-serif', 'Arial']}`,
            fontWeight: '600',
            lineHeight: '20px',
            opacity: '1',
        },
    };

    const createWebhook = async () => {
        try {
            setIsLoading(true);
            const data = {
                workspace_name: name,
                user_hash: userState.data.user_hash,
            };
            const response = await createJiraWebhook(data);
            setIsLoading(false);
            setWebhookId(response.data);
            onCreate(response.data);
            showNotification('Successfully created!', 'success');
        } catch (error) {
            setIsLoading(false);
            showNotification(error.message);
        }
    };

    return (
        <div className="w-full pl-4">
            <h4 className="font-normal leading-6 mb-2">
                Step 2.{' '}
                <span className="font-bold">
                    {capitalizeFirstLetter(t('repo_page.create_webhook'))}
                </span>
            </h4>
            {webhookId ? (
                <p className="leading-4 text-sm text-gray-500">
                    {capitalizeFirstLetter(t('repo_page.webhook_created'))}
                </p>
            ) : permissionForWrite ? (
                isInOrg ? (
                    (source === 'jira' && (
                        <>
                            {isLoading ? (
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            ) : (
                                <SaveButton
                                    saveFunc={createWebhook}
                                    text={capitalizeFirstLetter(
                                        t('repo_page.create_webhook')
                                    )}
                                    disabled={!isButtonActive}
                                    style={buttonStyles[`${isButtonActive}`]}
                                />
                            )}
                        </>
                    )) ||
                    (source === 'azure' && (
                        <>
                            {isLoading ? (
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            ) : (
                                <SaveButton
                                    text={capitalizeFirstLetter(
                                        t('repo_page.create_webhook')
                                    )}
                                    disabled={!isButtonActive}
                                    style={{
                                        margin: '0',
                                        color: '#1FB8D3',
                                        borderColor: '#1FB8D3',
                                        fontFamily: `${[
                                            'Exo\\ 2',
                                            'sans-serif',
                                            'Arial',
                                        ]}`,
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                    }}
                                />
                            )}
                        </>
                    ))
                ) : loading ? (
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                ) : null
            ) : (
                <p className="leading-4 text-sm text-gray-500">No webhook</p>
            )}
        </div>
    );
};

export default WorkspaceWebhook;
